export const Urls = {
  About: '/about/',
  Builder: '/builder/resume/:resumeId/',
  Builder_TemplateThumbnail: '/builder/template/:templateId/thumbnail/',
  Blog: '/blog/',
  FAQ: '/faq/',
  GetJobReady: '/get-job-ready/',
  GetJobReady_Super: '/get-job-ready/super/',
  GetJobReady_Super_StudentSuper: '/get-job-ready/super/student-super/',
  GetJobReady_Super_StudentSuper_Start: '/get-job-ready/super/student-super/start/',
  GetJobReady_Super_ProfessionalSuper: '/get-job-ready/super/professional-super/',
  GetJobReady_Super_ProfessionalSuper_Start: '/get-job-ready/super/professional-super/start/',
  GetJobReady_Tfn: '/get-job-ready/tfn/',
  GetStarted: '/get-started/',
  GetStarted_Survey: '/get-started/survey/',
  GetStarted_Template: '/get-started/template/',
  GetStarted_Builder: '/get-started/builder/resume/:resumeId/',
  Home: '/',
  Login: '/login/',
  MyAccount: '/my-account/',
  MyAccount_ChangePassword: '/my-account/change-password/',
  MyAccount_DeleteAccount: '/my-account/delete-account/',
  MyResumes: '/my-resumes/',
  MyResumes_New: '/my-resumes/new/',
  Privacy: '/privacy/',
  ResetPassword: '/reset-password/',
  ResetPassword_Confirm: '/reset-password/:tokenId/',
  Terms: '/terms/',
}
