import React from 'react'
import clsx from 'clsx'

import typographyStyles from '../../typography/Typography.module.css'


function PageTitle({ children }) {
  return (
    <h1 className={clsx(typographyStyles.title, 'mb-3')}>{children}</h1>
  )
}

export default PageTitle
