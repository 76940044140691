import { ActionName } from './_actions'
import { getIn, setIn } from './structure'


export function reducer(state, action) {
  switch (action.type) {

    case ActionName.SET_PROGRESS:
      return setProgress(state, action)
    case ActionName.SET_RESUME_DATA:
      return setResumeData(state, action)
    case ActionName.SET_URL_DATA:
      return setUrlData(state, action)

    case ActionName.ADD_SECTION:
      return addSection(state, action)
    case ActionName.DELETE_SECTION:
      return deleteSection(state, action)
    case ActionName.TOGGLE_SECTION:
      return toggleSection(state, action)
    case ActionName.TOGGLE_SECTION_VISIBILITY:
      return toggleSectionVisibility(state, action)
    case ActionName.ADD_FIELD:
      return addField(state, action)
    case ActionName.DELETE_FIELD:
      return deleteField(state, action)
    case ActionName.SET_LABEL:
      return updateLabel(state, action)

    case ActionName.SET_FORM_VALUES:
      return setFormValues(state, action)
    case ActionName.SET_TEMPLATE:
      return setTemplate(state, action)

    case ActionName.TOGGLE_PREVIEW:
      return togglePreview(state, action)
    case ActionName.TOGGLE_TEMPLATE_SWITCH:
      return toggleTemplateSwitch(state, action)

    default:
      throw new Error(action)
  }
}

const setProgress = (state, { payload }) => {
  return { ...state, progress: payload }
}

const setResumeData = (state, { payload }) => {
  return { ...state, ...payload }
}

const setUrlData = (state, { payload }) => {
  const { previewUrl, downloadUrl } = payload
  if (Object.keys(state).length === 0) { return state }
  return { ...state, previewUrl, downloadUrl }
}

const addSection = (state, { payload }) => {
  const self = getIn(state.formConfig, `${payload.key}.sections[${payload.index}]`)
  const formConfigWithAddedSection = setIn(state.formConfig, `${payload.key}.sections[${payload.index}]`, payload.config)
  const formConfigWithAddedSelf = setIn(formConfigWithAddedSection, `${payload.key}.sections[${payload.index + 1}]`, self)
  return { ...state, formConfig: formConfigWithAddedSelf }
}

const deleteSection = (state, { payload }) => {
  const formConfig = setIn(state.formConfig, payload.sectionKey, undefined)
  const sections = getIn(formConfig, payload.parentKey).filter(Boolean)
  const formConfigWithDeletedSection = setIn(state.formConfig, payload.parentKey, sections)

  // Need to recursively look into the new formValues for any section marked with
  // a delete key so that we can actually remove it from the `sections` array. This
  // is because `setIn` does not delete from arrays, only sets the value at the given
  // index to `empty`.
  const deleteKey = '~delete'
  const formValues = setIn(state.formValues, payload.sectionKey, deleteKey) || {}

  function updateFormValues(section) {
    if (!!section && !!section.sections && !!section.sections.length > 0) {
      // We have to iterate through by index the old-school way. `.filter` and `.forEach`
      // each have optimisations where they will only look at indexes actually set in the
      // array (and as such will exclude `empty` array indexes).
      let updatedSections = []
      for (let i = 0; i < section.sections.length; i++) {
        let currentSetion = section.sections[i]
        if (currentSetion !== deleteKey) {
          updatedSections.push(currentSetion)
        }
      }
      if (updatedSections.length === 0) {
        // If there are no more items in this section's `sections` array, just delete
        // the `sections` key.
        const newSection = { ...section }
        delete newSection.sections
        return newSection
      } else {
        // If there are child sections in this section look for further `sections` arrays
        // in each child section, and recurse.
        return {
          ...section,
          sections: updatedSections.map(nestedSection => updateFormValues(nestedSection))
        }
      }
    } else {
      // If we don't find anything further to look at just return the current node.
      return section
    }
  }

  return {
    ...state,
    formConfig: formConfigWithDeletedSection,
    formValues: updateFormValues(formValues),
  }
}

const toggleSection = (state, { payload }) => {
  const formConfig = setIn(state.formConfig, payload.key, {
    ...payload.section,
    expanded: !payload.section.expanded,
  })
  return { ...state, formConfig }
}

const toggleSectionVisibility = (state, { payload }) => {
  const formConfig = setIn(state.formConfig, payload.key, {
    ...payload.section,
    hidden: !payload.section.hidden,
  })
  return { ...state, formConfig }
}

const addField = (state, { payload }) => {
  const self = getIn(state.formConfig, `${payload.key}.fields[${payload.index}]`)
  const formConfigWithAddedField = setIn(state.formConfig, `${payload.key}.fields[${payload.index}]`, payload.config)
  const formConfigWithAddedSelf = setIn(formConfigWithAddedField, `${payload.key}.fields[${payload.index + 1}]`, self)
  return { ...state, formConfig: formConfigWithAddedSelf }
}

const deleteField = (state, { payload }) => {
  const section = getIn(state.formConfig, payload.sectionKey)
  const fieldToDelete = getIn(state.formConfig, `${payload.sectionKey}.fields[${payload.index}]`)

  const formConfig = setIn(state.formConfig, `${payload.sectionKey}.fields[${payload.index}]`, undefined)
  const fields = getIn(formConfig, `${payload.sectionKey}.fields`).filter(Boolean)
  const formConfigWithDeletedField = setIn(state.formConfig, `${payload.sectionKey}.fields`, fields)
  const formValuesWithDeletedField = setIn(state.formValues, `${payload.sectionKey}.${section.name}.${fieldToDelete.name}`, undefined) || {}

  return {
    ...state,
    formConfig: formConfigWithDeletedField,
    formValues: formValuesWithDeletedField
  }
}

const updateLabel = (state, { payload }) => {
  const formConfig = setIn(state.formConfig, `${payload.key}.fields[${payload.index}].label`, payload.value)
  return { ...state, formConfig }
}

const setFormValues = (state, { payload }) => {
  return { ...state, formValues: payload.values }
}

const setTemplate = (state, { payload }) => {
  return { ...state, template: payload.templateId }
}

const togglePreview = (state, { payload }) => {
  return { ...state, previewOpen: !state.previewOpen }
}

const toggleTemplateSwitch = (state, { payload }) => {
  return { ...state, templateSwitchOpen: !state.templateSwitchOpen }
}
