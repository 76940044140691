import React, { useState } from 'react'
import { Form } from 'react-final-form'

import { withAuthenticatedRedirect } from './withAuthenticatedRedirect'
import typographyStyles from '../typography/Typography.module.css'
import Input from '../forms/fields/Input'
import Button, { ButtonType } from '../forms/buttons/Button'
import StackedField from '../forms/layouts/StackedField'
import { required } from '../forms/validation'
import { sendResetPasswordEmail } from './_requests'


function ResetPassword() {
  const [submittedEmail, setSubmittedEmail] = useState(false)

  const reset = async ({ email }) => {
    sendResetPasswordEmail(email).then(_ => setSubmittedEmail(email))
  }

  return (
    <div className='container py-5'>
      <div className='row'>
        <div className='col-md-6'>
          <h1 className={typographyStyles.title}>Forgot your password?</h1>
          <p>
            Don't worry, it happens! Let us sort it out.
          </p>
          {submittedEmail && (
            <div className='alert alert-info' role='alert'>
              We've just sent a password reset link to <strong>{submittedEmail}</strong>. Please be patient – it can take a few minutes.
            </div>
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col-md-4 mt--2'>
          <Form
            onSubmit={reset}
            render={({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <StackedField name='email' label='Email' validate={required} component={Input} autoFocus />
                <div className='mt-4'>
                  <Button submit
                    type={ButtonType.CtaSecondary}
                    disabled={submitting}>
                    Send me a password reset email
                </Button>
                </div>
              </form>
            )}>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default withAuthenticatedRedirect()(ResetPassword)
