import Input from '../../forms/fields/Input'
import Select from '../../forms/fields/Select'
import Textarea from '../../forms/fields/Textarea'
import LeftLabel from './layouts/LeftLabel'
import NoLabel from './layouts/NoLabel'
import AddField from './fields/AddField'
import AddSection from './fields/AddSection'
import Basic from './labels/Basic'
import Editable from './labels/Editable'
import YearDropdown from '../../forms/fields/YearDropdown'
import YearDropdownWithCurrent from '../../forms/fields/YearDropdownWithCurrent'
import RichTextEditor from '../../forms/fields/RichTextEditor'
import Checkbox from '../../forms/fields/Checkbox'
import CheckboxLabel from './layouts/CheckboxLabel'


export const FieldComponentType = {
  AddField: 'addField',
  AddSection: 'addSection',
  Custom: 'custom',
  Checkbox: 'checkbox',
  Input: 'input',
  RichTextEditor: 'rte',
  Select: 'select',
  Textarea: 'textarea',
  YearDropdown: 'yearDropdown',
  YearDropdownWithCurrent: 'yearDropdownWithCurrent',
}

export const FieldLabelType = {
  Default: 'default',
  Editable: 'editable',
}

export const FieldLayoutType = {
  CheckboxLabel: 'checkboxLabel',
  LeftLabel: 'leftLabel',
  NoLabel: 'noLabel',
}

export const FieldComponentMap = {
  [FieldComponentType.AddField]: AddField,
  [FieldComponentType.AddSection]: AddSection,
  [FieldComponentType.Custom]: Input,
  [FieldComponentType.Checkbox]: Checkbox,
  [FieldComponentType.Input]: Input,
  [FieldComponentType.RichTextEditor]: RichTextEditor,
  [FieldComponentType.Select]: Select,
  [FieldComponentType.Textarea]: Textarea,
  [FieldComponentType.YearDropdown]: YearDropdown,
  [FieldComponentType.YearDropdownWithCurrent]: YearDropdownWithCurrent,
}

export const FieldLayoutMap = {
  [FieldLayoutType.CheckboxLabel]: CheckboxLabel,
  [FieldLayoutType.LeftLabel]: LeftLabel,
  [FieldLayoutType.NoLabel]: NoLabel,
}

export const FieldLabelMap = {
  [FieldLabelType.Default]: Basic,
  [FieldLabelType.Editable]: Editable,
}

export const FieldComponentLayoutDefaultMap = {
  [FieldComponentType.AddField]: FieldLayoutMap[FieldLayoutType.NoLabel],
  [FieldComponentType.AddSection]: FieldLayoutMap[FieldLayoutType.NoLabel],
  [FieldComponentType.Checkbox]: FieldLayoutMap[FieldLayoutType.CheckboxLabel],
}

export const FieldComponentLabelDefaultMap = {
  [FieldComponentType.Custom]: FieldLabelMap[FieldLabelType.Editable],
}
