import React, { useEffect } from 'react'
import GoogleAnalytics from 'react-ga'
import { Route as RouteCore } from 'react-router-dom'

import { getGlobals } from '../Globals'


const { googleAnalyticsId } = getGlobals().site

if (googleAnalyticsId) {
  GoogleAnalytics.initialize(googleAnalyticsId)
}

function Route(props) {

  const { children, ...rest } = props

  const trackPage = page => {
    GoogleAnalytics.set({ page })
    GoogleAnalytics.pageview(page)
  }

  useEffect(_ => {
    if (googleAnalyticsId) {
      trackPage(props.location.pathname)
    }
  }, [props.location.pathname])

  return (
    <RouteCore {...rest}>
      {children}
    </RouteCore>
  )
}

export default Route
