import React, { useEffect, useContext, useState } from 'react'
import { Link, useHistory, useLocation, generatePath } from 'react-router-dom'
import { Form } from 'react-final-form'
import queryString from 'query-string'

import Button, { ButtonType } from '../../../forms/buttons/Button'
import StackedField from '../../../forms/layouts/StackedField'
import Input from '../../../forms/fields/Input'
import { required } from '../../../forms/validation'
import { getGlobals } from '../../../Globals'
import styles from '../../GetStarted.module.css'
import { Urls } from '../../../routing/urls'
import { ModalContext } from '../../../modals/_context'
import { createAccount } from '../../../account/_requests'
import { AccountContext } from '../../../account/_context'
import { Action } from '../../../account/_actions'
import { createResume } from '../../../builder/create'
import { BuilderConfig } from '../../../builder/config'
import { withFormErrorAsync } from '../../../forms/errors'


function CollectPassword() {

  const [, dispatch] = useContext(AccountContext)
  const [, openModal, closeModal] = useContext(ModalContext)

  // The below is a hack to allow the form's DOM element time to update its
  // data-valid attribute to indicate whether the form has any client-side
  // field errors.
  const [fakeSubmitting, setFakeSubmitting] = useState(false)

  const $ = getGlobals().$
  const formId = 'builderform'

  const history = useHistory()
  const location = useLocation()

  const query = queryString.parse(location.search)
  const templateId = query.template

  useEffect(_ => {
    const handleSubmitExternal = _ => {
      setFakeSubmitting(true)
      setTimeout(_ => {
        setFakeSubmitting(false)
        // Use jQuery here so that the template and all its functionality
        // remains self-contained. The Builder does not need to know about
        // what goes on in the template.
        const formElement = $(`#${formId}`)
        const valid = formElement.attr('data-valid') === 'true'
        if (valid) {
          openModal({
            dismissable: false,
            title: _ => (
              <span className={styles.title}>Add a password to save your resume</span>
            ),
            body: _ => {
              const resumeValues = formElement.serializeArray().reduce((obj, item) => {
                obj[item.name.split('.').slice(-1)] = item.value
                return obj
              }, {})
              return (
                <React.Fragment>
                  <p>Auto-save your work while you continue to edit.<br />Download your resume anytime.</p>
                  <div className='my-4'>
                    <Form
                      initialValues={{ ...resumeValues, lastName: resumeValues.familyName }}
                      onSubmit={withFormErrorAsync(async passwordFormValues => {
                        const data = { ...resumeValues, ...passwordFormValues, survey: JSON.parse(query.survey) }
                        const account = await createAccount(data)
                        dispatch(Action.SetAccountData(account))
                        const { resumeId } = await createResume(templateId, { account })
                        history.push(generatePath(Urls.Builder, { resumeId }))
                        closeModal()
                      })}
                      render={({ handleSubmit, submitting }) => (
                        <form onSubmit={handleSubmit}>
                          <StackedField name='email' label='Email' validate={required} component={Input} />
                          <StackedField name='password' label='Password' validate={required} component={Input} type='password' />
                          <div>
                            This site is 100% free. I agree to the site terms <Link to={Urls.Terms} rel="noopener noreferrer" target="_blank">here</Link>.
                          </div>
                          <div className='mt-4'>
                            <Button submit
                              type={ButtonType.CtaSecondary}
                              disabled={submitting}>
                              Next
                            </Button>
                          </div>
                        </form>
                      )}>
                    </Form>
                  </div>
                </React.Fragment>
              )
            },
          })
        }
      }, BuilderConfig.DebounceDelay)
    }
    const form = document.getElementById(formId)
    form.addEventListener('submit', handleSubmitExternal)
    return _ => form.removeEventListener('submit', handleSubmitExternal)
  }, [$, history, openModal, closeModal, dispatch, templateId, query])

  return (
    <div className='text-right'>
      {!fakeSubmitting && (
        <Button
          submit
          type={ButtonType.CtaSecondary}
          onClick={_ => {
            document
              .getElementById(formId)
              .dispatchEvent(new Event('submit', { cancelable: true }))
          }}>
          Next
        </Button>
      )}
      {!!fakeSubmitting && (
        <div>...</div>
      )}
    </div>
  )
}

export default CollectPassword
