const extractProfile = state => {

  const { formConfig, formValues } = state

  const secondaryEducationIndex = (formConfig.sections || []).findIndex(x => x.name === 'secondary')
  const secondaryEducationWithCurrentEndYear = (((formValues.sections || [])[secondaryEducationIndex] || {}).sections || [])
    .find(section => !!section && !!section.education && section.education.yearFinished === '{"month":"0","year":"Current"}')
  const secondaryGradYear = !!secondaryEducationWithCurrentEndYear
    ? parseInt(JSON.parse(secondaryEducationWithCurrentEndYear.education.expectedGraduationYear || '{"month":"0","year":"0"}').year) || 0
    : 0

  const tertiaryEducationIndex = (formConfig.sections || []).findIndex(x => x.name === 'tertiary')
  const tertiaryEducationWithCurrentEndYear = (((formValues.sections || [])[tertiaryEducationIndex] || {}).sections || [])
    .find(section => !!section && !!section.education && section.education.yearFinished === '{"month":"0","year":"Current"}')
  const tertiaryGradYear = !!tertiaryEducationWithCurrentEndYear
    ? parseInt(JSON.parse(tertiaryEducationWithCurrentEndYear.education.expectedGraduationYear || '{"month":"0","year":"0"}').year) || 0
    : 0

  state.profileData = {
    secondaryGradYear,
    tertiaryGradYear,
  }

  return state
}

export default extractProfile
