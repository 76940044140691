import React from 'react'
import { Switch } from 'react-router-dom'

import Route from './Route'
import { Urls } from './urls'
import { AuthenticatedBuilder, PublicBuilder } from '../builder/Builder'
import Error from '../core/Error'
import Home from '../home/Home'
import Login from '../account/Login'
import MyAccount from '../account/MyAccount'
import MyResumes from '../myresumes/MyResumes'
import GetJobReady from '../jobready/GetJobReady'
import GetStarted from '../getstarted/GetStarted'
import MyResumesNew from '../myresumes/MyResumesNew'
import Privacy from '../pages/Privacy'
import Terms from '../pages/Terms'
import About from '../pages/About'
import FAQ from '../pages/FAQ'
import ResetPassword from '../account/ResetPassword'
import ResetPasswordConfirm from '../account/ResetPasswordConfirm'
import ChangePassword from '../account/ChangePassword'
import DeleteAccount from '../account/DeleteAccount'


function Routes() {
  return (
    <Switch>
      <Route exact path={Urls.Home}><Home /></Route>
      <Route exact path={Urls.About}><About /></Route>
      <Route exact path={Urls.Builder}><AuthenticatedBuilder /></Route>
      <Route exact path={Urls.FAQ}><FAQ /></Route>
      <Route exact path={Urls.GetStarted_Builder}><PublicBuilder /></Route>
      <Route path={Urls.GetStarted}><GetStarted /></Route>
      <Route exact path={Urls.Login}><Login /></Route>
      <Route exact path={Urls.MyAccount}><MyAccount /></Route>
      <Route exact path={Urls.MyAccount_ChangePassword}><ChangePassword /></Route>
      <Route exact path={Urls.MyAccount_DeleteAccount}><DeleteAccount /></Route>
      <Route exact path={Urls.MyResumes}><MyResumes /></Route>
      <Route exact path={Urls.MyResumes_New}><MyResumesNew /></Route>
      <Route exact path={Urls.Privacy}><Privacy /></Route>
      <Route exact path={Urls.ResetPassword}><ResetPassword /></Route>
      <Route exact path={Urls.ResetPassword_Confirm}><ResetPasswordConfirm /></Route>
      <Route exact path={Urls.Terms}><Terms /></Route>
      <Route><Error /></Route>
    </Switch>
  )
}

export default Routes
