import { ActionName } from './_actions'


export function reducer(state, action) {
  switch (action.type) {

    case ActionName.SET_ACCOUNT_DATA:
      return setAccountData(state, action)
    case ActionName.CLEAR_ACCOUNT_DATA:
      return clearAccountData(state, action)

    default:
      throw new Error(action)
  }
}

const setAccountData = (state, { payload }) => {
  return { ...state, ...payload }
}

const clearAccountData = (state, action) => {
  return {}
}
