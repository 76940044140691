import React, { useContext, useState, useEffect } from 'react'
import { Switch, Route, Redirect, useRouteMatch, useHistory } from 'react-router-dom'

import { withUnauthenticatedRedirect } from '../account/withUnauthenticatedRedirect'
import Tfn from './Tfn'
import { Urls } from '../routing/urls'
import Header from './Header'
import SuperStart from './SuperStart'
import { default as InfoFrameStudentSuper } from './super/funds/studentsuper/InfoFrame'
import { default as InfoFrameProfessionalSuper } from './super/funds/professionalsuper/InfoFrame'
import SuperSelect from './SuperSelect'
import { AccountContext } from '../account/_context'
import { isAccountReady } from '../account/status'
import { EventDescription } from '../events'
import { getSuperInfoPageUrl, getSuperFormPageUrl, finishedJobReady } from './status'



function GetJobReady() {
  const { path } = useRouteMatch()
  const [account] = useContext(AccountContext)
  const [startPage, setStartPage] = useState(null)

  const history = useHistory()

  useEffect(_ => {
    if (isAccountReady(account)) {
      switch (account.lastGetJobReadyStep) {
        case EventDescription.ViewedTFNQuestion:
          setStartPage(Urls.GetJobReady_Tfn)
          break;
        case EventDescription.ViewedSuperQuestion:
          setStartPage(Urls.GetJobReady_Super)
          break;
        case EventDescription.ViewedSuperInfo:
          setStartPage(getSuperInfoPageUrl(account))
          break;
        case EventDescription.ViewedSuperForm:
          setStartPage(getSuperFormPageUrl(account))
          break;
        case EventDescription.ViewedEndScreen:
          // Don't show any page under Get Job Ready
          // if they've already completed it once.
          if (finishedJobReady(account)) {
            history.push(Urls.MyResumes)
            return;
          } else {
            setStartPage(getSuperFormPageUrl(account))
            break;
          }
        default:
          setStartPage(Urls.GetJobReady_Tfn)
      }
    }
  }, [account, history])

  return (
    <div className='py-5'>
      <Switch>
        <Route exact path={path}>
          {!!startPage && <Redirect to={startPage} />}
        </Route>
        <Route exact path={Urls.GetJobReady_Tfn}>
          <Header>
            <div className='alert alert-info' role='alert'>
              <p>Now that you’ve created the perfect resume, it’s a great time to get everything you’ll need for when you start a new job.</p>
              <p className='mb-0'>These include a bank account, a Tax File Number and a Super account. Here's some steps to help you get started.</p>
            </div>
          </Header>
          <Tfn />
        </Route>
        <Route exact path={Urls.GetJobReady_Super_StudentSuper}><InfoFrameStudentSuper /></Route>
        <Route exact path={Urls.GetJobReady_Super_ProfessionalSuper}><InfoFrameProfessionalSuper /></Route>
        <Route exact path={Urls.GetJobReady_Super_StudentSuper_Start}><SuperStart /></Route>
        <Route exact path={Urls.GetJobReady_Super_ProfessionalSuper_Start}><SuperStart /></Route>
        <Route path={Urls.GetJobReady_Super}>
          <Header />
          <SuperSelect />
        </Route>
      </Switch>
    </div>
  )
}

export default withUnauthenticatedRedirect()(GetJobReady)
