import React from 'react'


function Textarea({ input, fieldInfo, fieldData, ...rest }) {
  return (
    <textarea
      className='form-control'
      {...input}
      {...rest} />
  )
}

export default Textarea
