import React, { useState, useRef, useEffect, useContext, useCallback } from 'react'

import Basic from './Basic'
import Input from '../../../forms/fields/Input'
import { FormContext } from '../../_context'
import { Action } from '../../_actions'
import styles from './Editable.module.css'
import { useDebounce } from '../../../hooks/useDebounce'
import { BuilderConfig } from '../../config'


function Editable(props) {
  const { fieldInfo, fieldData } = props
  const { location, fieldIndex } = fieldInfo

  const [, dispatch] = useContext(FormContext)
  const [editing, setEditing] = useState(false)
  const [value, setValue] = useState(fieldInfo.label)
  const debouncedValue = useDebounce(value, BuilderConfig.DebounceDelay)

  const node = useRef()

  const update = _ => dispatch(Action.SetLabel(location, fieldIndex, value))

  const startEditing = _ => {
    setEditing(true)
    setTimeout(_ => node.current.querySelector('input').select())
  }

  const stopEditing = useCallback(_ => {
    setEditing(false)
    update()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(_ => {
    update()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  useEffect(_ => {
    const handleClick = e => {
      const isClickInsideInput = node.current.contains(e.target)
      if (!isClickInsideInput) {
        stopEditing()
      }
    }
    if (editing) {
      document.addEventListener('click', handleClick)
    } else {
      document.removeEventListener('click', handleClick)
    }
    return _ => document.removeEventListener('click', handleClick)
  }, [editing, stopEditing])

  return (
    <React.Fragment>
      {!editing && (
        <Basic className={styles.label} onClick={startEditing} {...props} label={value} />
      )}
      {editing && (
        <React.Fragment>
          <div ref={node}>
            <Input onBlur={stopEditing} onChange={e => setValue(e.target.value)} value={value} />
          </div>
          <small className='form-text text-muted' dangerouslySetInnerHTML={{ __html: fieldData.helpText }} />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default Editable
