import React from 'react'


function Intro() {
  return (
    <React.Fragment>
      <p className='m-0'>Add people who will be willing to vouch for your character, and it's not necessary for them to have worked with you.</p>
    </React.Fragment>
  )
}

export default Intro
