import React, { useContext, useState, createRef, useEffect } from 'react'
import { Pencil } from 'react-bootstrap-icons'
import moment from 'moment'
import clsx from 'clsx'

import { BuilderContext } from '../_context'
import styles from './Title.module.css'
import typographyStyles from '../../typography/Typography.module.css'
import { Field } from 'react-final-form'


function Title() {

  const [state,] = useContext(BuilderContext)
  const [isEditing, setIsEditing] = useState(false)
  const [internalValue, setInternalValue] = useState(state.formValues.name || 'Untitled')

  const saved = moment(state.modified).format('D MMMM YYYY, h:mmA')
  const inputElement = createRef()

  const captureEnter = event => {
    if (event.key === 'Enter') {
      setIsEditing(false)
    }
  }

  useEffect(_ => {
    if (inputElement.current && isEditing) {
      inputElement.current.select()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing])

  return (
    <div className='text-center my-3'>
      <h1 className={styles.title} onClick={_ => setIsEditing(true)}>
        {
          !isEditing && (
            <React.Fragment>
              <span>{internalValue}</span>
              <Pencil className={styles.icon} />
            </React.Fragment>
          )
        }
        {
          isEditing && (
            <Field name={'name'}>
              {
                ({ input }) => {
                  return (
                    <input
                      {...input}
                      ref={inputElement}
                      className='form-control'
                      onChange={e => { setInternalValue(e.target.value); input.onChange(e) }}
                      onKeyPress={captureEnter}
                      onBlur={_ => setIsEditing(false)} />
                  )
                }
              }
            </Field>
          )
        }
      </h1>
      <small className={clsx(typographyStyles.psst, styles.saved)}>Last saved {saved}</small>
    </div>
  )
}

export default Title
