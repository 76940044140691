import { Action } from "./_actions"
import { putResume } from "./_requests"
import { getTemplateHtmlCss } from "./preview/utils/update"

import { default as v1_0_0 } from './state/profile/v1.0.0'


const ProfileExtractorVersionMap = {
  'v1.0.0': v1_0_0,
}


// Calculate/process all resume data here before persisting to server.
// The result here will be the result that is pulled down for consequent use.
export const saveResume = async (state, dispatch) => {

  // Generate the template html/css data to be used for server-side PDF generation.
  const templateHtmlCss = getTemplateHtmlCss(state)

  // Additionally extract any relevant profile fields for easy access at the top level
  // (e.g. to build up marketing profiles to be used for email campaigns).
  ProfileExtractorVersionMap[state.version](state)

  const promise = new Promise(
    async (resolve, reject) => {
      const resume = await putResume(state.resumeId, state, templateHtmlCss)
      dispatch(Action.SetUrlData(resume.previewUrl, resume.downloadUrl))
      resolve(resume)
    }
  )
  return promise
}
