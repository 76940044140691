import React, { useRef, useEffect, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import clsx from 'clsx'

import bulbOrange from '../../assets/icons/bulb-orange.svg'
import { getGlobals } from '../../Globals'
import styles from './Tooltip.module.css'


let ID_COUNTER = 0
const TOOLTIP_CLASSNAME = 'bs-tooltip-ext'
const TOOLTIP_ACTIVE_CLASSNAME = 'bs-tooltip-ext-active'
const TOOLTIP_DISMISS_CLASSNAME = 'bs-tooltip-ext-dismiss'

function Tooltip({ tips, className }) {

  const $ = getGlobals().$
  const button = useRef()
  const [isShown, setIsShown] = useState(false)

  const hideOnClickOutside = e => {
    // Assume that only one popover can be active at a time.
    const $element = $(e.target)
    const parents = $element.parents('.popover')
    if (parents.length === 0) {
      if (!!button.current) {
        $(`.${TOOLTIP_DISMISS_CLASSNAME}`).off('click')
        $(`#${button.current.id}`).popover('hide')
      }
      window.removeEventListener('click', hideOnClickOutside)
    } else {
      if (!!button.current && !$(`#${button.current.id}`).hasClass(TOOLTIP_ACTIVE_CLASSNAME)) {
        $(`.${TOOLTIP_DISMISS_CLASSNAME}`).off('click')
        $(`#${button.current.id}`).popover('hide')
      }
    }
  }

  const dismiss = _ => {
    // Remove the dismiss listener.
    $(`.${TOOLTIP_DISMISS_CLASSNAME}`).off('click')
    $(`#${button.current.id}`).popover('hide')
  }

  const toggle = e => {
    // Stop event propagation as we don't want tooltip clicks to additionally
    // interact with any other element on the screen.
    if (e) { e.preventDefault(); e.stopPropagation(); }

    // First close every other popover before opening the new one.
    $(`.${TOOLTIP_DISMISS_CLASSNAME}`).off('click')
    $(`.${TOOLTIP_CLASSNAME}:not(#${button.current.id})`).popover('hide')

    // Trigger Bootstrap popover.
    $(`#${button.current.id}`).popover({
      html: true,
      content: ReactDOMServer.renderToStaticMarkup(
        <div className={clsx('p-3')}>
          <div className={clsx(styles.dismiss, TOOLTIP_DISMISS_CLASSNAME)}>&times;</div>
          <div className={clsx(styles.title, 'd-flex d-flex-row align-items-center mb-3')}>
            <img className={clsx(styles.title__bulb, 'mr-2')} src={bulbOrange} alt='' />
            <div>Top tips</div>
          </div>
          <ul className={styles.tips}>
            {tips.map((tip, i) => <li key={i} className='my-3'><span dangerouslySetInnerHTML={{ __html: tip }} /></li>)}
          </ul>
        </div>
      ),
      boundary: 'viewport',
      container: 'body',
    })

    $(`#${button.current.id}`).on('show.bs.popover', _ => {
      setIsShown(true)
      window.addEventListener('click', hideOnClickOutside)

      // Need to add/remove an `active` class to keep track of the
      // latest opened tooltip.
      $(`.${TOOLTIP_CLASSNAME}`).removeClass(TOOLTIP_ACTIVE_CLASSNAME)
      $(`#${button.current.id}`).addClass(TOOLTIP_ACTIVE_CLASSNAME)
    })

    $(`#${button.current.id}`).on('shown.bs.popover', _ => {
      // Set a dismiss listener.
      if (!isShown) {
        $(`.${TOOLTIP_DISMISS_CLASSNAME}`).on('click', dismiss)
      }
    })

    $(`#${button.current.id}`).on('hide.bs.popover', _ => {
      window.removeEventListener('click', hideOnClickOutside)
      setIsShown(false)
    })

    return _ => {
      $(`.${TOOLTIP_DISMISS_CLASSNAME}`).off('click')
      $(`#${button.current.id}`).popover('dispose')
    }
  }

  // Toggle on mount to initialise the popover.
  useEffect(toggle, [])

  return (
    <button ref={button} id={`tooltip-${ID_COUNTER++}`} className={TOOLTIP_CLASSNAME} onClick={toggle}>
      <img className={clsx(className)} src={bulbOrange} alt='' />
    </button>
  )
}

export default Tooltip
