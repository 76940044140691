import React, { useContext, useState, useEffect } from 'react'
import { ChevronDown, ChevronUp, Trash, Eye, EyeSlash } from 'react-bootstrap-icons'
import clsx from 'clsx'

import { BuilderContext } from '../_context'
import { ModalContext } from '../../modals/_context'
import { Action } from '../_actions'
import { SectionContext } from './_context'
import SectionDebug from './SectionDebug'
import styles from './Section.module.css'
import Tooltip from './Tooltip'
import { load } from '../templates/load'
import Button, { ButtonType } from '../../forms/buttons/Button'


function Section({ parentKey, sectionKey, index, formValues, section, children }) {
  // `getValue` is not used but made available for use by dynamic
  // titles so that they can gain access to `formValues` at any
  // given `sectionKey`:
  // eslint-disable-next-line
  const [, dispatch, , , , , getValue] = useContext(BuilderContext)
  const [, openModal, closeModal] = useContext(ModalContext)
  const { dynamicTitle, renderCondition, deletable, hideable, hidden, expandable, expanded, tips = [], template, style = {} } = section

  const evalDynamicTitle = dynamicTitle => {
    try {
      // `eval` must be used to resolve the `dynamicTitle` value:
      // eslint-disable-next-line
      return eval(dynamicTitle)
    } catch {
      return ''
    }
  }
  const title = !!dynamicTitle ? evalDynamicTitle(dynamicTitle) : section.title

  const showHeading = !!title || !!expandable

  const toggleHeading = _ => {
    dispatch(Action.ToggleSection(sectionKey, section))
  }

  const toggleVisibility = _ => {
    dispatch(Action.ToggleSectionVisibility(sectionKey, section))
  }

  const deleteSection = _ => {
    openModal({
      title: _ => (
        <span>Delete "{title}"?</span>
      ),
      body: _ => {
        const onDelete = _ => {
          dispatch(Action.DeleteSection(parentKey, sectionKey, index))
          closeModal()
        }
        return (
          <React.Fragment>
            <p>"{title}" and its contents will be deleted. Are you sure?</p>
            <div>
              <Button type={ButtonType.Danger} className='mr-3 mb-2' onClick={onDelete}>Delete</Button>
              <Button type={ButtonType.Neutral} onClick={closeModal}>Cancel</Button>
            </div>
          </React.Fragment>
        )
      },
    })
  }

  const anyActions = expandable || deletable

  const [Template, setTemplate] = useState(null)

  useEffect(_ => {
    if (!!template) {
      setTemplate(_ => load(template))
    }
  }, [template])

  if (!!renderCondition) {
    try {
      // eslint-disable-next-line
      const shouldRender = eval(renderCondition)
      if (!shouldRender) { return null }
    } catch (e) {
      throw new Error(`Render condition error: ${e}`)
    }
  }

  return (
    <SectionContext.Provider value={{ sectionKey, index, formValues }}>
      <SectionDebug />
      <div style={style} className={clsx(styles.section)}>
        <div className={clsx((!!title || anyActions) && 'p-3')}>
          {
            showHeading && (
              <div className='d-flex flex-row flex-wrap justify-content-between'>
                <div>
                  {!!title && <span className={styles.title}>{title}</span>}
                  <div className={clsx(styles.actions, 'd-inline-block ml-1')}>
                    {tips.length > 0 && <Tooltip className={styles.bulb} tips={tips} />}
                  </div>
                </div>
                <div className={clsx(styles.actions, styles.bar, expanded && styles.bar__expanded)}>
                  {hideable && (
                    <React.Fragment>
                      <div className={styles.divider} />
                      <button onClick={toggleVisibility} style={{ marginLeft: '-1px' }}>{hidden ? (
                          <span>
                            <EyeSlash /> <span className='small'>Show</span>
                          </span>
                        ) : (
                          <span>
                            <Eye /> <span className='small'>Hide</span>
                          </span>
                        )}
                      </button>
                    </React.Fragment>
                   )}
                  {deletable && (
                    <React.Fragment>
                      <div className={styles.divider} />
                      <button onClick={deleteSection}><Trash /></button>
                    </React.Fragment>
                  )}
                  {expandable && (
                    <React.Fragment>
                      <div className={styles.divider} />
                      <button onClick={toggleHeading}>{expanded ? <ChevronUp /> : <ChevronDown />}</button>
                    </React.Fragment>
                  )}
                </div>
              </div>
            )
          }
        </div>
        {
          template && (
            <div className={clsx('px-3', 'py-3')}>
              {Template && <Template />}
            </div>
          )
        }
        {
          expanded && (
            <div className={clsx('px-3', !!title && 'pt-0 pb-3', !title && 'py-3')}>
              {children}
            </div>
          )
        }
      </div>
    </SectionContext.Provider>
  )
}

export default Section
