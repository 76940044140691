import React from 'react'


function CheckboxLabel({
  children, name, label, fieldInfo, fieldData,
  labelComponent: Label,
  ...rest
}) {
  return (
    <div className='form-row' {...rest}>
      <div className='col-11'>
        <Label
          style={{ width: '100%' }}
          name={name}
          label={label}
          fieldInfo={fieldInfo}
          fieldData={fieldData} />
      </div>
      <div className='col-1 d-flex justify-content-end align-items-center'>
        {children}
      </div>
    </div>
  )
}

export default CheckboxLabel
