import React from 'react'
import { pdfjs } from 'react-pdf'

import { DebugContext } from './debug/_context'
import AppHeader from './header/AppHeader'
import AppFooter from './footer/AppFooter'
import './App.css'

import { BrowserRouter as Router } from 'react-router-dom'
import Routes from './routing/Routes'
import { default as AccountProvider } from './account/Provider'
import { default as ModalProvider } from './modals/Provider'
import ApiErrorHandler from './core/ApiErrorHandler'


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`


function App() {
  return (
    <DebugContext.Provider value={{ debug: false }}>
      <Router>
        <AccountProvider>
          <ModalProvider>
            <ApiErrorHandler>
              <div className='App d-flex flex-column'>
                <AppHeader />
                <div style={{ flex: 1 }}>
                  <Routes />
                </div>
                <AppFooter />
              </div>
            </ApiErrorHandler>
          </ModalProvider>
        </AccountProvider>
      </Router>
    </DebugContext.Provider>
  );
}

export default App
