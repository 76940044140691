import React from 'react'
import { default as DatePickerCore } from 'react-datepicker'

import styles from './DatePicker.module.css'


function DatePicker({ input, fieldInfo, fieldData, placeholder, ...rest }) {
  return (
    <div className={styles.picker}>
      <DatePickerCore
        className='form-control'
        dateFormat='dd/MM/yyyy'
        placeholderText={placeholder}
        selected={input.value}
        dropdownMode='select'
        showMonthDropdown
        showYearDropdown
        {...input}
        {...rest} />
    </div>
  )
}

export default DatePicker
