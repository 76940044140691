import React, { useContext } from 'react'
import clsx from 'clsx'

import { withUnauthenticatedRedirect } from './withUnauthenticatedRedirect'
import typographyStyles from '../typography/Typography.module.css'
import Button, { ButtonType } from '../forms/buttons/Button'
import { Link } from 'react-router-dom'
import ScrollToTopOnMount from '../routing/ScrollToTopOnMount'
import { Urls } from '../routing/urls'
import { AccountContext } from './_context'


function DeleteAccount() {

  const [, , , , , deleteAndReset] = useContext(AccountContext)

  return (
    <React.Fragment>
      <ScrollToTopOnMount />
      <div className='py-5'>
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <h1 className={clsx(typographyStyles.title)}>Delete account</h1>
            </div>
          </div>
          <div className='row my-4'>
            <div className='col-md-5'>
              <p>Are you sure?</p>
              <p>Deleting your account you will also permanently delete any resumes saved in your account.</p>
              <div className='my-4'>
                <Button type={ButtonType.Danger} onClick={deleteAndReset}>Delete my account</Button>
              </div>
              <div>
                <Link to={Urls.MyAccount}>Back</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  )
}

export default withUnauthenticatedRedirect()(DeleteAccount)
