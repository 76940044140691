import React from 'react'


function Intro() {
  return (
    <React.Fragment>
      <p className='m-0'>Keep it recent and relevant. Start with your most recent jobs and preferably jobs where you have been there the longest.</p>
    </React.Fragment>
  )
}

export default Intro
