import React from 'react'
import clsx from 'clsx'

import styles from './FieldError.module.css'


function FieldError({ className, children }) {
  return (
    <span className={clsx(styles.error, className)}>{children}</span>
  )
}

export default FieldError
