import { getGlobals } from '../Globals'


export const isLoggedIn = _ => {
  return !!getGlobals().user.id
}

export const isAccountReady = account => {
  return !!account.email
}
