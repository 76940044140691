import React, { useContext } from 'react'

import { FormContext } from '../../_context'
import AddButton from './common/AddButton'
import { Action } from '../../_actions'
import { getIn } from '../../structure'
import { FieldComponentType } from '../maps'


// IMPORTANT:

// The correct usage for `AddSection` is to exist as the only field inside of a
// section. It must receive a `fieldData.config`, which details the structure of
// the new section it will insert in place.

// For now, we will just splice into the parent section's sections at the position
// just in front of the field's section's position (i.e. second to last index, so
// practically we will be inserting a new section as a sibling).

// If we need more fine-grained control over where a new section is added we will
// likely need a way to uniquely identify both initial and added sections.

function AddSection({ label, fieldInfo, fieldData }) {
  const { sectionIndex, location, parent } = fieldInfo
  const [formConfig, dispatch] = useContext(FormContext)

  const section = getIn(formConfig, location)
  if (section.fields.length > 1) {
    throw new Error(`AddSection should be the only field in its section. Location: ${location}`)
  }

  const addSectionConfig = {
    ...fieldData.config,

    // Additional section metadata letting consumers of the config know what created
    // the current section.
    originator: FieldComponentType.AddSection,
  }

  const addSection = _ => dispatch(Action.AddSection(parent, sectionIndex, addSectionConfig))

  return (
    <AddButton onClick={addSection}>
      {label}
    </AddButton>
  )
}

export default AddSection
