export const EventCategory = {
  Builder: 'BUILDER',
  JobReady: 'JOB_READY',
}

export const EventDescription = {
  ExportedResume: 'EXPORTED_RESUME',
  ViewedTFNQuestion: 'VIEWED_TFN_QUESTION',
  ViewedSuperQuestion: 'VIEWED_SUPER_QUESTION',
  ViewedSuperInfo: 'VIEWED_SUPER_INFO',
  ViewedSuperForm: 'VIEWED_SUPER_FORM',
  ViewedEndScreen: 'VIEWED_END_SCREEN',
}
