import React, { useReducer, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router-dom'
import clsx from 'clsx'

import { BuilderContext, FormContext } from './_context'
import { reducer } from './_reducers'
import Preview from './preview/Preview'
import FormContainer from './form/FormContainer'
import { getResume } from './_requests'
import { Action } from './_actions'
import { saveResume } from './save'
import { useDebounce } from '../hooks/useDebounce'
import styles from './Builder.module.css'
import { withUnauthenticatedRedirect } from '../account/withUnauthenticatedRedirect'
import { BuilderConfig } from './config'
import { getIn } from './structure'


function Builder({ isPublic = false }) {

  const [state, dispatch] = useReducer(reducer, {})
  const { formValues, formConfig, previewUrl, previewOpen } = state

  const [oldState, setOldState] = useState(JSON.stringify({ formValues, formConfig }))
  const [saving, setSaving] = useState(false)
  const [didLoadOnce, setDidLoadOnce] = useState(false)
  const debouncedState = useDebounce(oldState, BuilderConfig.DebounceDelay)

  const { resumeId } = useParams()

  const update = _ => {
    if (state.resumeId) {
      // Show the state as if it's still saving even after the actual request to
      // save has finished - it takes some time for the PDF library to render the
      // blob contents on the page. `setSaving` is then toggled off in a callback
      // on the PDF library when it has finished loading the PDF contents.
      saveResume(state, dispatch).then(_ => setSaving(true))
    }
  }

  useEffect(_ => {
    // `previewUrl` and `downloadUrl` come back as `null` until the PDF file has
    // been generated and is ready. We could move this to WebSockets at a later
    // point to improve snappiness. For now just poll on a relatively quick
    // interval and only update when `previewUrl` and `downloadUrl` have values.
    if (!resumeId) { return }
    let timer = null
    const poll = () => {
      if (!previewUrl) {
        setSaving(true)
        getResume(resumeId).then(resume => {
          if (!!resume.previewUrl) {
            dispatch(Action.SetUrlData(resume.previewUrl, resume.downloadUrl))
          } else {
            timer = setTimeout(poll, 500)
          }
        })
      }
    }
    poll()
    // Cancel any pending `setTimeout` callback on unmount.
    return _ => clearTimeout(timer)
  }, [resumeId, previewUrl])

  useEffect(_ => {
    setSaving(true)
    getResume(resumeId).then(resume => dispatch(Action.SetResumeData(resume)))
  }, [resumeId])

  useEffect(_ => {
    const newState = JSON.stringify({
      name: state.name,
      template: state.template,
      formValues: state.formValues,
      formConfig: state.formConfig,
    })
    if (oldState === debouncedState && didLoadOnce) {
      if (!!previewUrl) {
        setSaving(false)
      }
    }
    if (oldState !== newState) {
      setSaving(true)
      setOldState(newState)
    }
    // Purposefully don't include `debouncedState` as a dependency to this effect.
    // We don't want changes in the `debouncedState` value to toggle the saving loader:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldState, state])

  // Purposefully don't include `update` as a dependency to the following hook
  // on `debouncedState`. This is because `update` changes with every render
  // (as intended) but we only want to execute the effect when `debouncedState`
  // changes:
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(update, [debouncedState])

  const getValue = key => getIn(formValues, key)

  return Object.keys(state).length > 0 && (
    <BuilderContext.Provider value={[state, dispatch, saving, setSaving, setDidLoadOnce, isPublic, getValue]}>
      <div className={clsx('d-flex flex-row', styles.builder)}>
        <div className={clsx(styles.editorContainer, 'w-50 px-3')}>
          <FormContext.Provider value={[formConfig, dispatch]}>
            <Form
              {...formConfig}
              showTitle={!isPublic}
              component={FormContainer}
              initialValues={formValues || {}}
              validateOnBlur={true}
              keepDirtyOnReinitialize={true}
              subscription={{ submitting: true, hasValidationErrors: true }}
              onSubmit={values => values} />
          </FormContext.Provider>
        </div>
        <div className={clsx(styles.previewContainer, !previewOpen && styles.previewContainer__closed, 'w-50')}>
          <Preview />
        </div>
      </div>
    </BuilderContext.Provider>
  )
}

export default Builder

export const AuthenticatedBuilder = withUnauthenticatedRedirect()(Builder)
export const PublicBuilder = _ => <Builder isPublic={true} />
