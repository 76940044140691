import React, { useState, useEffect, useCallback } from 'react'
import { useHistory, generatePath, Switch, Route, Redirect, useRouteMatch, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import clsx from 'clsx'

import { withAuthenticatedRedirect } from '../account/withAuthenticatedRedirect'
import typographyStyles from '../typography/Typography.module.css'
import styles from './GetStarted.module.css'
import TemplatePicker from '../template/TemplatePicker'
import GetStartedSurvey from './GetStartedSurvey'
import { VersionTemplateMap, TemplateDisplayNameMap } from '../builder/preview/maps'
import { getLatestVersion } from '../builder/version'
import { startAccount } from './start'
import { Urls } from '../routing/urls'
import ScrollToTopOnMount from '../routing/ScrollToTopOnMount'
import { TemplateCardSize } from '../template/TemplateCard'


function GetStarted() {

  const history = useHistory()
  const location = useLocation()
  const [survey, setSurvey] = useState(null)
  const [template, setTemplate] = useState(null)

  const templates = VersionTemplateMap[getLatestVersion()].map(name => ({
    id: name,
    name: TemplateDisplayNameMap[name],
    thumbnailUrl: generatePath(Urls.Builder_TemplateThumbnail, { templateId: name }),
  }))

  const getNextUrl = useCallback(_ => {
    if (!template) return Urls.GetStarted_Template
    if (!survey) return Urls.GetStarted_Survey
    return null
  }, [survey, template])

  useEffect(_ => {
    // Both the survey and template selections should be provided
    // starting the account creation flow.
    const nextUrl = getNextUrl()
    if (nextUrl) {
      history.push(nextUrl)
    } else {
      startAccount(template.id).then(resume => {
        history.push({
          pathname: generatePath(Urls.GetStarted_Builder, { resumeId: resume.resumeId }),
          search: queryString.stringify({
            template: template.id,
            survey: survey,
          }),
        })
      })
    }
  }, [getNextUrl, history, survey, template])

  useEffect(_ => {
    const { survey } = queryString.parse(location.search)
    if (survey) {
      setSurvey(survey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { path } = useRouteMatch()
  const redirectUrl = path === window.location.pathname ? getNextUrl() : null

  return (
    <React.Fragment>
      <ScrollToTopOnMount />
      <Switch>
        {!!redirectUrl && <Route exact path={path}><Redirect to={redirectUrl} /></Route>}
        <Route exact path={Urls.GetStarted_Survey}>
          <div className='container py-5'>
            <div className='row'>
              <div className='col'>
                <h1 className={clsx(typographyStyles.title, styles.title)}>Let's get started</h1>
                <div className='lead'>Tell us a bit more about you</div>
              </div>
            </div>
            <div className='row py-5'>
              <div className='col'>
                <GetStartedSurvey onComplete={setSurvey} />
              </div>
            </div>
          </div>
        </Route>
        <Route exact path={Urls.GetStarted_Template}>
          <div className='container py-5'>
            <div className='row'>
              <div className='col'>
                <h1 className={clsx(typographyStyles.title, styles.title)}>Let's get started</h1>
                <div className='lead'>Click to pick a template</div>
                {/* <div className={typographyStyles.psst}>(Psst, you can always change this)</div> */}
              </div>
            </div>
            <div className='row py-5'>
              <div className='col'>
                <TemplatePicker
                  templates={templates}
                  emptyMessage={`Sorry! We couldn't find any templates.`}
                  size={TemplateCardSize.Large}
                  onClick={setTemplate} />
              </div>
            </div>
          </div>
        </Route>
      </Switch>
    </React.Fragment>
  )
}

export default withAuthenticatedRedirect()(GetStarted)
