import React from 'react'
import clsx from 'clsx'

import StackedField from './StackedField'
import Input from '../fields/Input'
import Select from '../fields/Select'
import { required } from '../validation'
import StackedFieldRow from './StackedFieldRow'
import FormError from '../messaging/FormError'


function AustralianAddressField({ name, label, className, submitErrors = {} }) {
  return (
    <div className={clsx('my-3', className)}>
      <label className='mt-3 mb--2'><strong>{label}</strong></label>
      <StackedField
        name={`${name}.street`} label='Street' validate={required}
        component={Input} />
      <StackedField
        name={`${name}.suburb`} label='Suburb' validate={required}
        component={Input} />
      <StackedFieldRow>
        <StackedField
          className='col-6'
          name={`${name}.state`} label='State' validate={required}
          component={Select}
          fieldData={{
            defaultOption: { label: 'Select' },
            options: [
              { label: 'Australian Capital Territory', value: 'ACT' },
              { label: 'New South Wales', value: 'NSW' },
              { label: 'Northern Territory', value: 'NT' },
              { label: 'Queensland', value: 'QLD' },
              { label: 'South Australia', value: 'SA' },
              { label: 'Tasmania', value: 'TAS' },
              { label: 'Victoria', value: 'VIC' },
              { label: 'Western Australia', value: 'WA' },
            ]
          }} />
        <StackedField
          className='col-6'
          name={`${name}.postcode`} label='Postcode' validate={required}
          component={Input} />
      </StackedFieldRow>
      {!!submitErrors[name] && <FormError className='mt-3'>There was an error with this address.</FormError>}
    </div>
  )
}

export default AustralianAddressField