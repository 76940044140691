import React from 'react'
import { getGlobals } from '../Globals'


function Error() {
  const { status } = getGlobals()
  return (
    <div className='display-2 text-center mt-5'>{status}</div>
  )
}

export default Error
