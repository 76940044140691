import React from 'react'
import clsx from 'clsx'

import styles from './Button.module.css'


export const ButtonType = {
  CtaPrimary: 'ctaPrimary',
  CtaSecondary: 'ctaSecondary',
  CtaPrimaryInverse: 'ctaPrimaryInverse',
  CtaSecondaryInverse: 'ctaSecondaryInverse',
  Danger: 'danger',
  Neutral: 'neutral',
}

const ButtonTypeStyleMap = {
  [ButtonType.CtaPrimary]: styles.ctaPrimary,
  [ButtonType.CtaSecondary]: styles.ctaSecondary,
  [ButtonType.CtaPrimaryInverse]: styles.ctaPrimaryInverse,
  [ButtonType.CtaSecondaryInverse]: styles.ctaSecondaryInverse,
  [ButtonType.Danger]: styles.danger,
  [ButtonType.Neutral]: styles.neutral,
}

function Button({ type, className, submit, ...rest }) {
  return (
    <button
      className={clsx(styles.button, ButtonTypeStyleMap[type], className)}
      {...(!!submit ? { type: 'submit' } : {})}
      {...rest} />
  )
}

export default Button
