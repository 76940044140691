import React from 'react'
import clsx from 'clsx'

import styles from './AppFooter.module.css'
import { Link, useRouteMatch } from 'react-router-dom'
import { Urls } from '../routing/urls'
import { getGlobals } from '../Globals'


function AppFooter() {

  const isBuilderView = useRouteMatch(Urls.Builder)
  const isGetStartedBuilderView = useRouteMatch(Urls.GetStarted_Builder)
  const isHidden = isBuilderView || isGetStartedBuilderView

  const { site } = getGlobals()

  return (
    !isHidden
      ? (
        <div className={clsx(styles.footer, 'py-5')}>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-3 mb-4'>
                <ul>
                  <li><Link to={Urls.FAQ}>FAQ</Link></li>
                  <li><a href={Urls.Blog}>Blog</a></li>
                  <li><Link to={Urls.About}>About</Link></li>
                  <li><Link to={Urls.Terms}>Terms</Link></li>
                  <li><Link to={Urls.Privacy}>Privacy</Link></li>
                </ul>
              </div>
              <div className='col-sm-3 mb-4'>
                <ul>
                  <li><a href='https://www.studentvip.com.au/' target='_blank' rel='noopener noreferrer'>StudentVIP</a></li>
                  <li><a href='https://www.highschooltutors.com.au/' target='_blank' rel='noopener noreferrer'>High School Tutors</a></li>
                  <li><a href='https://studentvip.com.au/lost-on-campus/' target='_blank' rel='noopener noreferrer'>Lost on Campus</a></li>
                  <li><a href='https://www.summerjobs.com.au/' target='_blank' rel='noopener noreferrer'>Summer Jobs</a></li>
                  <li><a href='https://www.studentinternships.com.au/' target='_blank' rel='noopener noreferrer'>Student Internships</a></li>
                </ul>
              </div>
              <div className='col-sm-3'>
              </div>
              <div className='col-sm-3 text-right'>
                <ul className={styles.social}>
                  <li>
                    <a href={site.facebookUrl} target='_blank' rel='noopener noreferrer'>
                      <span className={styles.facebook}>
                        Facebook
                        <svg viewBox="0 0 54 100">
                          <path d="M50.095 55.825l2.754-17.96H35.616V26.207c0-4.913 2.408-9.703 10.126-9.703h7.836V1.214S46.467 0 39.668 0C25.476 0 16.2 8.602 16.2 24.175v13.69H.422v17.96H16.2v43.42c3.163.497 6.406.755 9.709.755s6.545-.258 9.708-.755v-43.42h14.479z" fill="#FFF" fillRule="nonzero" />
                        </svg>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href={site.youtubeUrl} target='_blank' rel='noopener noreferrer'>
                      <span className={styles.youtube}>
                        YouTube
                        <svg viewBox="0 0 100 70">
                          <path d="M50.572.001c4.28.013 31.322.166 38.497 2.09a12.522 12.522 0 018.841 8.84c1.971 7.355 2.083 22.248 2.09 23.916v.306c-.007 1.669-.119 16.562-2.09 23.916a12.523 12.523 0 01-8.84 8.842C81.581 69.916 52.463 69.996 50.145 70h-.293c-2.317-.004-31.436-.084-38.923-2.09a12.525 12.525 0 01-8.84-8.84C.165 51.891.012 37.534 0 35.296v-.593c.012-2.239.165-16.595 2.088-23.773a12.524 12.524 0 018.84-8.84C18.106.166 45.148.013 49.428 0zM35.944 15.318v39.365L70.034 35l-34.09-19.682z" fill="#FFF" fillRule="nonzero" />
                        </svg>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )
      : null
  )
}

export default AppFooter
