import React, { useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { List, X } from 'react-bootstrap-icons'
import clsx from 'clsx'

import dog from '../assets/resume-dog.svg'
import styles from './AppHeader.module.css'
import { Urls } from '../routing/urls'
import { getGlobals } from '../Globals'
import Navigation from '../routing/Navigation'


export const AppHeaderStyle = {
  Compact: 'container',
  Fluid: 'container-fluid',
}

export default function AppHeader() {

  const siteName = getGlobals().site.name

  const isBuilderView = useRouteMatch(Urls.Builder)
  const isGetStartedBuilderView = useRouteMatch(Urls.GetStarted_Builder)

  const style = isBuilderView || isGetStartedBuilderView ? AppHeaderStyle.Fluid : AppHeaderStyle.Compact

  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <React.Fragment>
      <div className={clsx('d-flex justify-content-center')}>
        <header className={clsx(styles.bar, style)}>
          <div className='row'>
            <div className={clsx(styles.bar__sectioner, 'col')}>
              <Link className={styles.titleLink} to={Urls.Home}>
                <h1 className={styles.title}>
                  <div className={styles.logo__container}>
                    <img src={dog} className={styles.logo} alt={siteName} />
                  </div>
                  <span className={styles.title__text}>{siteName}</span>
                  <span className={styles.super}>Beta</span>
                </h1>
              </Link>
              <div className={styles.links__open} onClick={_ => setMenuOpen(true)}><List /></div>
              <div className={clsx(styles.links, menuOpen && styles.links__visible)}>
                <div className={styles.links__close} onClick={_ => setMenuOpen(false)}><X /></div>
                <Navigation onClick={_ => setMenuOpen(false)} />
              </div>
            </div>
          </div>
        </header>
      </div>
      <div className={styles.spacer} />
    </React.Fragment>
  )
}
