import React from 'react'
import clsx from 'clsx'

import TemplateCard from './TemplateCard'
import typographyStyles from '../typography/Typography.module.css'


function TemplatePicker({ templates = [], emptyMessage, ...rest }) {
  return (
    <div className='d-flex justify-content-center flex-wrap'>
      {
        templates.length > 0 && templates.map((template, i) => (
          <div key={i} className={clsx('m-2')}>
            <TemplateCard template={template} {...rest} />
          </div>
        ))
      }
      {
        templates.length === 0 && (
          <div
            className={clsx(typographyStyles.psst, 'text-center')}
            dangerouslySetInnerHTML={{ __html: emptyMessage }} />
        )
      }
    </div>
  )
}

export default TemplatePicker
