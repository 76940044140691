import { EventDescription } from "../events"
import { StudyStatus } from "../account"
import { Urls } from "../routing/urls"


export const isNotInterestedInSuper = account => {
  return account.hasSuper && !account.wantsSuperInfo
}

export const getSuperInfoPageUrl = account => {
  return account.studyStatus === StudyStatus.NotStudying ? Urls.GetJobReady_Super_ProfessionalSuper : Urls.GetJobReady_Super_StudentSuper
}

export const getSuperFormPageUrl = account => {
  return account.studyStatus === StudyStatus.NotStudying ? Urls.GetJobReady_Super_ProfessionalSuper_Start : Urls.GetJobReady_Super_StudentSuper_Start
}

export const needsToGetJobReady = account => {
  return false
  const hasNotSeenSuperInfoPage = [null, EventDescription.ViewedTFNQuestion, EventDescription.ViewedSuperQuestion].includes(account.lastGetJobReadyStep)
  return hasNotSeenSuperInfoPage && !isNotInterestedInSuper(account)
}

export const finishedJobReady = account => {
  return true
  return account.lastGetJobReadyStep === EventDescription.ViewedEndScreen && account.hasSuccessfullySubmittedGetJobReady
}
