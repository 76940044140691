import React from 'react'

import PageTitle from './common/PageTitle'
import ScrollToTopOnMount from '../routing/ScrollToTopOnMount'
import { getGlobals } from '../Globals'


function About() {
  const { site } = getGlobals()
  return (
    <React.Fragment>
      <ScrollToTopOnMount />
      <div className='container py-5'>
        <div className='row'>
          <div className='col'>
            <PageTitle>About</PageTitle>
            <p>{site.name} is owned by Student Services Australia (SSA). We've been around for 25 years creating fun and useful services for young people.</p>
            <p>If you haven't heard of SSA, but you're a Australian student.... you may already be using one of our services! They include:</p>
            <ul>
              <li><a href='https://www.studentvip.com.au/' target='_blank' rel='noopener noreferrer'>StudentVIP</a></li>
              <li><a href='https://studentvip.com.au/lost-on-campus/' target='_blank' rel='noopener noreferrer'>Lost on Campus</a> apps</li>
              <li><a href='https://www.highschooltutors.com.au/' target='_blank' rel='noopener noreferrer'>High School Tutors</a></li>
              <li><a href='https://www.summerjobs.com.au/' target='_blank' rel='noopener noreferrer'>Summer Jobs</a></li>
            </ul>
            <p>Our StudentVIP community has over 550,000 uni students nationally buying and selling second hand textbooks, subject notes and finding a tutor.</p>
            <p>So, why have we launched a resume website?</p>
            <p>We've had a long history of employing a lot of young people for O-Week and to create our uni mapping app called <a href='https://studentvip.com.au/lost-on-campus/' target='_blank' rel='noopener noreferrer'>Lost on Campus</a>. So in our time, we have hired a lot of students, and seen many, many resumes.</p>
            <p>We know one of the biggest stresses of creating a resume is fiddling around with the formatting or getting a mind blank of what to include - especially if this is your first resume!</p>
            <p>Starting a resume from scratch can be daunting, but using {site.name} feels like you're answering a questionnaire! We've included as many tips that can help you create the best resume.</p>
            <p>{site.name} is <strong>100% free</strong> to use.</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default About
