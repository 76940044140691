import { useEffect, useState, useContext } from 'react'
import { useFormState } from 'react-final-form'

import { BuilderContext } from '../_context'
import { Action } from '../_actions'
import { useDebounce } from '../../hooks/useDebounce'
import { BuilderConfig } from '../config'


function FormWatcher() {
  const [, dispatch] = useContext(BuilderContext)
  const [oldValues, setNewValues] = useState(JSON.stringify({}))

  // We tell `react-final-form` not to re-render on every update to the form
  // state to improve performance. In order to access the form values we then
  // need to use a `FormSpy` (or `useFormState`, its internal implementation).
  // See: https://final-form.org/docs/react-final-form/examples/subscriptions
  const { values: formValues } = useFormState({ subscription: { values: true } })

  const formValuesDebounced = useDebounce(formValues, BuilderConfig.DebounceDelay)

  useEffect(_ => {
    // We look for a diff in the form values before dispatching an
    // update, otherwise we will run into an infinite render loop.
    const newValues = JSON.stringify(formValues)
    setNewValues(newValues)
    if (oldValues !== newValues) {
      // Updating the form values in the top level state will result in
      // a re-initialisation of the form (via initialValues). This causes
      // an expensive re-render of the entire form. Only do this sparingly
      // as doing so on every keystroke causes a perceptible input delay.
      dispatch(Action.SetFormValues(formValues))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValuesDebounced])

  return null
}

export default FormWatcher
