export const EmploymentStatus = {
  Working: 'WORKING',
  NotWorking: 'NOT_WORKING',
}

export const StudyStatus = {
  Unknown: 'UNKNOWN',
  Highschool: 'HIGHSCHOOL',
  UniTafe: 'UNI_TAFE',
  NotStudying: 'NOT_STUDYING',
}

export const WorkExperienceLevel = {
  None: 'NONE',
  Some: 'SOME',
  Lots: 'LOTS',
}
