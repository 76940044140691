import React, { useState, useEffect } from 'react'
import { Form } from 'react-final-form'
import { Link, useParams } from 'react-router-dom'

import { withAuthenticatedRedirect } from './withAuthenticatedRedirect'
import typographyStyles from '../typography/Typography.module.css'
import Input from '../forms/fields/Input'
import Button, { ButtonType } from '../forms/buttons/Button'
import StackedField from '../forms/layouts/StackedField'
import { required } from '../forms/validation'
import { Urls } from '../routing/urls'
import { checkResetPasswordToken, submitResetPassword } from './_requests'
import { withFormErrorAsync } from '../forms/errors'
import FormError from '../forms/messaging/FormError'


function ResetPasswordConfirm() {
  const [tokenChecked, setTokenChecked] = useState(false)
  const [expired, setExpired] = useState(true)
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false)
  const { tokenId } = useParams()

  const save = async ({ password }) => {
    await submitResetPassword(password, tokenId)
    setResetPasswordSuccess(true)
  }

  useEffect(_ => {
    if (tokenId) {
      checkResetPasswordToken(tokenId)
        .then(_ => {
          setTokenChecked(true)
          setExpired(false)
        })
        .catch(_ => {
          setTokenChecked(true)
          setExpired(true)
        })
    }
  }, [tokenId])

  return (
    <div className='container py-5'>
      <div className='row'>
        <div className='col-md-5'>
          {!tokenChecked && (
            <React.Fragment>
              <h1 className={typographyStyles.title}>Forgot your password?</h1>
              <p>
                Please hold on while we check your password reset link...
              </p>
            </React.Fragment>
          )}
          {tokenChecked && expired && (
            <React.Fragment>
              <h1 className={typographyStyles.title}>Link expired</h1>
              <p>
                This password reset link has expired.
              </p>
              <p>
                You can always <Link to={Urls.ResetPassword}>request a new password reset link to your email by clicking here</Link>.
              </p>
            </React.Fragment>
          )}
          {tokenChecked && !expired && (
            <React.Fragment>
              <h1 className={typographyStyles.title}>Reset password</h1>
              {!resetPasswordSuccess && (
                <p>Enter and save your new password below.</p>
              )}
              {resetPasswordSuccess && (
                <p>Done!</p>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
      {tokenChecked && !expired && (
        <React.Fragment>
          {!resetPasswordSuccess && (
            <div className='row'>
              <div className='col-md-4 mt--2'>
                <Form
                  onSubmit={withFormErrorAsync(save)}
                  render={({ handleSubmit, submitting, submitError }) => (
                    <form onSubmit={handleSubmit}>
                      <StackedField name='password' label='New password' validate={required} component={Input} type='password' autoFocus />
                      {submitError && <FormError className="error">{submitError}</FormError>}
                      <div className='mt-4'>
                        <Button submit
                          type={ButtonType.CtaSecondary}
                          disabled={submitting}>
                          Save
                        </Button>
                      </div>
                    </form>
                  )}>
                </Form>
              </div>
            </div>
          )}
          {resetPasswordSuccess && (
            <p>You can now <Link to={Urls.Login}>log in with your new password</Link>.</p>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

export default withAuthenticatedRedirect()(ResetPasswordConfirm)
