import React from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

import Button, { ButtonType } from '../forms/buttons/Button'
import { Urls } from '../routing/urls'
import fastClock from '../assets/icons/fast-clock-black.svg'
import preview from '../assets/icons/preview-black.svg'
import upload from '../assets/icons/upload-black.svg'
import bulb from '../assets/icons/bulb-black.svg'
import styles from './Home.module.css'
import typographyStyles from '../typography/Typography.module.css'
import dog from '../assets/resume-dog.svg'
import editors from '../assets/resume-editors.svg'
import ScrollToTopOnMount from '../routing/ScrollToTopOnMount'
import GetStartedSurvey from '../getstarted/GetStartedSurvey'


function Home() {
  return (
    <React.Fragment>
      <ScrollToTopOnMount />
      <div className={clsx('overflow-hidden', styles.whiteband)}>
        <div className={clsx('container py-5 position-relative')}>
          <div className='row'>
            <div className='col-md-6'>
              <h1 className={clsx(typographyStyles.title, styles.title, 'mb-3 display-5')}>We make creating a resume <u>easy</u></h1>
              <p className={clsx(styles.lead, 'lead')}>
                Choose a template.<br />
                Fill in the blanks.<br />
                Create a winning resume now.
              </p>
              <Link to={Urls.GetStarted}>
                <Button className='mt-3 mb-4' type={ButtonType.CtaSecondary}>Get started</Button>
              </Link>
              <p className='lead'>
                100% free and 100% Australian.
              </p>
            </div>
            <div className='col-md-6'>
              <div className='py-2'>
                <img src={editors} className={styles.editors} alt='' />
                <img src={dog} className={styles.dog} alt='' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={clsx(styles.features, 'container py-5')}>
        <div className={clsx('row mt-3')}>
          {
            [
              {
                icon: preview,
                iconStyles: { transform: 'scale(1.05)', transformOrigin: 'top left' },
                title: 'Live preview',
                description: 'See your resume come to life as you complete each section.',
              },
              {
                icon: upload,
                iconStyles: { transform: 'scale(.9)', transformOrigin: 'top left' },
                title: 'Auto save',
                description: 'Never lose your work. Your resume will save automatically.',
              },
              {
                icon: fastClock,
                iconStyles: {},
                title: 'Short cuts',
                description: 'No more hours wasted formatting. Kick-ass templates to the rescue.',
              },
              {
                icon: bulb,
                iconStyles: { transform: 'scale(1.05)', transformOrigin: 'bottom left' },
                title: 'Writing tips',
                description: 'Don’t stress. Our tips help you create a winning resume.',
              },
            ].map(({ icon, iconStyles, title, description }, i) => (
              <div key={i} className={clsx(styles.feature, 'col-sm-3 mt--2')}>
                <img src={icon} style={iconStyles} alt='' />
                <div className={clsx('h4 font-weight-bold mt-3', styles.orange)}>{title}</div>
                <p>{description}</p>
              </div>
            ))
          }
        </div>
      </div>
      <div className={styles.whiteband}>
        <div className='container py-5'>
          <h1 className={clsx(typographyStyles.title, styles.orange, 'mb-3 display-4')}>Let's start...</h1>
          <GetStartedSurvey showGoButton={true} />
        </div>
      </div>
      {/* <div className='container py-5'>
        <p className={clsx(styles.lead, 'lead')}>Our top blog posts</p>
        <BlogPostLinker />
      </div> */}
    </React.Fragment>
  )
}

export default Home
