import React from 'react'

import styles from './AddButton.module.css'


function AddButton({ children, ...rest }) {
  return (
    <button className={styles.button} {...rest}>
      {children}
    </button>
  )
}

export default AddButton
