export const checkImageExists = async url => {
  const promise = new Promise(
    async (resolve, reject) => {
      const image = new Image()
      image.src = url
      image.onload = _ => resolve()
      image.onerror = _ => reject()
    }
  )
  return promise
}
