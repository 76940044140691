import React from 'react'
import clsx from 'clsx'

import typographyStyles from '../../typography/Typography.module.css'


function SectionTitle({ children }) {
  return (
    <h2 className={clsx(typographyStyles.title, 'h4')}>{children}</h2>
  )
}

export default SectionTitle
