import React from 'react'
import { Check } from 'react-bootstrap-icons'
import clsx from 'clsx'

import styles from './TickList.module.css'


function TickList({ items = [], ...rest }) {
  return (
    <ul {...rest}>
      {
        items.map((item, i) => (
          <li className={clsx(styles.item, 'my-3')} key={i}>
            <span className={styles.check}><Check /></span>
            <span dangerouslySetInnerHTML={{ __html: item }} />
          </li>
        ))
      }
    </ul>
  )
}

export default TickList
