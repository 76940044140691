import React from 'react'


function Input({ input, fieldInfo, fieldData, ...rest }) {
  return (
    <input
      className='form-control'
      {...input}
      {...rest} />
  )
}

export default Input
