import React from 'react'

import SectionTitle from './common/SectionTitle'
import PageTitle from './common/PageTitle'
import ScrollToTopOnMount from '../routing/ScrollToTopOnMount'
import { getGlobals } from '../Globals'


function FAQ() {
  const { site } = getGlobals()
  return (
    <React.Fragment>
      <ScrollToTopOnMount />
      <div className='container py-5'>
        <div className='row'>
          <div className='col'>
            <PageTitle>FAQs</PageTitle>
            <SectionTitle>Is {site.name} free to use?</SectionTitle>
            <p>Yes, 100% free. You won't get to the end of entering all your hard work and having to pay to download. The website is completely free to use.</p>
            <SectionTitle>Why should I use a resume builder?</SectionTitle>
            <p>We know one of the top stresses of creating a resume is fiddling around with the formatting or getting a mind blank of what to include - especially if this is your first resume!</p>
            <p>Starting a resume from scratch can be daunting, but using {site.name} feels like you're answering a questionnaire! We've included as many tips that can help you create the best resume.</p>
            <SectionTitle>Do I need to include all the sections in my resume?</SectionTitle>
            <p>Of course not! When you start, we ask a few questions that will help us guide you better as to what sections are commonly included… but at the end of the day, not all sections will be relevant to you, so you can easily choose to include or exclude them.</p>
            <SectionTitle>Can I download my resume?</SectionTitle>
            <p>Yes, you can download your resume as a PDF at any time. If you are on a desktop, the live preview will show you what your resume looks like as you create it.</p>
            <SectionTitle>Can I change my template?</SectionTitle>
            <p>Absolutely! There is a default template at the beginning, but you can always change this throughout the process. You can also pick different templates for different resumes.</p>
            <SectionTitle>Support</SectionTitle>
            <p>As a completely free site, we can't offer telephone support. However, if you're having an issue, have feedback or future features you'd like to see, please email us at <a href={`mailto:${site.contactEmail}`}>{site.contactEmail}</a>.</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default FAQ
