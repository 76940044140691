import React, { useContext, useState, useRef, useEffect } from 'react'
import { Document, Page } from 'react-pdf'
import { Link } from 'react-router-dom'
import { ChevronLeft, ChevronRight, Download, FileEarmark, Tools } from 'react-bootstrap-icons'
import clsx from 'clsx'

import { BuilderContext } from '../_context'
import styles from './PreviewFrame.module.css'
import typographyStyles from '../../typography/Typography.module.css'
import { createEvent } from '../../events/_requests'
import { EventCategory, EventDescription } from '../../events'
import { ModalContext } from '../../modals/_context'
import jumpingDog from '../../assets/jumping-dog.svg'
import Button, { ButtonType } from '../../forms/buttons/Button'
import { Urls } from '../../routing/urls'
import { needsToGetJobReady } from '../../jobready/status'
import { AccountContext } from '../../account/_context'
import { Action } from '../_actions'


function PreviewFrame() {
  const [account] = useContext(AccountContext)
  const [state, dispatch, saving, setSaving, setBuilderDidLoadOnce, isPublic] = useContext(BuilderContext)
  const [, openModal, closeModal] = useContext(ModalContext)

  const [pdf, setPdf] = useState({})
  const [didLoadOnce, setDidLoadOnce] = useState(false)
  const [pageIndex, setPageIndex] = useState(0)
  const [pageHeight, setPageHeight] = useState(0)
  const [didResize, setDidResize] = useState(false)

  const { previewUrl, previewOpen, downloadUrl } = state

  const viewport = useRef(null)

  const loadPdf = pdf => {
    setSaving(false)
    setDidLoadOnce(true)
    setBuilderDidLoadOnce(true)
    setPdf(pdf)
  }

  const onChangeTemplate = _ => {
    dispatch(Action.ToggleTemplateSwitch())
  }

  const onDownload = _ => {
    createEvent(EventCategory.Builder, EventDescription.ExportedResume)
    if (needsToGetJobReady(account)) {
      openModal({
        body: _ => (
          <div>
            <div className={clsx(typographyStyles.color__tertiary, 'h4 text-center font-weight-bold')}>
              While your resume downloads...
            </div>
            <div className='text-center my-4'>
              <div>
                <img className={clsx(styles.dog, 'mb-4')} src={jumpingDog} alt='' />
              </div>
              <Link to={Urls.GetJobReady} onClick={closeModal}>
                <Button type={ButtonType.CtaSecondary}>Get job ready</Button>
              </Link>
            </div>
          </div>
        )
      })
    }
  }

  const copyCanvas = _ => {
    const duplicateContainer = document.querySelector('.copied-pdf canvas')
    const canvas = document.querySelector('.rendered-pdf canvas')
    const duplicate = canvas.cloneNode(true)
    duplicate.getContext('2d').drawImage(canvas, 0, 0)
    duplicateContainer.parentNode.replaceChild(duplicate, duplicateContainer)
  }

  const resizeViewport = _ => {
    // For some reason this needs to be in a timeout because on
    // first render the ref height is not correct. Could be because
    // the `viewport` element is a flex grow container.
    setTimeout(_ => {
      setDidResize(true)
      // TODO: Could we avoid the `setTimeout` with a measured ref?
      // See: https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
    }, 300)
  }

  const togglePreview = _ => {
    dispatch(Action.TogglePreview())
  }

  useEffect(_ => {
    window.addEventListener('resize', resizeViewport)
    return _ => {
      window.removeEventListener('resize', resizeViewport)
    }
  }, [])

  useEffect(resizeViewport, [viewport])

  useEffect(_ => {
    // This 10 value corresponds to the transformY amount under
    // `styles.document_container__toolbar`. Probably something
    // to do with the way flex grow is calculated.
    const A4 = { width: 210, height: 297 }
    const heightWithinViewportHeight = viewport.current.offsetHeight - 10
    const maxHeightWithinViewportWidth = ((window.innerWidth - 40) / A4.width) * A4.height
    setPageHeight(Math.min(heightWithinViewportHeight, maxHeightWithinViewportWidth))
    setDidResize(false)
  }, [didResize, previewOpen])

  return (
    <div className={clsx(styles.container, previewOpen && styles.container__open, 'PreviewFrame')}>

      <div className={saving ? styles.saving_active : styles.saving}>
        <div className='spinner-border spinner-border-sm text-light' role='status' />
        <span className={styles.saving__text}>{didLoadOnce ? 'Saving' : 'Loading'}</span>
      </div>

      <div className={!!pdf && pdf.numPages > 1 ? styles.pager_active : styles.pager}>
        <button
          className={clsx(styles.pager__button, styles.pager__button_left)}
          onClick={_ => setPageIndex(Math.max(0, pageIndex - 1))}>
          <ChevronLeft />
        </button>
        <span className={styles.pager__page_numbers}>Page {pageIndex + 1} / {pdf.numPages}</span>
        <button
          className={clsx(styles.pager__button, styles.pager__button_right)}
          onClick={_ => setPageIndex(Math.min(pageIndex + 1, pdf.numPages - 1))}>
          <ChevronRight />
        </button>
      </div>

      <div className={styles.document_container}>
        <div className={styles.document_container__viewport}>
          <div ref={viewport}>
            <div className={clsx(styles.document, styles.document__copy, 'copied-pdf')}>
              <canvas />
            </div>
            {!didResize && (
              <Document
                className={clsx(styles.document, 'rendered-pdf')}
                file={previewUrl}
                noData={''}
                loading={''}
                onLoadSuccess={loadPdf}>
                <Page height={pageHeight} pageIndex={pageIndex} onRenderSuccess={copyCanvas} />
              </Document>
            )}
          </div>
        </div>
        {!isPublic && (
          <div className={styles.document_container__toolbar}>
            {!previewOpen && (
              <div className={clsx(styles.clickCatcher, styles.previewToggle)} onClick={togglePreview}>
                <button className={clsx(styles.previewButton, 'btn btn-primary')}><FileEarmark /> Preview</button>
              </div>
            )}
            <div style={{ flex: 1 }}>
              <button className={clsx(styles.previewToggle, !previewOpen && styles.previewClosed, 'btn btn-primary')} onClick={togglePreview}>Back</button>
            </div>
            <button className={clsx(!previewOpen && styles.previewClosed, 'btn btn-primary mr-2')} onClick={onChangeTemplate}><Tools className='mt--1' /><span className='ml-2 d-none d-sm-inline-block'> Change template</span></button>
            <a className={clsx(!previewOpen && styles.previewClosed)} href={downloadUrl} onClick={onDownload} rel='noopener noreferrer'>
              <button className='btn btn-primary' disabled={!downloadUrl}><Download className='mr-1' /> Download PDF</button>
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

export default PreviewFrame
