import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

import styles from './InfoFrame.module.css'
import Button, { ButtonType } from '../../../../forms/buttons/Button'
import { Urls } from '../../../../routing/urls'
import TickList from '../../../../common/TickList'
import { createEvent } from '../../../../events/_requests'
import { EventCategory, EventDescription } from '../../../../events'


function InfoFrame() {

  const platform = window.location.hostname.replace('www.', '')

  useEffect(_ => {
    createEvent(EventCategory.JobReady, EventDescription.ViewedSuperInfo)
  }, [])

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-md-5'>
          <div className={clsx(styles.body, 'mb-4')}>
            <div className={clsx(styles.title, 'mb-3')}>Student Super can help you get your super organised.</div>
            <TickList items={[
              `Student Super discount most fees for members with balances under $1,000. See our <a href='https://www.studentsuper.com.au/our-fees/'>Fees & discounts page</a> or <a href='https://www.studentsuper.com.au/product-disclosure-statement/'>PDS</a> for details.`,
              `Find old and lost super accounts. Choose which funds to combine into your Student Super account.`,
              `Student Super will send you a pre-filled form with all the details that your employer needs.`,
              `When you graduate, Student Super changes name to Professional Super.`,
            ]} />
            <p>
              For more information, the PDS is available&nbsp;
              <a href='https://www.studentsuper.com.au/pds/' target='_blank' rel='noopener noreferrer'>here</a>.
            </p>
            <p>
              <strong>Join our growing 30,000+ community of members today!</strong>
            </p>
            <div className='text-center my-5'>
              <Link to={Urls.GetJobReady_Super_StudentSuper_Start}>
                <Button type={ButtonType.CtaSecondary}>Get started</Button>
              </Link>
            </div>
            <small className={styles.disclaimer}>
              The promoter of Student Super is responsible for the content about
              Student Super on the {platform} platform. The content about
              Student Super on the {platform} Platform is intended to be general
              in nature and is not personal financial product advice. It does
              not take into account your objectives, financial situation or
              needs. Before acting on any information, you should consider the
              appropriateness of the information provided and the nature of the
              relevant financial product having regard to your objectives,
              financial situation and needs. In particular, you should seek
              independent financial advice and read the relevant <a href='https://www.studentsuper.com.au/pds/' target='_blank' rel='noopener noreferrer'>Product
              Disclosure Statement</a> (PDS) or other offer document and <a
              href='https://www.studentsuper.com.au/tmd/' target='_blank'
              rel='noopener noreferrer'>Target Market Determination</a> prior to
              making an investment decision in relation to a financial product
              (including a decision about whether to acquire or continue to
              hold). {platform} is not responsible for the content provided
              about Student Super on its {platform} Platform. By allowing you to
              join Student Super through the {platform} platform, we are not
              recommending or endorsing Student Super . Any statements which
              {platform} makes about Student Super is not intended to imply a
              recommendation or opinion about Student Super by us.
            </small>
          </div>
          <Link to={Urls.MyResumes} className={styles.escape}>Back to My Resumes</Link>
        </div>
      </div>
    </div>
  )
}

export default InfoFrame
