export const getTopLevelSectionValues = (values, config, sectionName) => {
  if (!config.sections || !values.sections) {
    return { values: {}, config: {} }
  }

  const sectionIndex = config.sections.findIndex(x => x.name === sectionName)

  const sectionConfig = config.sections[sectionIndex]
  const section = values.sections[sectionIndex]

  if (!section || !sectionConfig) {
    return { values: {}, config: {} }
  }

  const result = section.sections
    ? { values: section, config: sectionConfig }
    : { values: section[sectionName], config: sectionConfig }

  return result
}
