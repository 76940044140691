import React from 'react'

import { default as EmploymentHistory_Intro } from './employmenthistory/Intro'
import { default as WorkExperience_Intro } from './workexperience/Intro'
import { default as References_Intro } from './references/Intro'
import { default as End_Finished } from './end/Finished'
import { default as GetStarted_Intro } from '../../getstarted/builder/templates/Intro'
import { default as GetStarted_CollectPassword } from '../../getstarted/builder/templates/CollectPassword'


const TemplateLocationMap = {
  'builder/templates/employmenthistory/Intro': EmploymentHistory_Intro,
  'builder/templates/workexperience/Intro': WorkExperience_Intro,
  'builder/templates/references/Intro': References_Intro,
  'builder/templates/end/Finished': End_Finished,
  'getstarted/builder/templates/Intro': GetStarted_Intro,
  'getstarted/builder/templates/CollectPassword': GetStarted_CollectPassword,
}

export const load = templatePath => {
  const TemplateComponent = TemplateLocationMap[templatePath]
  if (!TemplateComponent) {
    throw new Error(`Could not find a registered template for path '${templatePath}. Check that it has been added to the TemplateLocationMap.`)
  }
  return _ => <TemplateComponent />
}
