import React from 'react'
import clsx from 'clsx'

import styles from './Select.module.css'


function Select({ input, fieldInfo, fieldData, ...rest }) {
  const { options } = fieldData
  if (!options) {
    throw new Error(`'options' prop was not supplied.`)
  }

  return (
    <React.Fragment>
      <div className='dropdown'>
        <button
          id={`select-${input.name}`}
          className={clsx(styles.button, 'form-control dropdown-toggle')} type='button'
          data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
          {(fieldData.options.filter(x => x.value === input.value)[0] || {}).label || ''}
        </button>
        <div className={clsx(styles.options, 'dropdown-menu')} aria-labelledby={`select-${input.name}`}>
          {
            options.map((option, i) =>
              <div
                key={i} className={clsx(styles.option, 'dropdown-item')}
                onClick={_ => input.onChange(option.value)}>
                {option.label}
              </div>
            )
          }
        </div>
      </div>
    </React.Fragment>
  )
}

export default Select
