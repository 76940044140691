import { ActionName } from './_actions'


export function reducer(state, action) {
  switch (action.type) {

    case ActionName.SHOW_MODAL:
      return showModal(state, action)
    case ActionName.CLEAR_MODAL:
      return hideModal(state, action)

    default:
      throw new Error(action)
  }
}

const showModal = (state, { payload }) => {
  return { ...state, ...payload }
}

const hideModal = () => {
  return {}
}
