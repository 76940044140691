import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import clsx from 'clsx'

import { isInGetStarted } from '../routing/location'
import styles from './GetStartedSurvey.module.css'
import coffee from '../assets/icons/coffee-green.svg'
import globe from '../assets/icons/globe-green.svg'
import school from '../assets/icons/school-green.svg'
import search from '../assets/icons/search-green.svg'
import star1 from '../assets/icons/star-1-green.svg'
import star2 from '../assets/icons/star-2-green.svg'
import star4 from '../assets/icons/star-4-green.svg'
import uni from '../assets/icons/uni-green.svg'
import { Urls } from '../routing/urls'
import { getSurveyOptions } from './_requests'
import Button, { ButtonType } from '../forms/buttons/Button'
import { AccountContext } from '../account/_context'
import { Action } from '../account/_actions'


const IconMap = {
  coffee: coffee,
  globe: globe,
  highschool: school,
  search: search,
  'star-1': star1,
  'star-2': star2,
  'star-4': star4,
  uni: uni,
}

const IconStyleMap = {
  coffee: {},
  globe: {},
  highschool: { transform: 'scale(1.08)', transformOrigin: 'bottom' },
  search: {},
  'star-1': { transform: 'scale(.6)' },
  'star-2': { transform: 'translateY(10%)' },
  'star-4': {},
  uni: { transform: 'translateY(1px)' },
}

const OptionIconMap = {
  HIGHSCHOOL: 'highschool',
  UNI_TAFE: 'uni',
  NOT_STUDYING: 'globe',
  WORKING: 'coffee',
  NOT_WORKING: 'search',
  NONE: 'star-1',
  SOME: 'star-2',
  LOTS: 'star-4',
}

function GetStartedSurvey({ onComplete = _ => null, showGoButton = false, ...rest }) {

  const history = useHistory()
  const location = useLocation()
  const [questions, setQuestions] = useState([])
  const [answers, setAnswers] = useState([])
  const [allAnswersFilled, setAllAnswersFilled] = useState(false)

  const [, dispatch] = useContext(AccountContext)

  const select = (answer, i) => {
    const answersCopy = [...answers]
    answersCopy[i] = answer
    setAnswers(answersCopy)
  }

  const proceed = _ => {
    const answersNormalised = answers.reduce((prev, curr) => ({ ...prev, [curr.key]: curr.value }), {})
    const answersNormalisedStringified = JSON.stringify(answersNormalised)
    if (!isInGetStarted(location)) {
      history.push({
        pathname: Urls.GetStarted,
        search: queryString.stringify({ survey: answersNormalisedStringified }),
      })
    }
    dispatch(Action.SetAccountData(answersNormalised))
    onComplete(answersNormalisedStringified)
  }

  useEffect(_ => {
    const load = async _ => {
      const options = await getSurveyOptions()
      const { studyStatusOptions, employmentStatusOptions, workExperienceLevelOptions } = options
      const studyStatus = {
        key: 'studyStatus',
        text: 'What is your current study status?',
        options: studyStatusOptions.slice(1).map(tuple => ({
          icon: OptionIconMap[tuple[0]],
          value: tuple[0],
          text: tuple[1],
        }))
      }
      const employmentStatus = {
        key: 'employmentStatus',
        text: 'What is your current employment status?',
        options: employmentStatusOptions.slice(1).map(tuple => ({
          icon: OptionIconMap[tuple[0]],
          value: tuple[0],
          text: tuple[1],
        }))
      }
      const workExperienceLevel = {
        key: 'workExperienceLevel',
        text: 'What best describes your work experience?',
        options: workExperienceLevelOptions.slice(1).map(tuple => ({
          icon: OptionIconMap[tuple[0]],
          value: tuple[0],
          text: tuple[1],
        }))
      }
      setQuestions([workExperienceLevel, studyStatus, employmentStatus])
    }
    load()
  }, [])

  useEffect(_ => {
    const allAnswersFilled = answers.filter(Boolean).length === 3
    if (allAnswersFilled) {
      if (!showGoButton) {
        proceed()
      } else {
        setAllAnswersFilled(allAnswersFilled)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers])

  return (
    <div {...rest}>
      {
        questions.map(({ key, text, options }, i) => (
          <div key={i} className='my-4'>
            <p className='lead font-weight-bold'>{i + 1}. {text}</p>
            <div className='d-flex flex-wrap'>
              {
                options.map(({ icon, value, text }, j) => (
                  <div
                    key={j}
                    onClick={_ => select({ key, value }, i)}
                    className={clsx(
                      styles.option,
                      !!answers[i] && answers[i].value === value && styles.active,
                      'p-4 mr-4 d-flex flex-column justify-content-center align-items-center'
                    )}>
                    <img src={IconMap[icon]} style={IconStyleMap[icon]} alt='' />
                    <div className={clsx(styles.option__label, 'mt-3')}>{text}</div>
                  </div>
                ))
              }
            </div>
          </div>
        ))
      }
      {showGoButton && (
        <Button className='mt-3 mb-4' type={ButtonType.CtaSecondary} disabled={!allAnswersFilled} onClick={proceed}>Go!</Button>
      )}
    </div>
  )
}

export default GetStartedSurvey
