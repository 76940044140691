import { api } from '../core/_requests'


export const getResume = async (id) => {
  const result = await api.get(`builder/resume/${id}`)
  return result
}

export const postResume = async (state, templateHtmlCss) => {
  const result = await api.post(`builder/resume/`, {
    resume: state,
    preview: templateHtmlCss,
  })
  return result
}

export const putResume = async (resumeId, state, templateHtmlCss) => {
  const result = await api.put(`builder/resume/${resumeId}/`, {
    resume: state,
    preview: templateHtmlCss,
  })
  return result
}

export const generateTemplate = async (templateId, templateHtmlCss) => {
  const { html, css } = templateHtmlCss
  const result = await api.post(`builder/template/${templateId}/thumbnail/`, { html, css })
  return result
}
