import React from 'react'
import { Field } from 'react-final-form'

import FieldLayout from './FieldLayout'
import FieldError from '../../forms/messaging/FieldError'
import { ValidatorTypeMap, composeValidators } from '../../forms/validation'


function FieldLayoutMemo({
  fieldName, fieldInfo, fieldData, field,
  formValues, component: FieldComponent,
  ...rest
}) {
  const validators = (fieldData.validators || []).map(x => ValidatorTypeMap[x])
  return (
    <FieldLayout
      name={fieldName}
      label={field.label}
      formValues={formValues}
      fieldInfo={fieldInfo}
      fieldData={fieldData}
      {...rest}>
      <Field
        id={fieldName}
        name={fieldName}
        type={fieldInfo.type}
        fieldInfo={fieldInfo}
        fieldData={fieldData}
        initialValue={fieldData.initialValue}
        validate={composeValidators(validators)}
        {...field}>
        {
          ({ input, meta, ...rest }) => {
            return (
              <React.Fragment>
                <FieldComponent input={input} {...rest} />
                {meta.error && meta.touched && <FieldError className='mb-1'>{meta.error}</FieldError>}
              </React.Fragment>
            )
          }
        }
      </Field>
    </FieldLayout>
  )
}

const areEqual = (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps)
}

// For some reason `react-final-form` becomes very expensive render-wise for
// larger forms (as in our case). In addition to preventing re-renders of the
// whole form on every keystroke we tell React not to re-render each individual
// `FieldLayout` unless props have changed (see the above `areEqual` func).

// This does a straight `JSON.stringify` comparison for now. This does improve
// performance by a perceptible degree however can be further improved by more
// closely examining which value(s) have changed within `formValues` and whether
// they are applicable to the field in question.
export default React.memo(FieldLayoutMemo, areEqual)
