import React from 'react'
import { Link } from 'react-router-dom'
import { Pencil, Files, Trash, CardImage } from 'react-bootstrap-icons'
import moment from 'moment'
import clsx from 'clsx'

import typographyStyles from '../typography/Typography.module.css'
import styles from './TemplateCard.module.css'
import TemplateImageLoader from './TemplateImageLoader'
import Button, { ButtonType } from '../forms/buttons/Button'


export const TemplateCardSize = {
  Small: 'small',
  Large: 'large',
}

function TemplateCard({
  template,
  getEditLink, onClick, onCopy, onDelete,
  size = TemplateCardSize.Small,
  className, ...rest
}) {

  if (!!getEditLink && !!onClick) {
    throw new Error(`Supply only one of 'getEditLink' and 'onClick'. Both were supplied.`)
  }

  const { id, name, updated, thumbnailUrl } = template

  const sizeStyleMap = {
    [TemplateCardSize.Small]: styles.small,
    [TemplateCardSize.Large]: styles.large,
  }

  const capture = callback => e => {
    e.stopPropagation()
    callback(template)
  }

  return (
    <React.Fragment>
      {!!getEditLink && (
        <Link to={getEditLink(template)}>
          <div key={thumbnailUrl} className={clsx(styles.card, 'p-3 text-center', className)} {...rest}>
            <div className={clsx(styles.thumbnail, sizeStyleMap[size], 'm-3')}>
              {!thumbnailUrl && <CardImage />}
              {!!thumbnailUrl && <TemplateImageLoader className={styles.thumbnail__image} templateId={id} url={thumbnailUrl} />}
            </div>
            <div className='pb-1'>
              <strong className={styles.label}>{name}</strong>
              {!!updated && (
                <small className={styles.date}>Last updated {moment(updated).format('D MMMM YYYY, h:mmA')}</small>
              )}
            </div>
          </div>
        </Link>
      )}
      {!!onClick && (
        <div key={thumbnailUrl}
          className={clsx(styles.card, 'p-3 text-center', typographyStyles.pointer, className)}
          onClick={_ => onClick(template)}
          {...rest}>
          <div className={clsx(styles.thumbnail, sizeStyleMap[size], 'm-3')}>
            {!thumbnailUrl && <CardImage />}
            {!!thumbnailUrl && <TemplateImageLoader className={styles.thumbnail__image} templateId={id} url={thumbnailUrl} />}
            <div className={styles.shadow} />
            <div className={styles.use}><Button type={ButtonType.CtaPrimary}>Use this template</Button></div>
          </div>
          <div className='pb-1'>
            <strong className={styles.label}>{name}</strong>
          </div>
        </div>
      )}
      <div className='mt-3'>
        {(!!getEditLink || !!onCopy || !!onDelete) && (
          <div className='d-flex justify-content-between px-1'>
            <Link to={getEditLink(template)}>
              <button className={clsx(styles.button, styles.edit)}><Pencil />Edit</button>
            </Link>
            <button className={clsx(styles.button, styles.copy)} onClick={capture(onCopy)}><Files />Copy</button>
            <button className={clsx(styles.button, styles.delete)} onClick={capture(onDelete)}><Trash />Delete</button>
          </div>
        )}
        {(!!template && template.downloadUrl) && (
          <div className='d-flex justify-content-between p-1'>
            <a className={styles.download} href={template.downloadUrl} rel='noopener noreferrer'>
              <button className={styles.button}>Download</button>
            </a>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default TemplateCard
