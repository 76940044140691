import React, { useState, useEffect } from 'react'
import { default as ReactRTE } from 'react-rte'

import styles from './RichTextEditor.module.css'


// See the below link for further information about customisation:
// https://github.com/sstur/react-rte#toolbar-customization
const TOOLBAR_CONFIG = {
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
    { label: 'Strikethrough', style: 'STRIKETHROUGH' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
  ],
}

const getInputValue = input => {
  return input.value
    ? ReactRTE.createValueFromString(input.value, 'html')
    : ReactRTE.createEmptyValue()
}

function RichTextEditor({ input, fieldInfo, fieldData, ...rest }) {
  const [value, setValue] = useState(getInputValue(input))

  useEffect(_ => {
    if (input.value !== value.toString('html')) {
      setValue(getInputValue(input))
    }
    // Only run this effect when the `input` changes. We are
    // not interested in reacting to changes in `value`:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input])

  const onChange = value => {
    setValue(value)
    input.onChange(value.toString('html'))
  }

  return (
    <ReactRTE
      className={styles.editor}
      toolbarConfig={TOOLBAR_CONFIG}
      value={value}
      onChange={onChange}
      {...rest} />
  )
}

export default RichTextEditor
