const { FORM_ERROR } = require("final-form")


export const withFormErrorAsync = onSubmit => {
  return async values => {
    try {
      await onSubmit(values)
    } catch (error) {
      // Errors from the server come in the following shape:
      // { detail: <error_message> }
      // We massage them into the format `react-final-form` understands
      // in order to show form-level errors (as opposed to field-level).
      if (!!error.detail) {
        return { [FORM_ERROR]: error.detail }
      } else {
        return error
      }
    }
  }
}
