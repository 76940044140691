import React, { useEffect, useState } from 'react'

import { checkImageExists } from '../core/image'
import { generateTemplate } from '../builder/_requests'
import { getTemplateHtmlCss } from '../builder/preview/utils/update'
import { getLatestVersion } from '../builder/version'


function TemplateImageLoader({ templateId, url, ...rest }) {

  const [thumbnailUrl, setThumbnailUrl] = useState(null)
  const [pollForThumbnail, setPollForThumbnail] = useState(false)

  useEffect(_ => {
    const check = async _ => {
      try {
        await checkImageExists(url)
        setThumbnailUrl(url)
      } catch {
        const version = getLatestVersion()
        const thumbnailState = (await import(`../builder/state/thumbnail/${version}`)).default
        const templateHtmlCss = getTemplateHtmlCss({ ...thumbnailState, template: templateId })
        await generateTemplate(templateId, templateHtmlCss)
        setPollForThumbnail(true)
      }
    }
    check()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(_ => {
    if (pollForThumbnail) {
      let timer = null
      const poll = async () => {
        await checkImageExists(url).then(_ => {
          setThumbnailUrl(url)
        }).catch(_ => {
          timer = setTimeout(poll, 500)
        })
      }
      poll()
      // Cancel any pending `setTimeout` callback on unmount.
      return _ => clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollForThumbnail])

  return (
    <div {...rest} style={!!thumbnailUrl ? { backgroundImage: `url(${thumbnailUrl})` } : {}} />
  )
}

export default TemplateImageLoader
