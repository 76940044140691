import React from 'react'


function Finished() {
  return (
    <React.Fragment>
      <p className='text-center font-weight-bold text-black-50'>
        Yay! You're almost done.
      </p>
      <p className='text-center text-black-50'>
        A handy checklist before you download your resume:
        <ol className='text-left mt-3' style={{ width: '80%', maxWidth: '350px', margin: '0 auto', fontSize: '.95em' }}>
          <li>Check your spelling and grammar</li>
          <li>Make sure the contact information is correct</li>
          <li>Check that the resume flows, and it's clear and easy for people to read</li>
        </ol>
      </p>
    </React.Fragment>
  )
}

export default Finished
