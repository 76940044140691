class Provider {
  constructor(formValues, formConfig) {
    this.values = formValues
    this.config = formConfig
  }

  getProps = _ => ({})
}

export default Provider
