import React, { useContext } from 'react'

import Section from '../section/Section'
import { FieldComponentMap } from './maps'
import FieldLayoutMemo from './FieldLayoutMemo'
import { BuilderContext } from '../_context'


function FieldGroup({
  // Props provided by final-form
  values: formValues,
  // Custom props
  _key = '',
  sections,
  // Remaining props
  ...rest
}) {
  // eslint-disable-next-line
  const [, , , , , , getValue] = useContext(BuilderContext)
  return sections.map((section, sectionIndex) => {
    const parentKey = `${_key}.sections`
    const sectionKey = `${parentKey}[${sectionIndex}]`
    return (
      <Section
        key={sectionIndex}
        parentKey={parentKey} sectionKey={sectionKey} index={sectionIndex}
        formValues={formValues} section={section}>
        {
          !!section.fields && section.fields.map(({ name, component, layout, renderCondition, fieldData = {}, ...field }, fieldIndex) => {
            const fieldName = `${sectionKey}.${section.name}.${name}`
            const FieldComponent = FieldComponentMap[component]

            if (!FieldComponent) {
              throw new Error(`Could not find a field component for type '${component}.'`)
            }

            // Handle conditional rendering of a field.
            if (!!renderCondition) {
              try {
                // eslint-disable-next-line
                const shouldRender = eval(renderCondition)
                if (!shouldRender) { return null }
              } catch (e) {
                throw new Error(`Render condition error: ${e}`)
              }
            }

            // Metadata about our custom fields. Will be made available
            // to the component responsible for rendering the field.
            const fieldInfo = {
              name: name,
              label: field.label,
              type: component,
              sectionIndex: sectionIndex,
              fieldIndex: fieldIndex,
              location: sectionKey,
              parent: _key,
            }

            return (
              <FieldLayoutMemo
                key={fieldIndex}
                component={FieldComponent}
                formValues={formValues}
                fieldName={fieldName}
                fieldInfo={fieldInfo}
                fieldData={fieldData}
                field={field} />
            )
          })
        }
        {
          !!section.sections && (
            <FieldGroup
              values={formValues}
              _key={sectionKey}
              sections={section.sections}
              {...rest} />
          )
        }
      </Section>
    )
  })
}

export default FieldGroup
