import React from 'react'


function StackedFieldRow({ children }) {
  return (
    <div className='form-row my--3'>
      {children}
    </div>
  )
}

export default StackedFieldRow