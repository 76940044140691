import axios from 'axios'
import Cookies from 'js-cookie'

import { getGlobals } from '../Globals'


export const api = axios.create({
  baseURL: getGlobals().site.apiUrl,
})
api.interceptors.request.use(
  config => {
    config.headers['X-CSRFToken'] = Cookies.get('csrftoken')
    return config
  },
  error => Promise.reject(error)
)
api.interceptors.response.use(
  response => response.data,
  error => {
    // If the client tries to log out while not logged in, ignore this error.
    if (error.response && error.response.config.url === 'account/logout/' && error.response.status === 403) {
      return Promise.resolve()
    }

    // Send this error to the window so that it can be caught and processed
    // by the <ApiErrorHandler /> component (see App.js).
    window.onerror({ source: 'api', error })

    // Send the response error data (if available) through to the caller.
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data)
    } else {
      return Promise.reject(error)
    }
  }
)
