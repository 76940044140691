import Provider from "./base"
import { getTopLevelSectionValues } from './util/section'


class Provider_v_1_0_0 extends Provider {

  version = 'v1.0.0'

  getContactDetails = (values, config) => {
    return getTopLevelSectionValues(values, config, 'contact')
  }

  getProfessionalSummary = (values, config) => {
    return getTopLevelSectionValues(values, config, 'professionalSummary')
  }

  getSecondaryEducation = (values, config) => {
    return getTopLevelSectionValues(values, config, 'secondary')
  }

  getTertiaryEducation = (values, config) => {
    return getTopLevelSectionValues(values, config, 'tertiary')
  }

  getEmploymentHistory = (values, config) => {
    return getTopLevelSectionValues(values, config, 'employment')
  }

  getWorkExperience = (values, config) => {
    return getTopLevelSectionValues(values, config, 'workExperience')
  }

  getInternships = (values, config) => {
    return getTopLevelSectionValues(values, config, 'internships')
  }

  getSkills = (values, config) => {
    return getTopLevelSectionValues(values, config, 'skills')
  }

  getQualifications = (values, config) => {
    return getTopLevelSectionValues(values, config, 'qualifications')
  }

  getLanguages = (values, config) => {
    return getTopLevelSectionValues(values, config, 'languages')
  }

  getInterests = (values, config) => {
    return getTopLevelSectionValues(values, config, 'interests')
  }

  getExtracurricular = (values, config) => {
    return getTopLevelSectionValues(values, config, 'extracurricular')
  }

  getLinks = (values, config) => {
    return getTopLevelSectionValues(values, config, 'links')
  }

  getReferences = (values, config) => {
    return getTopLevelSectionValues(values, config, 'references')
  }

  getProps = _ => {
    return {
      contactDetails: this.getContactDetails(this.values, this.config),
      professionalSummary: this.getProfessionalSummary(this.values, this.config),
      secondaryEducation: this.getSecondaryEducation(this.values, this.config),
      tertiaryEducation: this.getTertiaryEducation(this.values, this.config),
      employmentHistory: this.getEmploymentHistory(this.values, this.config),
      workExperience: this.getWorkExperience(this.values, this.config),
      internships: this.getInternships(this.values, this.config),
      skills: this.getSkills(this.values, this.config),
      qualifications: this.getQualifications(this.values, this.config),
      languages: this.getLanguages(this.values, this.config),
      interests: this.getInterests(this.values, this.config),
      extracurricular: this.getExtracurricular(this.values, this.config),
      links: this.getLinks(this.values, this.config),
      references: this.getReferences(this.values, this.config),
    }
  }

}

export default Provider_v_1_0_0
