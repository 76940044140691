import Provider_v_0_1_0 from './providers/v0.1.0'
import Provider_v_1_0_0 from './providers/v1.0.0'

import TemplateApollo_v_1_0_0 from './templates/Template.Apollo.v1.0.0'
import TemplateApollo_v_1_0_0_Css from '!!raw-loader!./templates/Template.Apollo.v1.0.0.css'
import TemplateAres_v_1_0_0 from './templates/Template.Ares.v1.0.0'
import TemplateAres_v_1_0_0_Css from '!!raw-loader!./templates/Template.Ares.v1.0.0.css'
import TemplateZeus_v_0_1_0 from './templates/Template.Zeus.v0.1.0'
import TemplateZeus_v_0_1_0_Css from '!!raw-loader!./templates/Template.Zeus.v0.1.0.css'
import TemplateZeus_v_1_0_0 from './templates/Template.Zeus.v1.0.0'
import TemplateZeus_v_1_0_0_Css from '!!raw-loader!./templates/Template.Zeus.v1.0.0.css'


// ABOUT PROVIDERS:
// ---------------

// Providers extract data for rendering given a form's values and config objects.
// When a form config changes the builder version should be bumped and a new provider
// created to select data for consumption by any compatible templates.

// See:
// ../_state          -- the Builder context
// ./providers/base   -- the base provider

export const VersionProviderMap = {
  'v0.1.0': Provider_v_0_1_0,
  'v1.0.0': Provider_v_1_0_0,
}


// ABOUT TEMPLATES:
// ---------------

// Templates are React components that are populated with data from providers, then
// rendered to a HTML string. The accompanying styles for each template are loaded
// with webpack's `raw-loader`. The HTML and CSS strings are combined to produce the
// preview.

// To enable the loading of CSS files as strings into this module the corresponding
// eslint rule must be disabled:
/* eslint import/no-webpack-loader-syntax: off */

export const TemplateName = {
  Apollo_v_1_0_0: 'Apollo@v1.0.0',
  Ares_v_1_0_0: 'Ares@v1.0.0',
  Zeus_v_0_1_0: 'Zeus@v0.1.0',
  Zeus_v_1_0_0: 'Zeus@v1.0.0',
}

export const TemplateDisplayNameMap = {
  [TemplateName.Apollo_v_1_0_0]: 'Apollo',
  [TemplateName.Ares_v_1_0_0]: 'Ares',
  [TemplateName.Zeus_v_0_1_0]: 'Zeus',
  [TemplateName.Zeus_v_1_0_0]: 'Zeus',
}

export const TemplateNameResourceMap = {
  [TemplateName.Apollo_v_1_0_0]: [TemplateApollo_v_1_0_0, TemplateApollo_v_1_0_0_Css],
  [TemplateName.Ares_v_1_0_0]: [TemplateAres_v_1_0_0, TemplateAres_v_1_0_0_Css],
  [TemplateName.Zeus_v_0_1_0]: [TemplateZeus_v_0_1_0, TemplateZeus_v_0_1_0_Css],
  [TemplateName.Zeus_v_1_0_0]: [TemplateZeus_v_1_0_0, TemplateZeus_v_1_0_0_Css],
}


// ABOUT VERSIONS:
// --------------

// Each iteration of a form config should have a version tied to it. This is how we
// recognise which Provider to use to extract the relevant data from a set of form
// values to send to any Templates available for that version.

export const VersionTemplateNameDefaultMap = {
  'v0.1.0': TemplateName.Zeus_v_0_1_0,
  'v1.0.0': TemplateName.Zeus_v_1_0_0,
}

export const VersionTemplateMap = {
  'v0.1.0': [TemplateName.Zeus_v_0_1_0],
  'v1.0.0': [TemplateName.Apollo_v_1_0_0, TemplateName.Ares_v_1_0_0, TemplateName.Zeus_v_1_0_0],
}
