import React, { useContext, useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import clsx from 'clsx'

import NavLink, { NavLinkType } from './NavLink'
import { Urls } from './urls'
import styles from './Navigation.module.css'
import Button, { ButtonType } from '../forms/buttons/Button'
import { AccountContext } from '../account/_context'
import { isInGetStarted, isInGetJobReady } from './location'
import { isAccountReady } from '../account/status'
import { finishedJobReady } from '../jobready/status'


function Navigation({ onClick = _ => null }) {
  const [account, , name, loggedIn, logoutAndReset] = useContext(AccountContext)
  const location = useLocation()
  const [showGetJobReady, setShowGetJobReady] = useState(false)

  useEffect(_ => {
    setShowGetJobReady(isAccountReady(account) && !finishedJobReady(account))
  }, [account])

  const logout = _ => {
    logoutAndReset()
    onClick()
  }

  return (
    <ul className={clsx(styles.nav, 'nav justify-content-end')}>
      {!loggedIn && (
        <React.Fragment>
          <li className='nav-item'>
            <NavLink className='nav-link' type={NavLinkType.Text} to={Urls.Login} onClick={onClick}>Login</NavLink>
          </li>
          {!isInGetStarted(location) && (<li className='nav-item'>
            <NavLink className='nav-link' type={NavLinkType.Button} to={Urls.GetStarted} onClick={onClick}>
              <Button type={ButtonType.CtaSecondaryInverse}>Get started</Button>
            </NavLink>
          </li>)}
        </React.Fragment>
      )}
      {loggedIn && (
        <React.Fragment>
          {!isInGetJobReady(location) && showGetJobReady && (<li className='nav-item'>
            <NavLink className='nav-link' type={NavLinkType.Text} to={Urls.GetJobReady} onClick={onClick}>Get job ready</NavLink>
          </li>)}
          <li className='nav-item'>
            <NavLink className='nav-link' type={NavLinkType.Text} to={Urls.MyResumes} onClick={onClick}>My resumes</NavLink>
          </li>
          <li className={clsx(styles.profile, 'nav-item')}>
            <div className='dropdown'>
              <button
                id='userAccountDropdown'
                className='btn dropdown-toggle' type='button'
                data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                {name}
              </button>
              <div className={clsx(styles.dropdown, 'dropdown-menu')} aria-labelledby='userAccountDropdown'>
                <NavLink className='dropdown-item' type={NavLinkType.DropdownText} to={Urls.MyAccount} onClick={onClick}>My account</NavLink>
                <NavLink className='dropdown-item' type={NavLinkType.DropdownText} to={Urls.MyResumes} onClick={onClick}>My resumes</NavLink>
                <div className={clsx(styles.divider, 'dropdown-divider')} />
                <Link className='dropdown-item' to={'#'} onClick={logout}>Log out</Link>
              </div>
            </div>
          </li>
        </React.Fragment>
      )}
    </ul>
  )
}

export default Navigation
