import React, { useState, useEffect } from 'react'
import moment from 'moment'
import clsx from 'clsx'


const NUM_YEARS_BACK = 20

export const Orientation = {
  Future: 'future',
  Past: 'past',
}

export const InputMode = {
  MonthYear: 'mmyy',
  YearOnly: 'yyyy',
}

export const CurrentYearMode = {
  Show: 'show',
  Hide: 'hide',
}

function YearDropdown({ input, fieldInfo, fieldData, ...rest }) {

  const { orientation = Orientation.Past, inputMode = InputMode.MonthYear, currentYearMode = CurrentYearMode.Hide } = fieldData
  const [value, setValue] = useState({ month: '', year: '' })

  const thisYear = moment().year()
  const selectedYear = input.value ? parseInt(input.value) : null

  let numYearsBack = 0
  if (orientation === Orientation.Past) {
    numYearsBack = selectedYear && selectedYear <= thisYear - NUM_YEARS_BACK
      ? thisYear - selectedYear + 1
      : NUM_YEARS_BACK
  } else if (orientation === Orientation.Future) {
    numYearsBack = selectedYear && selectedYear >= thisYear + NUM_YEARS_BACK
      ? selectedYear - thisYear + 1
      : NUM_YEARS_BACK
  }

  let availableYears = []
  if (orientation === Orientation.Past) {
    availableYears = [...Array(numYearsBack).keys()].map(x => thisYear - x)
  } else if (orientation === Orientation.Future) {
    availableYears = [...Array(numYearsBack).keys()].map(x => thisYear + x)
  }
  if (currentYearMode === CurrentYearMode.Show) {
    availableYears = ['Current'].concat(availableYears)
  }

  const months = [...Array(13).keys()]
  const monthLabels = ['No month', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  useEffect(_ => {
    try {
      const { month = '', year = '' } = JSON.parse(input.value)
      setValue({ month, year })
    } catch {
      setValue({ month: '', year: '' })
    }
    // Only run this effect when the `input` changes. We are
    // not interested in reacting to changes in `value`:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input])

  const onChange = key => e => {
    const updatedValue = { ...value, [key]: e.target.value }
    if (key === 'year' && e.target.value === 'Current') { updatedValue.month = '0' }
    if (key === 'month' && e.target.value === 'Current') { updatedValue.month = '0'; updatedValue.year = 'Current' }
    if (key === 'month' && (e.target.value !== 'Current' && e.target.value !== '0') && updatedValue.year === 'Current') { updatedValue.year = '' }
    setValue(updatedValue)
    input.onChange(JSON.stringify(updatedValue))
  }

  return (
    <div className='d-flex align-items-center'>
      {inputMode === InputMode.MonthYear && (
        <div className='pr-1'>
          <select
            className={clsx('form-control', value.month === '' && 'text-black-30')}
            name={`${input.name}-month`}
            value={value.month}
            onChange={onChange('month')}
            {...rest}>
            <option disabled label='Month' value='' className='text-muted' />
            {currentYearMode === CurrentYearMode.Show && (
              <option key='current' label={'Current'} value={'Current'} />
            )}
            {
              months.map((month, i) => <option key={i} label={monthLabels[i]} value={month} />)
            }
          </select>
        </div>
      )}
      <div>
        <select
          className={clsx('form-control', value.year === '' && 'text-black-30')}
          style={{ width: inputMode === InputMode.MonthYear ? '105px' : '150px' }}
          name={`${input.name}-year`}
          value={value.year}
          onChange={onChange('year')}
          {...rest}>
          <option disabled label='Year' value='' />
          {
            availableYears.map((year, i) => <option key={i} label={year} value={year} />)
          }
        </select>
      </div>
    </div>
  )
}

export default YearDropdown
