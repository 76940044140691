import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { Form, Field } from 'react-final-form'
import Button, { ButtonType } from '../forms/buttons/Button'
import { Urls } from '../routing/urls'
import { createEvent } from '../events/_requests'
import { EventCategory, EventDescription } from '../events'
import { updateAccount } from '../account/_requests'
import { AccountContext } from '../account/_context'
import { FORM_ERROR } from 'final-form'
import { withFormErrorAsync } from '../forms/errors'
import FormError from '../forms/messaging/FormError'


function Tfn() {

  const history = useHistory()
  const [account] = useContext(AccountContext)

  const onSubmit = async ({ tfn }) => {
    if (!tfn) {
      return Promise.reject({ [FORM_ERROR]: 'Please choose an option above.' })
    }
    await updateAccount({
      ...account,
      ...(JSON.parse(tfn)),
    })
    history.push(Urls.GetJobReady_Super)
  }

  useEffect(_ => {
    createEvent(EventCategory.JobReady, EventDescription.ViewedTFNQuestion)
  }, [])

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-md-6'>
          <p>
            A Tax File Number (TFN) is an important part of your tax and super records.
            If you don't tell your employer your TFN, you'll pay more tax.
          </p>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6'>
          <Form
            onSubmit={withFormErrorAsync(onSubmit)}
            render={({ handleSubmit, submitting, submitError }) => (
              <form className='my-3' onSubmit={handleSubmit}>
                <div className='mb-2'><strong>Do you have a Tax File Number?</strong></div>
                {
                  [
                    {
                      label: `Yes`,
                      value: { hasTfn: true, wantsTfnInfo: false },
                    },
                    {
                      label: `No, but I've already applied for one`,
                      value: { hasTfn: false, wantsTfnInfo: false },
                    },
                    {
                      label: `No, send me info on how to get one`,
                      value: { hasTfn: false, wantsTfnInfo: true },
                    },
                  ].map((option, i) => {
                    const id = `tfn-${i + 1}`
                    return (
                      <div key={i} className='form-check'>
                        <Field
                          value={JSON.stringify(option.value)}
                          type='radio'
                          name='tfn'>
                          {({ input }) => (
                            <input
                              className='form-check-input'
                              id={id} {...input} />
                          )}
                        </Field>
                        <label className='form-check-label' htmlFor={id}>{option.label}</label>
                      </div>
                    )
                  })
                }
                {submitError && <FormError className="error mt-3">{submitError}</FormError>}
                <div className='mt-4'>
                  <Button submit
                    className='mt-2'
                    type={ButtonType.CtaSecondary}
                    disabled={submitting}>
                    Next
                  </Button>
                </div>
              </form>
            )}>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default Tfn
