import React, { useContext } from 'react'
import { Form } from 'react-final-form'
import { Link, useHistory, useLocation } from 'react-router-dom'

import typographyStyles from '../typography/Typography.module.css'
import { Urls } from '../routing/urls'
import Button, { ButtonType } from '../forms/buttons/Button'
import styles from './Login.module.css'
import Input from '../forms/fields/Input'
import StackedField from '../forms/layouts/StackedField'
import { required } from '../forms/validation'
import { login } from './_requests'
import { AccountContext } from './_context'
import { Action } from './_actions'
import { withAuthenticatedRedirect } from './withAuthenticatedRedirect'
import { withFormErrorAsync } from '../forms/errors'
import FormError from '../forms/messaging/FormError'


function Login() {
  const [, dispatch] = useContext(AccountContext)
  const history = useHistory()
  const location = useLocation()

  const loginEmailPassword = async ({ email, password }) => {
    const account = await login(email, password)
    dispatch(Action.SetAccountData(account))

    const redirecting = !!location.state && !!location.state.from
    if (redirecting) {
      history.replace(location.state.from)
    } else {
      history.push(Urls.MyResumes)
    }
  }

  return (
    <div className='container py-5'>
      <div className='row'>
        <div className='col-md-5'>
          <h1 className={typographyStyles.title}>Login</h1>
          <p>
            Don’t have an account yet?&nbsp;
            <Link to={Urls.GetStarted}>Join for free</Link>
          </p>
          <div className='my-4'>
            <Form
              onSubmit={withFormErrorAsync(loginEmailPassword)}
              render={({ handleSubmit, submitting, submitError }) => (
                <form onSubmit={handleSubmit}>
                  <StackedField name='email' label='Email' validate={required} component={Input} autoFocus />
                  <StackedField name='password' label='Password' validate={required} component={Input} type='password' />
                  {submitError && <FormError className="error">{submitError}</FormError>}
                  <div className='mt-4'>
                    <Button submit
                      className={styles.login}
                      type={ButtonType.CtaSecondary}
                      disabled={submitting}>
                      Login
                    </Button>
                    <Link className={styles.forgot} to={Urls.ResetPassword}>Forgot your password?</Link>
                  </div>
                </form>
              )}>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withAuthenticatedRedirect()(Login)
