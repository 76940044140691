import React from 'react'


function TemplateZeus({
  contactDetails, secondaryEducation, tertiaryEducation, employmentHistory,
  qualifications, languages, extracurricular, links, references,
}) {
  return (
    <div className='TemplateBasic'>
      <div className='header'>
        <h1>{contactDetails.values.firstName} {contactDetails.values.familyName}</h1>
        <div>{contactDetails.values.address}</div>
        <div>{contactDetails.values.email}</div>
        <div>{contactDetails.values.mobile}</div>
      </div>
      <div className='body'>
        {(
          (Object.keys(secondaryEducation.values).length > 0 && !secondaryEducation.config.hidden) ||
          (Object.keys(tertiaryEducation.values).length > 0 && !tertiaryEducation.config.hidden)
        ) && (
            <div className='section'>
              <h2>Education</h2>
              <div>
                {Object.keys(secondaryEducation.values).length > 0 && !secondaryEducation.config.hidden && (
                  <table>
                    <tr>
                      <td width="20%">
                        <div>
                          <span>{secondaryEducation.values.yearStarted}</span>
                          <span>{secondaryEducation.values.yearStarted && secondaryEducation.values.yearFinished && (' – ')}</span>
                          <span>{secondaryEducation.values.yearFinished}</span>
                        </div>
                      </td>
                      <td>
                        <div><h3>{secondaryEducation.values.schoolName || 'Secondary education'}</h3></div>
                        {secondaryEducation.values.atar && (
                          <ul>
                            <li>ATAR {secondaryEducation.values.atar}</li>
                          </ul>
                        )}
                        <div dangerouslySetInnerHTML={{ __html: secondaryEducation.values.extracurricular }} />
                      </td>
                    </tr>
                  </table>
                )}
                {Object.keys(tertiaryEducation.values).length > 0 && (
                  <table>
                    <tr>
                      <td width="20%">
                        <div>
                          <span>{tertiaryEducation.values.yearStarted}</span>
                          <span>{tertiaryEducation.values.yearStarted && tertiaryEducation.values.yearFinished && (' – ')}</span>
                          <span>{tertiaryEducation.values.yearFinished}</span>
                        </div>
                      </td>
                      <td>
                        <div><h3>{tertiaryEducation.values.schoolName || 'Tertiary education'}</h3></div>
                        <div>
                          {tertiaryEducation.values.qualification}
                          {tertiaryEducation.values.gpa && ` (${tertiaryEducation.values.gpa})`}
                        </div>
                      </td>
                    </tr>
                  </table>
                )}
              </div>
            </div>
          )}
        {(
          (Object.keys(employmentHistory.values).length > 0 && (employmentHistory.values.sections || []).length > 0) &&
          (!employmentHistory.config.hidden && (employmentHistory.config.sections || []).filter(x => x.name !== 'addEmployer' && !x.hidden).length > 0)
        ) && (
            <div className='section'>
              <h2>Employment</h2>
              {employmentHistory.values.sections.map(({ employer }, i) => (
                !employmentHistory.config.sections[i].hidden && (
                  <table key={i}>
                    <tr>
                      <td>
                        <div>
                          <h3>
                            {employer.jobTitle}
                            {employer.jobTitle && employer.company && ', '}
                            {employer.company}
                          </h3>
                        </div>
                        <div>
                          <span>{employer.startDate}</span>
                          <span>{employer.startDate && employer.endDate && (' – ')}</span>
                          <span>{employer.endDate && `${employer.endDate[0].toUpperCase()}${employer.endDate.slice(1)}`}</span>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: employer.responsibilities }} />
                        <div dangerouslySetInnerHTML={{ __html: employer.skills }} />
                        {employer.additionalFields.length > 0 && (
                          <table>
                            {employer.additionalFields.map((field, j) => (
                              <tr key={j}>
                                <td width="20%"><i><b>{field.label}</b></i></td>
                                <td>{field.value}</td>
                              </tr>
                            ))}
                          </table>
                        )}
                      </td>
                    </tr>
                  </table>
                )
              ))}
            </div>
          )}
        {(
          (Object.keys(qualifications.values).length > 0 && !!qualifications.values.longform) &&
          (!qualifications.config.hidden)
        ) && (
            <div className='section'>
              <h2>Qualifications</h2>
              <div dangerouslySetInnerHTML={{ __html: qualifications.values.longform }} />
            </div>
          )}
        {(
          (Object.keys(languages.values).length > 0 && !!languages.values.longform) &&
          (!languages.config.hidden)
        ) && (
            <div className='section'>
              <h2>Languages</h2>
              <div dangerouslySetInnerHTML={{ __html: languages.values.longform }} />
            </div>
          )}
        {(
          (Object.keys(extracurricular.values).length > 0 && !!extracurricular.values.longform) &&
          (!extracurricular.config.hidden)
        ) && (
            <div className='section'>
              <h2>Extracurricular</h2>
              <div dangerouslySetInnerHTML={{ __html: extracurricular.values.longform }} />
            </div>
          )}
        {(
          (Object.keys(links.values).length > 0 && !!links.values.longform) &&
          (!links.config.hidden)
        ) && (
            <div className='section'>
              <h2>Links</h2>
              <div dangerouslySetInnerHTML={{ __html: links.values.longform }} />
            </div>
          )}
        {(
          (Object.keys(references.values).length > 0 && !!references.values.longform) &&
          (!references.config.hidden)) && (
            <div className='section'>
              <h2>References</h2>
              <div dangerouslySetInnerHTML={{ __html: references.values.longform }} />
            </div>
          )}
      </div>
    </div>
  )
}

export default TemplateZeus
