import Provider from "./base"
import { getTopLevelSectionValues } from './util/section'


class Provider_v_0_1_0 extends Provider {

  version = 'v0.1.0'

  getContactDetails = (values, config) => {
    return getTopLevelSectionValues(values, config, 'contact')
  }

  getSecondaryEducation = (values, config) => {
    return getTopLevelSectionValues(values, config, 'secondary')
  }

  getTertiaryEducation = (values, config) => {
    return getTopLevelSectionValues(values, config, 'tertiary')
  }

  getEmploymentHistory = (values, config) => {
    const employment = getTopLevelSectionValues(values, config, 'employment') || {}
    const result = {
      values: {
        sections: ((employment.values || {}).sections || []).map((section, i) => {
          const { employer } = (section || {})
          return {
            employer: {
              ...employer,
              additionalFields: Object.keys(employment.config.sections[i]).length > 0
                ? employment.config.sections[i].fields
                  .filter(x => x.name.startsWith('addField#'))
                  .map(x => ({ label: x.label, value: employer[x.name] }))
                : []
            }
          }
        })
      },
      config: employment.config
    }
    return result
  }

  getQualifications = (values, config) => {
    return getTopLevelSectionValues(values, config, 'qualifications')
  }

  getLanguages = (values, config) => {
    return getTopLevelSectionValues(values, config, 'languages')
  }

  getExtracurricular = (values, config) => {
    return getTopLevelSectionValues(values, config, 'extracurricular')
  }

  getLinks = (values, config) => {
    return getTopLevelSectionValues(values, config, 'links')
  }

  getReferences = (values, config) => {
    return getTopLevelSectionValues(values, config, 'references')
  }

  getProps = _ => {
    return {
      contactDetails: this.getContactDetails(this.values, this.config),
      secondaryEducation: this.getSecondaryEducation(this.values, this.config),
      tertiaryEducation: this.getTertiaryEducation(this.values, this.config),
      employmentHistory: this.getEmploymentHistory(this.values, this.config),
      qualifications: this.getQualifications(this.values, this.config),
      languages: this.getLanguages(this.values, this.config),
      extracurricular: this.getExtracurricular(this.values, this.config),
      links: this.getLinks(this.values, this.config),
      references: this.getReferences(this.values, this.config),
    }
  }

}

export default Provider_v_0_1_0
