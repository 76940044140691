import React from 'react'

import FormWatcher from './FormWatcher'
import FieldGroup from './FieldGroup'
import Title from './Title'
import TemplateSwitch from './TemplateSwitch'


function FormContainer({ showTitle, handleSubmit, ...rest }) {
  return (
    <React.Fragment>
      <FormWatcher />
      <form
        id='builderform'
        onSubmit={handleSubmit}
        {...(rest.hasValidationErrors ? { 'data-valid': false } : { 'data-valid': true })}>
        {showTitle && <Title />}
        <TemplateSwitch />
        <FieldGroup {...rest} />
      </form>
    </React.Fragment>
  )
}

export default FormContainer
