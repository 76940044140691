import React, { useReducer, useEffect, useState } from 'react'
import clsx from 'clsx'

import { ModalContext } from './_context'
import { reducer } from './_reducers'
import { getGlobals } from '../Globals'
import { Action } from './_actions'


function Provider({ children }) {

  const [state, dispatch] = useReducer(reducer, {})
  const { title: TitleComponent, body: BodyComponent, dismissable, onClose } = state
  const [queue, setQueue] = useState([])

  const $ = getGlobals().$
  const modalId = 'modal'

  const openModal = modal => {
    const { title, body, dismissable = true, onClose = _ => null } = modal
    if (Object.keys(state).length === 0) {
      dispatch(Action.ShowModal({ title, body, dismissable, onClose }))
    } else {
      setQueue([...queue, modal])
    }
  }

  const closeModal = _ => {
    $(`#${modalId}`).modal('hide')
  }

  useEffect(_ => {
    if (Object.keys(state).length === 0 && queue.length > 0) {
      const [next, ...rest] = queue
      openModal(next)
      setQueue(rest)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  useEffect(_ => {
    // Hook into Bootstrap's modal implementation.
    if (Object.keys(state).length > 0) {
      $(`#${modalId}`).modal({
        show: true,
        keyboard: dismissable,
        backdrop: dismissable ? true : 'static',
      })
      $(`#${modalId}`).on('hide.bs.modal', onClose)
      $(`#${modalId}`).on('hidden.bs.modal', _ => {
        // Clear modal contents only after modal has been
        // fully hidden (runs after CSS transitions complete).
        dispatch(Action.ClearModal())
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, $])

  useEffect(_ => {
    // Clean up any remaining DOM nodes on unmount.
    return _ => {
      $(`#${modalId}`).modal('dispose')
    }
  }, [$])

  return (
    <React.Fragment>
      <ModalContext.Provider value={[state, openModal, closeModal]}>
        {children}
      </ModalContext.Provider>
      <div id={modalId} className='modal fade' tabIndex='-1' role='dialog'>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className={clsx('modal-header', !TitleComponent && 'no-title')}>
              <h5 className='modal-title'>
                {!!TitleComponent && (
                  <strong><TitleComponent /></strong>
                )}
              </h5>
              {dismissable && (
                <button
                  type='button' className='close' data-dismiss='modal' aria-label='Close'
                  onClick={closeModal}>
                  <span aria-hidden='true'>&times;</span>
                </button>
              )}
            </div>
            <div className='modal-body text-left'>
              {!!BodyComponent && <BodyComponent />}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Provider
