import React, { useContext } from 'react'
import clsx from 'clsx'

import styles from './TemplateSwitch.module.css'
import TemplatePicker from '../../template/TemplatePicker'
import { TemplateCardSize } from '../../template/TemplateCard'
import { TemplateDisplayNameMap, VersionTemplateMap } from '../preview/maps'
import { getLatestVersion } from '../version'
import { generatePath } from 'react-router-dom'
import { Urls } from '../../routing/urls'
import { BuilderContext } from '../_context'
import { Action } from '../_actions'


function TemplateSwitch() {
  const [state, dispatch] = useContext(BuilderContext)
  const { templateSwitchOpen, template } = state

  const templates = VersionTemplateMap[getLatestVersion()]
    .filter(name => name !== template)
    .map(name => ({
      id: name,
      name: TemplateDisplayNameMap[name],
      thumbnailUrl: generatePath(Urls.Builder_TemplateThumbnail, { templateId: name }),
    }))

  const close = _ => {
    dispatch(Action.ToggleTemplateSwitch())
  }

  const onClick = template => {
    dispatch(Action.SetTemplate(template.id))
    close()
  }

  return templateSwitchOpen
    ? (
      <div className={styles.container}>
        <h2 className='mb-4'>Change template</h2>
        <TemplatePicker
          templates={templates}
          emptyMessage={`Sorry! We couldn't find any templates.`}
          size={TemplateCardSize.Large}
          onClick={onClick} />
        <button className={clsx(styles.back, 'btn btn-primary')} onClick={close}>Back</button>
      </div>
    )
    : null
}

export default TemplateSwitch
