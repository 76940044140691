import React from 'react'


function TemplateZeus({
  contactDetails, professionalSummary, secondaryEducation, tertiaryEducation, employmentHistory, workExperience,
  internships, skills, qualifications, interests, languages, extracurricular, links, references,
}) {
  const isVisibleSingle = section => Object.keys(section.values).length > 0 && !section.config.hidden
  const isVisibleArray = (section, excludeNames = []) => {
    const sections = ((section.values || {}).sections || [])
    const configs = ((section.config || {}).sections || [])
    return (
      sections.filter(Boolean).length > 0 && !section.config.hidden &&
      configs.filter(x => !excludeNames.includes(x.name) && !x.hidden).length > 0
    )
  }
  const isVisibleRte = content => content && content !== '<p><br></p>'
  const parseJsonStringOrDefault = (string, defaultValue) => {
    try {
      return JSON.parse(string)
    } catch {
      return defaultValue
    }
  }
  const showMonthYearRange = (startJSON, endJSON) => {
    const start = parseJsonStringOrDefault(startJSON, {})
    const end = parseJsonStringOrDefault(endJSON, {})

    if (!!start.year && !!end.year && start.year === end.year) {
      const startMonth = ({
        1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun',
        7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec',
      }[start.month] || '')
      const endMonth = ({
        1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun',
        7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec',
      }[end.month] || '')
      return `${!!startMonth && !!endMonth ? `${startMonth} – ${endMonth}` : (startMonth || endMonth)} ${end.year}`
    } else {
      const startString = `${({
        1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun',
        7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec',
      }[start.month] || '')} ${start.year || ''}`.trim()
      const endString = `${({
        1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun',
        7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec',
      }[end.month] || '')} ${end.year || ''}`.trim()
      return `${startString}${startString && endString && ' – '}${endString}`
    }
  }
  return (
    <div className='TemplateBasic'>
      {isVisibleSingle(contactDetails) && (
        <div className='header'>
          <h1>{contactDetails.values.firstName} {contactDetails.values.familyName}</h1>
          <div className='table-wrapper'>
            <table>
              <tr>
                {!!contactDetails.values.email && (
                  <td>
                    <div className='subtitle'>Email:</div>{contactDetails.values.email}
                    <div className='divider'></div>
                  </td>
                )}
                {!!contactDetails.values.mobile && (
                  <td>
                    <div className='subtitle'>Mobile:</div>{contactDetails.values.mobile}
                    <div className='divider'></div>
                  </td>
                )}
                {!!contactDetails.values.address && (
                  <td>
                    <div className='subtitle'>Address:</div>{contactDetails.values.address}
                    <div className='divider'></div>
                  </td>
                )}
              </tr>
            </table>
          </div>
        </div>
      )}
      <div className='body'>
        {isVisibleSingle(professionalSummary) && isVisibleRte(professionalSummary.values.summary) && (
          <div className='section'>
            <h2>Professional summary</h2>
            <div className='professionalSummary rte' dangerouslySetInnerHTML={{ __html: professionalSummary.values.summary }} />
          </div>
        )}
        {(isVisibleArray(secondaryEducation, ['addSecondary']) || isVisibleArray(tertiaryEducation, ['addTertiary'])) && (
          <div className='section'>
            <h2>Education</h2>
            <div>
              {isVisibleArray(secondaryEducation, ['addSecondary']) && (
                <React.Fragment>
                  {secondaryEducation.values.sections
                    .filter((_, i) => !secondaryEducation.config.sections[i].hidden)
                    .map((section, i) => {
                      const { education = {} } = (section || {})
                      return (
                        <table key={i}>
                          <tr>
                            <td width="24%">
                              <div>{showMonthYearRange(education.yearStarted, education.yearFinished)}</div>
                            </td>
                            <td>
                              <div><h3>{education.schoolName || 'Secondary education'}</h3></div>
                              {education.expectedGraduationYear && education.yearFinished.includes('Current') && (!!showMonthYearRange(education.expectedGraduationYear)) && <div>Expected graduation: {showMonthYearRange(education.expectedGraduationYear)}</div>}
                              {education.atar && <div>Mark achieved: {education.atar}</div>}
                              {education.extracurricular && isVisibleRte(education.extracurricular) && (
                                <React.Fragment>
                                  <h4>Extracurricular</h4>
                                  <div className='rte rte-compact' dangerouslySetInnerHTML={{ __html: education.extracurricular }} />
                                </React.Fragment>
                              )}
                              {education.achievements && isVisibleRte(education.achievements) && (
                                <React.Fragment>
                                  <h4>Achievements</h4>
                                  <div className='rte rte-compact' dangerouslySetInnerHTML={{ __html: education.achievements }} />
                                </React.Fragment>
                              )}
                              {education.sports && isVisibleRte(education.sports) && (
                                <React.Fragment>
                                  <h4>Sports</h4>
                                  <div className='rte rte-compact' dangerouslySetInnerHTML={{ __html: education.sports }} />
                                </React.Fragment>
                              )}
                            </td>
                          </tr>
                        </table>
                      )
                    })
                  }
                </React.Fragment>
              )}
              {isVisibleArray(tertiaryEducation, ['addTertiary']) && (
                <React.Fragment>
                  {tertiaryEducation.values.sections
                    .filter((_, i) => !tertiaryEducation.config.sections[i].hidden)
                    .map((section, i) => {
                      const { education = {} } = (section || {})
                      return (
                        <table key={i}>
                          <tr>
                            <td width="24%">
                              <div>{showMonthYearRange(education.yearStarted, education.yearFinished)}</div>
                            </td>
                            <td>
                              <div><h3>{education.schoolName || 'Tertiary education'}</h3></div>
                              <div>{education.qualification}</div>
                              {education.expectedGraduationYear && education.yearFinished.includes('Current') && (!!showMonthYearRange(education.expectedGraduationYear)) && <div>Expected graduation: {showMonthYearRange(education.expectedGraduationYear)}</div>}
                              <div>{education.gpa && `Mark average: ${education.gpa}`}</div>
                              <div>
                                {education.extracurricular && isVisibleRte(education.extracurricular) && (
                                  <React.Fragment>
                                    <h4>Extracurricular</h4>
                                    <div className='rte rte-compact' dangerouslySetInnerHTML={{ __html: education.extracurricular }} />
                                  </React.Fragment>
                                )}
                                {education.achievements && isVisibleRte(education.achievements) && (
                                  <React.Fragment>
                                    <h4>Achievements</h4>
                                    <div className='rte rte-compact' dangerouslySetInnerHTML={{ __html: education.achievements }} />
                                  </React.Fragment>
                                )}
                                {education.sports && isVisibleRte(education.sports) && (
                                  <React.Fragment>
                                    <h4>Sports</h4>
                                    <div className='rte rte-compact' dangerouslySetInnerHTML={{ __html: education.sports }} />
                                  </React.Fragment>
                                )}
                              </div>
                            </td>
                          </tr>
                        </table>
                      )
                    })}
                </React.Fragment>
              )}
            </div>
          </div>
        )}
        {isVisibleArray(employmentHistory, ['intro', 'addEmployer']) && (
          <div className='section'>
            <h2>Employment</h2>
            {employmentHistory.values.sections
              .filter((_, i) => !employmentHistory.config.sections[i].hidden)
              .map((section, i) => {
                const { employer = {} } = (section || {})
                return employer && (
                  <table key={i}>
                    <tr>
                      <td>
                        <div>
                          <h3>
                            {employer.jobTitle}
                            {employer.jobTitle && employer.company && ', '}
                            {employer.company}
                          </h3>
                        </div>
                        <div>
                          {showMonthYearRange(employer.startDate, employer.endDate)}
                        </div>
                        {isVisibleRte(employer.description) && <div dangerouslySetInnerHTML={{ __html: employer.description }} />}
                        {isVisibleRte(employer.responsibilities) && <div dangerouslySetInnerHTML={{ __html: employer.responsibilities }} />}
                      </td>
                    </tr>
                  </table>
                )
              })}
          </div>
        )}
        {isVisibleArray(workExperience, ['addExperience']) && (
          <div className='section'>
            <h2>Work experience</h2>
            {workExperience.values.sections
              .filter((_, i) => !workExperience.config.sections[i].hidden)
              .map((section, i) => {
                const { experience = {} } = (section || {})
                return experience && (
                  <table key={i}>
                    <tr>
                      <td>
                        <div>
                          <h3>
                            {experience.company}
                          </h3>
                        </div>
                        <div>
                          {showMonthYearRange(experience.startDate, experience.endDate)}
                        </div>
                        {isVisibleRte(experience.description) && <div dangerouslySetInnerHTML={{ __html: experience.description }} />}
                      </td>
                    </tr>
                  </table>
                )
              })}
          </div>
        )}
        {isVisibleArray(internships, ['addInternship']) && (
          <div className='section'>
            <h2>Internships</h2>
            {internships.values.sections
              .filter((_, i) => !internships.config.sections[i].hidden)
              .map((section, i) => {
                const { internship = {} } = (section || {})
                return internship && (
                  <table key={i}>
                    <tr>
                      <td>
                        <div>
                          <h3>
                            {internship.jobTitle}
                            {internship.jobTitle && internship.company && ', '}
                            {internship.company}
                          </h3>
                        </div>
                        <div>
                          {showMonthYearRange(internship.startDate, internship.endDate)}
                        </div>
                        {isVisibleRte(internship.description) && <div dangerouslySetInnerHTML={{ __html: internship.description }} />}
                        {isVisibleRte(internship.responsibilities) && <div dangerouslySetInnerHTML={{ __html: internship.responsibilities }} />}
                      </td>
                    </tr>
                  </table>
                )
              })}
          </div>
        )}
        {isVisibleSingle(skills) && isVisibleRte(skills.values.longform) && (
          <div className='section'>
            <h2>Skills</h2>
            <div dangerouslySetInnerHTML={{ __html: skills.values.longform }} />
          </div>
        )}
        {isVisibleSingle(qualifications) && isVisibleRte(qualifications.values.longform) && (
          <div className='section'>
            <h2>Qualifications</h2>
            <div dangerouslySetInnerHTML={{ __html: qualifications.values.longform }} />
          </div>
        )}
        {isVisibleSingle(interests) && isVisibleRte(interests.values.longform) && (
          <div className='section'>
            <h2>Interests</h2>
            <div dangerouslySetInnerHTML={{ __html: interests.values.longform }} />
          </div>
        )}
        {isVisibleSingle(extracurricular) && isVisibleRte(extracurricular.values.longform) && (
          <div className='section'>
            <h2>Extracurricular</h2>
            <div dangerouslySetInnerHTML={{ __html: extracurricular.values.longform }} />
          </div>
        )}
        {isVisibleSingle(languages) && isVisibleRte(languages.values.longform) && (
          <div className='section'>
            <h2>Languages</h2>
            <div dangerouslySetInnerHTML={{ __html: languages.values.longform }} />
          </div>
        )}
        {isVisibleSingle(links) && isVisibleRte(links.values.longform) && (
          <div className='section'>
            <h2>Portfolio</h2>
            <div dangerouslySetInnerHTML={{ __html: links.values.longform }} />
          </div>
        )}
        {isVisibleArray(references, ['intro', 'toggle', 'addReferee']) && (
          <div className='section'>
            <h2>References</h2>
            {((references.values.sections[1] || {}).toggle || {}).toggle && (
              <div>References available upon request.</div>
            )}
            {!((references.values.sections[1] || {}).toggle || {}).toggle && (
              <React.Fragment>
                {references.values.sections
                  .filter((_, i) => !references.config.sections[i].hidden && references.config.sections[i].name === 'referee')
                  .map((section, i) => {
                    const { referee = {} } = (section || {})
                    return (
                      <table key={i}>
                        <tr>
                          <td>
                            {referee.name && <div><h3>{referee.name}</h3></div>}
                            {referee.company && <div>Company: {referee.company}</div>}
                            {referee.phone && <div>Phone: {referee.phone}</div>}
                            {referee.email && <div>Email: {referee.email}</div>}
                          </td>
                        </tr>
                      </table>
                    )
                  })}
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default TemplateZeus
