import React, { useContext } from 'react'

import { SectionContext } from './_context'
import { withDebug } from '../../debug/withDebug'


function SectionDebug() {
  const { sectionKey, index, formValues } = useContext(SectionContext)
  return (
    <div style={{ background: 'yellow' }}>
      <small>
        <div>key: {sectionKey}</div>
        <div>index: {index}</div>
        <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>formValues: {JSON.stringify(formValues)}</div>
      </small>
    </div>
  )
}

export default withDebug(SectionDebug)
