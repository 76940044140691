import { api } from '../core/_requests'


export const getResumes = async () => {
  const result = await api.get(`builder/resume/`)
  return result
}

export const copyResume = async (id) => {
  const result = await api.post(`builder/resume/`, {
    copy: id,
  })
  return result
}

export const deleteResume = async (id) => {
  const result = await api.delete(`builder/resume/${id}/`)
  return result
}
