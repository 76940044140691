import moment from 'moment'

import { api } from '../../../../core/_requests'


export const submitToSuper = async values => {
  const residential = {
    source: 'Manual',
    name: values.residentialAddress.street,
    types: [],
    street_number: '',
    route: '',
    locality: values.residentialAddress.suburb,
    iso_country_code: 'AU',
    administrative_area_level_1: values.residentialAddress.state,
    postal_code: values.residentialAddress.postcode,
    description: `${values.residentialAddress.street}, ${values.residentialAddress.suburb} ${values.residentialAddress.state} ${values.residentialAddress.postcode}`,
  }
  const postal = values.addressPostalSameAsResidential
    ? { ...residential }
    : {
      source: 'Manual',
      name: values.postalAddress.street,
      types: [],
      street_number: '',
      route: '',
      locality: values.postalAddress.suburb,
      iso_country_code: 'AU',
      administrative_area_level_1: values.postalAddress.state,
      postal_code: values.postalAddress.postcode,
      description: `${values.postalAddress.street}, ${values.postalAddress.suburb} ${values.postalAddress.state} ${values.postalAddress.postcode}`,
    }
  const data = {
    first_name: values.firstName,
    family_name: values.familyName,
    mobile_number: values.mobileNumber,
    date_of_birth: moment(values.dateOfBirth).format('YYYY-MM-DD'),
    gender: values.gender,
    email: values.email,
    residential_address: residential,
    postal_address: postal,
  }

  const result = await api.post(`partners/ssps/professional/`, data)
  return result
}
