import React, { useContext } from 'react'
import { useHistory, generatePath } from 'react-router-dom'
import clsx from 'clsx'

import { withUnauthenticatedRedirect } from '../account/withUnauthenticatedRedirect'
import typographyStyles from '../typography/Typography.module.css'
import TemplatePicker from '../template/TemplatePicker'
import { VersionTemplateMap, TemplateDisplayNameMap } from '../builder/preview/maps'
import { getLatestVersion } from '../builder/version'
import { createResume } from '../builder/create'
import { AccountContext } from '../account/_context'
import { Urls } from '../routing/urls'
import { TemplateCardSize } from '../template/TemplateCard'


function MyResumesNew() {

  const history = useHistory()
  const templates = VersionTemplateMap[getLatestVersion()].map(name => ({
    id: name,
    name: TemplateDisplayNameMap[name],
    thumbnailUrl: generatePath(Urls.Builder_TemplateThumbnail, { templateId: name }),
  }))

  const [account,] = useContext(AccountContext)

  const start = template => {
    createResume(template.id, { account }).then(resume => {
      history.push(generatePath(Urls.Builder, { resumeId: resume.resumeId }))
    })
  }

  return (
    <div className='container py-5'>
      <div className='row'>
        <div className='col'>
          <h1 className={clsx(typographyStyles.title)}>New resume</h1>
          <div className='lead'>Click to pick a template</div>
          {/* <div className={typographyStyles.psst}>(Psst, you can always change this)</div> */}
        </div>
      </div>
      <div className='row py-5'>
        <div className='col'>
          <TemplatePicker
            templates={templates}
            emptyMessage={`Sorry! We couldn't find any templates.`}
            size={TemplateCardSize.Large}
            onClick={start} />
        </div>
      </div>
    </div>
  )
}

export default withUnauthenticatedRedirect()(MyResumesNew)
