import React from 'react'
import { NavLink as ReactRouterNavLink } from 'react-router-dom'
import clsx from 'clsx'

import styles from './NavLink.module.css'


export const NavLinkType = {
  Text: 'text',
  Button: 'button',
  DropdownText: 'dropdownText',
}

const NavLinkTypeStyleMap = {
  [NavLinkType.Text]: styles.text,
  [NavLinkType.Button]: styles.button,
  [NavLinkType.DropdownText]: styles.dropdownText,
}

function NavLink({ type, className, ...rest }) {
  return (
    <ReactRouterNavLink
      className={clsx(styles.link, NavLinkTypeStyleMap[type], className)}
      activeStyle={{ fontWeight: 'bold', background: 'none', color: 'inherit' }}
      {...rest} />
  )
}

export default NavLink
