import React from 'react'

import SectionTitle from './common/SectionTitle'
import PageTitle from './common/PageTitle'
import ScrollToTopOnMount from '../routing/ScrollToTopOnMount'
import { getGlobals } from '../Globals'


function Privacy() {
  return (
    <React.Fragment>
      <ScrollToTopOnMount />
      <div className='container py-5'>
        <div className='row'>
          <div className='col'>
            <PageTitle>Privacy Policy</PageTitle>
            <SectionTitle>Purpose</SectionTitle>
            <p>Personal information is collected to provide relevant and useful employment related services, such as creating a resume, and helping users get ready for employment.</p>
            <p>Our aim is to have all your personal information be transparent and controlled by you.</p>
            <SectionTitle>Australian legislation</SectionTitle>
            <p>The Website complies with the Privacy Act (2008), The National Privacy Principles and the Spam Act (2003).</p>
            <SectionTitle>Who has your personal information</SectionTitle>
            <p>Student Services Australia Pty Ltd, ACN 100 372 156 is the owner of {getGlobals().site.prettyUrl}. When we send you email alerts on behalf of an organisation, they do not have your personal information. Your personal information is only held by us and our third party service providers directly required to provide our services to you.</p>
            <SectionTitle>Unsubscribing</SectionTitle>
            <p>All email correspondence sent to you will identify who it is from and will have a functional unsubscribe link in the footer. You can unsubscribe at any time on the website.</p>
            <SectionTitle>Privacy officer</SectionTitle>
            <p>Our privacy officer is Andrew Maloney. Please contact Andrew if you have any questions or concerns regarding the website. Andrew is contactable via email. firstname [at] studentservices.com.au.</p>
            <SectionTitle>Spam</SectionTitle>
            <p>We do not send out unsolicited email (spam).</p>
            <SectionTitle>Website usage data</SectionTitle>
            <p>Like most websites, the Website maintains log files tracking IP addresses to analyse trends, help diagnose problems with our servers, and to monitor traffic/usage in order to provide better service to our members. The Website uses cookies to improve our members' experience when using our Website. You can always choose to not accept cookies, but in doing so you may not be able to use our Website effectively. Some of our business partners, such as our Web analytics provider, also use cookies on our site. These cookies are used to track visitor behaviour, and the Website has no access to or control over these cookies.</p>
            <SectionTitle>Email usage data</SectionTitle>
            <p>To improve our services, the Website uses pixel tags and other methods to gather information about the emails it sends to users. Pixel tags allow us to record and store information such as date and time the recipient viewed the email message, if the recipient's email program is capable of receiving HTML, and other information.</p>
            <SectionTitle>Links to other websites</SectionTitle>
            <p>SSA is not responsible for the privacy practices or the content of other websites.</p>
            <SectionTitle>Changes to the policy</SectionTitle>
            <p>Under the site terms, the Website may change these policies at any time, with immediate effect.</p>
            <SectionTitle>Contact</SectionTitle>
            <p>If you have questions or concerns regarding this Policy, you should first contact the Privacy Officer below.</p>
            <p>
              Student Services Australia<br />
              56 Meagher Street<br />
              Chippendale NSW 2008<br />
              02 9698 4999
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Privacy
