import React, { useContext, useState } from 'react'

import { BuilderContext } from '../_context'
import { withDebug } from '../../debug/withDebug'
import './PreviewDebug.css'


const Mode = {
  Context: 'context',
  FormValues: 'formValues',
  FormConfig: 'formConfig',
  Preview: 'preview',
}

function PreviewDebug() {
  const [state,] = useContext(BuilderContext)
  const [mode, setMode] = useState(Mode.Preview)
  return (
    <div>
      <div className='PreviewDebugActionBar px-2'>
        <button
          onClick={_ => setMode(Mode.Preview)}
          className={`button btn btn-sm mr-2 ${mode === Mode.Preview ? 'btn-primary' : 'btn-dark'}`}>Preview</button>
        <div className='btn-group my-2' role='group'>
          <button
            onClick={_ => setMode(Mode.Context)}
            className={`button btn btn-sm ${mode === Mode.Context ? 'btn-primary' : 'btn-dark'}`}>BuilderContext</button>
          <button
            onClick={_ => setMode(Mode.FormValues)}
            className={`button btn btn-sm ${mode === Mode.FormValues ? 'btn-primary' : 'btn-dark'}`}>.formValues</button>
          <button
            onClick={_ => setMode(Mode.FormConfig)}
            className={`button btn btn-sm ${mode === Mode.FormConfig ? 'btn-primary' : 'btn-dark'}`}>.formConfig</button>
        </div>
      </div>
      <div className='PreviewDebug'>
        {
          mode !== Mode.Preview && (
            <div style={{ background: 'white', minHeight: '100%' }}>
              <small>
                <pre style={{ whiteSpace: 'pre-wrap' }}>
                  {mode === Mode.Context && <code>{JSON.stringify(state, null, 2)}</code>}
                  {mode === Mode.FormValues && <code>{JSON.stringify(state.formValues, null, 2)}</code>}
                  {mode === Mode.FormConfig && <code>{JSON.stringify(state.formConfig, null, 2)}</code>}
                </pre>
              </small>
            </div>
          )
        }
        {
          mode === Mode.Preview && (null
          )
        }
      </div>
    </div>
  )
}

export default withDebug(PreviewDebug)
