import React from 'react'
import clsx from 'clsx'

import styles from '../../GetStarted.module.css'


function Intro() {
  return (
    <React.Fragment>
      <h1 className={clsx('h2', styles.title)}><strong>Let's get started</strong></h1>
    </React.Fragment>
  )
}

export default Intro