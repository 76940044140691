import { api } from '../core/_requests'


export const login = async (email, password) => {
  const result = await api.post(`account/login/`, { email, password })
  return result
}

export const logout = async () => {
  const result = await api.get(`account/logout/`)
  return result
}

export const getAccount = async () => {
  const result = await api.get(`account/`)
  return result
}

export const createAccount = async (account) => {
  const result = await api.post(`account/`, account)
  return result
}

export const updateAccount = async (account) => {
  const result = await api.put(`account/`, account)
  return result
}

export const deleteAccount = async () => {
  const result = await api.delete(`account/`)
  return result
}

export const sendResetPasswordEmail = async (email) => {
  const result = await api.post(`account/password/reset/`, { email })
  return result
}

export const checkResetPasswordToken = async (tokenId) => {
  const result = await api.get(`account/password/reset/confirm/${tokenId}`)
  return result
}

export const submitResetPassword = async (newPassword, tokenId) => {
  const result = await api.post(`account/password/reset/confirm/`, { newPassword, tokenId })
  return result
}

export const changePassword = async (oldPassword, newPassword) => {
  const result = await api.post(`account/password/change/`, { oldPassword, newPassword })
  return result
}
