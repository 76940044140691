import { postResume } from "../builder/_requests"
import { getTemplateHtmlCss } from "../builder/preview/utils/update"


export const startAccount = async (templateName) => {

  const state = {
    ...((await import(`./_state`)).default),
    template: templateName,
  }

  const templateHtmlCss = getTemplateHtmlCss(state)

  const promise = new Promise(
    async (resolve, reject) => {
      const resume = await postResume(state, templateHtmlCss)
      resolve(resume)
    }
  )
  return promise
}
