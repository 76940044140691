import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { Urls } from '../routing/urls'
import { default as JoinFormStudent } from './super/funds/studentsuper/JoinForm'
import { default as JoinFormProfessional } from './super/funds/professionalsuper/JoinForm'


function SuperStart() {
  return (
    <Switch>
      <Route exact path={Urls.GetJobReady_Super_StudentSuper_Start}><JoinFormStudent /></Route>
      <Route exact path={Urls.GetJobReady_Super_ProfessionalSuper_Start}><JoinFormProfessional /></Route>
    </Switch>
  )
}

export default SuperStart
