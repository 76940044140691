import React, { useContext, useState } from 'react'
import clsx from 'clsx'

import { withUnauthenticatedRedirect } from './withUnauthenticatedRedirect'
import typographyStyles from '../typography/Typography.module.css'
import StackedFieldRow from '../forms/layouts/StackedFieldRow'
import StackedField from '../forms/layouts/StackedField'
import { required } from '../forms/validation'
import Input from '../forms/fields/Input'
import { Form } from 'react-final-form'
import Button, { ButtonType } from '../forms/buttons/Button'
import { AccountContext } from './_context'
import { updateAccount } from './_requests'
import { Action } from './_actions'
import { Link } from 'react-router-dom'
import Select from '../forms/fields/Select'
import ScrollToTopOnMount from '../routing/ScrollToTopOnMount'
import { Urls } from '../routing/urls'


function MyAccount() {

  const [account, dispatch] = useContext(AccountContext)
  const [didUpdate, setDidUpdate] = useState(false)

  const update = values => {
    updateAccount(values).then(account => {
      dispatch(Action.SetAccountData(account))
      setDidUpdate(true)
    })
  }

  const showForm = _ => setDidUpdate(false)

  return (
    <React.Fragment>
      <ScrollToTopOnMount />
      <div className='py-5'>
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <h1 className={clsx(typographyStyles.title)}>My account</h1>
            </div>
          </div>
          <div className='row my-4'>
            <div className='col-md-5 mb-4'>
              {didUpdate && (
                <React.Fragment>
                  <p>Account details successfully updated.</p>
                  <p><Link to={'#'} onClick={showForm}>Back</Link></p>
                </React.Fragment>
              )}
              {!didUpdate && (
                <Form
                  onSubmit={update}
                  initialValues={account}
                  render={({ values, handleSubmit, submitting }) => values && Object.keys(values).length > 0 && (
                    <form onSubmit={handleSubmit}>
                      <StackedFieldRow>
                        <StackedField
                          className='col-6'
                          name='firstName' label='First name' validate={required}
                          component={Input} />
                        <StackedField
                          className='col-6'
                          name='lastName' label='Family name' validate={required}
                          component={Input} />
                      </StackedFieldRow>
                      <StackedField
                        name='email' label='Email' validate={required}
                        component={Input} />
                      <StackedFieldRow>
                        <StackedField
                          className='col-6'
                          name='mobile' label='Mobile'
                          component={Input} />
                      </StackedFieldRow>
                      <StackedField
                        name='studyStatus' label='Study status'
                        component={Select}
                        fieldData={{
                          options: (values.studyStatusOptions || []).map(x => ({ value: x[0], label: x[1] })),
                          defaultOption: values.studyStatus,
                        }} />
                      <StackedField
                        name='employmentStatus' label='Employment status'
                        component={Select}
                        fieldData={{
                          options: (values.employmentStatusOptions || []).map(x => ({ value: x[0], label: x[1] })),
                          defaultOption: values.employmentStatus,
                        }} />
                      <StackedField
                        name='workExperienceLevel' label='Work experience level'
                        component={Select}
                        fieldData={{
                          options: (values.workExperienceLevelOptions || []).map(x => ({ value: x[0], label: x[1] })),
                          defaultOption: values.workExperienceLevel,
                        }} />
                      <div className='mt-4'>
                        <Button submit
                          type={ButtonType.CtaSecondary}
                          disabled={submitting}>
                          Update
                      </Button>
                      </div>
                    </form>
                  )}>
                </Form>
              )}
            </div>
            <div className='col-md-7'>
              <strong>Other options</strong>
              <ul className='list-unstyled mt-1' style={{ fontSize: '.9rem' }}>
                <li><Link to={Urls.MyAccount_ChangePassword}>Update password</Link></li>
                <li><Link to={Urls.MyAccount_DeleteAccount}>Delete account</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  )
}

export default withUnauthenticatedRedirect()(MyAccount)
