import React from 'react'

import SectionTitle from './common/SectionTitle'
import PageTitle from './common/PageTitle'
import ScrollToTopOnMount from '../routing/ScrollToTopOnMount'
import { getGlobals } from '../Globals'


function Terms() {
  const { site } = getGlobals()
  return (
    <React.Fragment>
      <ScrollToTopOnMount />
      <div className='container py-5'>
        <div className='row'>
          <div className='col'>
            <PageTitle>Terms and Conditions</PageTitle>
            <p>Please read and agree to the following Terms and Conditions before joining {site.name}.</p>
            <SectionTitle>Purpose</SectionTitle>
            <p>The purpose of this website is to provide relevant and useful employment related services, such as creating a resume and helping users get ready for employment.</p>
            <SectionTitle>Parties</SectionTitle>
            <p>Student Services Australia Pty Ltd (“SSA”), ACN 100 372 156 (subsequently referred to as "SSA") is the owner of the {getGlobals().site.prettyUrl} ("Website").</p>
            <p>By using the Website you are entering into an agreement with SSA to be bound by these Terms and Conditions.</p>
            <SectionTitle>Variation</SectionTitle>
            <p>SSA may modify these Terms and Conditions from time to time and that such modifications will be effective and binding on you when posted on the Website.</p>
            <SectionTitle>Decline and Cancellation discretion</SectionTitle>
            <p>SSA may decline to accept any person for registration. SSA may also cancel and delete an existing account for, but not limited to, not providing appropriate identification or not complying with the Website Terms and Conditions. SSA has the sole discretion to determine membership.</p>
            <SectionTitle>Email and password</SectionTitle>
            <p>To access the Website, you must provide an email address and password. You are solely responsible for maintaining the confidentiality of your email and password. You must notify SSA immediately of any unauthorised use of your email and password. You are responsible for any authorised or unauthorised actions undertaken using your email address and password.</p>
            <SectionTitle>Privacy Guarantee</SectionTitle>
            <p>The Website complies with the Privacy Act (2008), The National Privacy Principles and the Spam Act (2003). When we send you emails on behalf of another organisation, that organisation does not have your personal information. All emails have a functional unsubscribe link in the footer. You can unsubscribe at any time. Your personal information is only held by the Websites and our service providers who directly are required to provide our services. See our full Privacy Policy here.</p>
            <SectionTitle>Misuse</SectionTitle>
            <p>You must not attempt to mislead or deceive by posting inaccurate, incomplete or false material to the Websites or by impersonating any person on the Website. You must not post to the Website, material which infringes the intellectual property rights of any third party. You must not post any illegal, threatening, abusive, harmful, obscene or otherwise objectionable material to the Websites. You must not use the Websites for or to encourage any unlawful action. You must not post to the Website, material which contains or is a virus, worm, Trojan horse, spyware or other contaminating or destructive code. You must not use any device, routine or software to reduce the functionality of the Website or disrupt its users.</p>
            <SectionTitle>Indemnity and liabilities</SectionTitle>
            <p>You will indemnify SSA, its officers, employees and agents against all loss, damage, costs, professional fees and expenses (including legal fees), actions, claims and liability arising directly or indirectly from or in relation to your use or misuse of the Website.</p>
            <SectionTitle>Use and disclosure of material</SectionTitle>
            <p>You consent to SSA collecting, using and disclosing material in accordance with our Privacy Guarantee. SSA cannot guarantee that this material will not be unlawfully accessed or altered by a third party during transit over the Internet or while on the Website.</p>
            <SectionTitle>Third party service providers</SectionTitle>
            <p>To enable delivery of some services to you, you acknowledge that SSA may provide your personal information and other material from you to specified contracted third party service providers. SSA enters into contractual arrangements with these service providers to maintain the confidentiality of your personal information.</p>
            <p>It is a condition of use that you read and agree to the third party's terms and conditions, in addition to these terms and conditions. Third party terms form part of these terms and conditions.</p>
            <SectionTitle>Services changes</SectionTitle>
            <p>SSA reserves the right to change, add or cancel services or features of the Website, as well as reformat and change material which you have posted to the Website without prior notice.</p>
            <SectionTitle>Service availability</SectionTitle>
            <p>SSA does not guarantee that the services provided will be uninterrupted or error-free. The Website may be temporarily unavailable.</p>
            <SectionTitle>No warranties</SectionTitle>
            <p>The content of the Website is intended for general information only. SSA does not make any warranties or representations, express or implied, in relation to the completeness, accuracy or timeliness of the information contained in the Website. To the extent permitted by law, SSA excludes all liability for any direct, indirect or consequential damage, loss or costs caused by or in connection with your use of or inability to use the Website or SSA's breach of contract or statute, negligence or misrepresentation.</p>
            <SectionTitle>Links to other sites</SectionTitle>
            <p>You acknowledge that any links from SSA to third party sites do not indicate that SSA has any control over or responsibility for the contents of such sites. SSA excludes all liability for any direct or indirect damage, loss or costs of any kind arising in any way out of or in connection with your access and/or use of third party sites.</p>
            <p>After you have joined the Website, you may be invited to join other relevant membership programs.</p>
            <p>If you choose to join by ticking the relevant opt-in box, you understand you are joining a separate membership program and agree to that organisation's terms and conditions. If subsequently you no longer wish to be a member of that program, you will need to contact that organisation directly to unsubscribe.</p>
            <SectionTitle>Subsequent transaction parties</SectionTitle>
            <p>The Website connects you with other parties who you may later enter into an agreement with. SSA is not a party in these transactions and provides no warranties as to the quality of these services.</p>
            <SectionTitle>Waiver</SectionTitle>
            <p>A failure or delay by SSA in exercising a right under these Terms and Conditions does not result in a waiver of that right. A waiver of any right under these Terms and Conditions must be made in writing.</p>
            <SectionTitle>Severability</SectionTitle>
            <p>If any part of these Terms and Conditions is held to be invalid or unenforceable that part shall be severed and the remaining Terms and Conditions will continue to be valid and enforceable.</p>
            <SectionTitle>Applicable law</SectionTitle>
            <p>These Terms and Conditions are governed by the laws of New South Wales and you submit to the non-exclusive jurisdiction of the courts of New South Wales and any courts which may hear appeals from these courts in respect of proceedings regarding these Terms and Conditions.</p>
            <SectionTitle>Contact</SectionTitle>
            <p>
              Student Services Australia<br />
              56 Meagher Street<br />
              Chippendale NSW 2008<br />
              02 9698 4999
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Terms
