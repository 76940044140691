import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { ModalContext } from '../modals/_context'
import Button, { ButtonType } from '../forms/buttons/Button'
import { AccountContext } from '../account/_context'
import { Urls } from '../routing/urls'


function ApiErrorHandler({ children }) {

  const history = useHistory()
  const [, openModal, closeModal] = useContext(ModalContext)
  const [, , , , logoutAndReset, , , reset] = useContext(AccountContext)

  useEffect(_ => {
    window.onerror = e => {
      if (e.source !== 'api') {
        throw e
      }

      const { error } = e

      if (error.response) {
        /*
        * The request was made and the server responded with a
        * status code that falls out of the range of 2xx
        */
        if (error.response.status >= 400 && error.response.status < 600) {
          // Only handle the below cases as 400s, 401s are handled in their
          // respective form components.

          if (error.response.status === 403) {
            openModal({
              dismissable: false,
              title: _ => <span>Login required</span>,
              body: _ => (
                <React.Fragment>
                  <p>Oops! It looks like you're not logged in.</p>
                  <Button type={ButtonType.CtaSecondary} onClick={_ => {
                    closeModal()
                    logoutAndReset().then(_ => {
                      history.push(Urls.Login)
                    })
                  }}>Log in</Button>
                </React.Fragment>
              )
            })
          }

          if (error.response.status === 404 || error.response.status === 500) {
            openModal({
              dismissable: false,
              title: _ => <span>Oops...</span>,
              body: _ => (
                <React.Fragment>
                  <p>Something went wrong.</p>
                  <Button type={ButtonType.CtaSecondary} onClick={closeModal}>Ok</Button>
                </React.Fragment>
              )
            })
          }
        }
      } else if (error.request) {
        /*
        * The request was made but no response was received, `error.request`
        * is an instance of XMLHttpRequest in the browser and an instance
        * of http.ClientRequest in Node.js
        */
        openModal({
          dismissable: false,
          title: _ => <span>Oops...</span>,
          body: _ => (
            <React.Fragment>
              <p>We're having a bit of trouble. Please wait a moment and try again.</p>
              <Button type={ButtonType.CtaSecondary} onClick={closeModal}>Ok</Button>
            </React.Fragment>
          )
        })
      } else {
        // Something happened in setting up the request and triggered an Error
        openModal({
          dismissable: false,
          title: _ => <span>Oops...</span>,
          body: _ => (
            <React.Fragment>
              <p>Something unexpected happened.</p>
              <Button type={ButtonType.CtaSecondary} onClick={closeModal}>Ok</Button>
            </React.Fragment>
          )
        })
      }
    }
  }, [openModal, closeModal, logoutAndReset, reset, history])

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  )
}

export default ApiErrorHandler
