import React from 'react'


function NoLabel({
  children, name, label, fieldInfo, fieldData,
  labelComponent: Label,
  ...rest
}) {
  return (
    <div {...rest}>
      {children}
    </div>
  )
}

export default NoLabel
