import React, { useContext, useEffect } from 'react'
import { Form, Field } from 'react-final-form'
import { useHistory } from 'react-router-dom'

import { Urls } from '../routing/urls'
import { AccountContext } from '../account/_context'
import Button, { ButtonType } from '../forms/buttons/Button'
import { createEvent } from '../events/_requests'
import { EventCategory, EventDescription } from '../events'
import { updateAccount } from '../account/_requests'
import { FORM_ERROR } from 'final-form'
import { withFormErrorAsync } from '../forms/errors'
import FormError from '../forms/messaging/FormError'
import { isNotInterestedInSuper, getSuperInfoPageUrl } from './status'


function SuperSelect() {

  const [account] = useContext(AccountContext)
  const history = useHistory()

  const onSubmit = async ({ superStatus }) => {
    if (!superStatus) {
      return Promise.reject({ [FORM_ERROR]: 'Please choose an option above.' })
    }
    const result = JSON.parse(superStatus)
    const updatedAccount = await updateAccount({
      ...account,
      ...result,
    })

    // Exit early if they're not interested in super.
    if (isNotInterestedInSuper(updatedAccount)) {
      history.push(Urls.MyResumes)
      return
    }

    // Otherwise redirect to the appropriate super page.
    history.push(getSuperInfoPageUrl(updatedAccount))
  }

  useEffect(_ => {
    createEvent(EventCategory.JobReady, EventDescription.ViewedSuperQuestion)
  }, [])

  return (
    <React.Fragment>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <p>
              In Australia, almost every employee will need a super fund.<br />
              On your first day of work, your employer will ask you for your fund details.
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <Form
              onSubmit={withFormErrorAsync(onSubmit)}
              render={({ handleSubmit, submitting, submitError }) => (
                <form className='my-3' onSubmit={handleSubmit}>
                  <div className='mb-2'><strong>Do you want to get your super organised?</strong></div>
                  {
                    [
                      {
                        label: `Yes`,
                        value: { hasSuper: false, wantsSuperInfo: false },
                      },
                      {
                        label: `No`,
                        value: { hasSuper: true, wantsSuperInfo: false },
                      },
                      {
                        label: `Not sure, tell me more`,
                        value: { hasSuper: false, wantsSuperInfo: true },
                      },
                    ].map((option, i) => {
                      const id = `superStatus-${i + 1}`
                      return (
                        <div key={i} className='form-check'>
                          <Field
                            value={JSON.stringify(option.value)}
                            type='radio'
                            name='superStatus'>
                            {({ input }) => (
                              <input
                                className='form-check-input'
                                id={id} {...input} />
                            )}
                          </Field>
                          <label className='form-check-label' htmlFor={id}>{option.label}</label>
                        </div>
                      )
                    })
                  }
                  {submitError && <FormError className="error mt-3">{submitError}</FormError>}
                  <div className='mt-4'>
                    <Button submit
                      className='mt-2'
                      type={ButtonType.CtaSecondary}
                      disabled={submitting}>
                      Next
                    </Button>
                  </div>
                </form>
              )}>
            </Form>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SuperSelect
