import { postResume } from "./_requests"
import { getTemplateHtmlCss } from "./preview/utils/update"
import { getLatestVersion } from "./version"


// Always start with creating a resume based on the latest version of the builder.
// The result here will be the result that is pulled down for consequent use.
// `stateOverrides` optional.
export const createResume = async (templateName, stateOverrides = {}) => {

  const version = getLatestVersion()
  const initialState = (await import(`./state/${version}`)).default

  let state = {
    ...initialState,
    ...stateOverrides,
    template: templateName,
  }

  try {
    const stateInitialiser = (await import(`./state/init/${version}`)).default
    state = stateInitialiser(state)
  } catch {
    // We don't need to do anything if we don't find a state initialiser.
  }

  const templateHtmlCss = getTemplateHtmlCss(state)

  const promise = new Promise(
    async (resolve, reject) => {
      const resume = await postResume(state, templateHtmlCss)
      resolve(resume)
    }
  )
  return promise
}
