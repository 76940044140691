import React, { useState } from 'react'
import clsx from 'clsx'

import { withUnauthenticatedRedirect } from './withUnauthenticatedRedirect'
import typographyStyles from '../typography/Typography.module.css'
import StackedFieldRow from '../forms/layouts/StackedFieldRow'
import StackedField from '../forms/layouts/StackedField'
import { required } from '../forms/validation'
import Input from '../forms/fields/Input'
import { Form } from 'react-final-form'
import Button, { ButtonType } from '../forms/buttons/Button'
import { changePassword } from './_requests'
import { Link } from 'react-router-dom'
import ScrollToTopOnMount from '../routing/ScrollToTopOnMount'
import { Urls } from '../routing/urls'
import { withFormErrorAsync } from '../forms/errors'
import FormError from '../forms/messaging/FormError'


function ChangePassword() {

  const [didUpdate, setDidUpdate] = useState(false)

  const update = async ({ oldPassword, newPassword }) => {
    await changePassword(oldPassword, newPassword)
    setDidUpdate(true)
  }

  return (
    <React.Fragment>
      <ScrollToTopOnMount />
      <div className='py-5'>
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <h1 className={clsx(typographyStyles.title)}>Change password</h1>
            </div>
          </div>
          <div className='row my-4'>
            <div className='col-md-5'>
              {didUpdate && (
                <React.Fragment>
                  <p>Password successfully updated.</p>
                  <p><Link to={Urls.MyAccount}>Back</Link></p>
                </React.Fragment>
              )}
              {!didUpdate && (
                <Form
                  onSubmit={withFormErrorAsync(update)}
                  render={({ handleSubmit, submitting, submitError }) => (
                    <form onSubmit={handleSubmit}>
                      <StackedFieldRow>
                        <StackedField
                          className='col-6'
                          name='oldPassword' label='Old password' validate={required} type='password'
                          component={Input} />
                      </StackedFieldRow>
                      <StackedFieldRow>
                        <StackedField
                          className='col-6'
                          name='newPassword' label='New password' validate={required} type='password'
                          component={Input} />
                      </StackedFieldRow>
                      {submitError && <FormError className="error mt-3">{submitError}</FormError>}
                      <div className='mt-4'>
                        <Button submit
                          type={ButtonType.CtaSecondary}
                          disabled={submitting}>
                          Update
                      </Button>
                      </div>
                    </form>
                  )}>
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  )
}

export default withUnauthenticatedRedirect()(ChangePassword)
