import React from 'react'
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'

import styles from './ProgressBar.module.css'
import typographyStyles from '../typography/Typography.module.css'
import circleTick from '../assets/icons/circle-tick-orange.svg'
import circleTickFilled from '../assets/icons/circle-tick-filled-orange.svg'
import { Urls } from '../routing/urls'


export const ProgressBarStep = {
  Tfn: 'Tax File Number',
  Super: 'Super',
}

const ProgressBarStepOrder = {
  [ProgressBarStep.Tfn]: 1,
  [ProgressBarStep.Super]: 2,
}

const ProgressBarStepUrl = {
  [ProgressBarStep.Tfn]: Urls.GetJobReady_Tfn,
  [ProgressBarStep.Super]: Urls.GetJobReady_Super,
}

function ProgressBar({ className, ...rest }) {

  const { pathname } = useLocation()
  const activeStep = Object.keys(ProgressBarStepUrl).find(x => pathname.includes(ProgressBarStepUrl[x]))

  return (
    <div className={clsx('d-flex justify-content-start text-center', className)} {...rest}>
      {
        Object.keys(ProgressBarStepOrder).map((key, i) => {
          const seen = ProgressBarStepOrder[key] <= ProgressBarStepOrder[activeStep]
          const active = pathname.includes(ProgressBarStepUrl[key])
          return (
            <div key={i} className={clsx(styles.step, 'mx-3')}>
              <div className={clsx(styles.circle, 'mb-2')}>
                <img src={seen ? circleTickFilled : circleTick} alt='' />
              </div>
              <div className={!seen ? typographyStyles.psst : undefined}>
                {active && <b>{key}</b>}
                {!active && key}
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default ProgressBar
