import React, { useContext } from 'react'

import { DebugContext } from './_context'


function withDebug(WrappedComponent) {
  return function Wrapped(props) {
    const { debug } = useContext(DebugContext)
    return debug ? <WrappedComponent {...props} /> : null
  }
}

export { withDebug }