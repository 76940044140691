import { getIn, setIn } from 'final-form'

// We use the `final-form` utilities `getIn` and `setIn` to
// manipulate values in an object given a string notation of
// the value's location (a complex key). Handles nested properties
// and arrays.

// Done for convenience so we don't have to write our own
// implementation seeing as we use `final-form` already.

// If the need does arise to roll our own implementation
// this module would be the place to do so.

// Usage as follows:
//  const result = setIn({}, 'foo', 'bar')
//  console.log(result)
//  >> { 'foo': 'bar' }

// For more examples see:
// https://final-form.org/docs/final-form/field-names

export {
  getIn,
  setIn,
}
