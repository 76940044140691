import React, { useContext, useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import moment from 'moment'
import { Link, generatePath } from 'react-router-dom'
import { Plus } from 'react-bootstrap-icons'
import clsx from 'clsx'

import { withUnauthenticatedRedirect } from '../account/withUnauthenticatedRedirect'
import typographyStyles from '../typography/Typography.module.css'
import Button, { ButtonType } from '../forms/buttons/Button'
import TemplatePicker from '../template/TemplatePicker'
import { TemplateCardSize } from '../template/TemplateCard'
import { AccountContext } from '../account/_context'
import { ModalContext } from '../modals/_context'
import { getResumes, deleteResume, copyResume } from './_requests'
import { Urls } from '../routing/urls'
import styles from './MyResumes.module.css'
import jumpingDog from '../assets/jumping-dog.svg'
import { needsToGetJobReady } from '../jobready/status'


const GET_JOB_READY_MODAL_COOKIE_NAME = 'MyResumesGJRDismissed'

function MyResumes() {

  const [account, , , , , , reloadAccount] = useContext(AccountContext)
  const [, openModal, closeModal] = useContext(ModalContext)
  const [resumes, setResumes] = useState([])

  const name = account.firstName

  const loadResumes = _ => {
    getResumes().then((resumes) => {
      // Display all resumes.
      setResumes(resumes.map(x => {
        return {
          ...x,
          name: x.name || 'Untitled',
        }
      }))
    })
  }

  const maybePromptGetJobReady = _ => {
    // Trigger a Get Job Ready prompt on the 2nd time the user logs in.
    if (account.loginCount === 2 && needsToGetJobReady(account)) {
      if (!Cookies.get(GET_JOB_READY_MODAL_COOKIE_NAME)) {
        openModal({
          body: _ => (
            <div>
              <div className={clsx(typographyStyles.color__tertiary, 'h4 text-center font-weight-bold')}>Welcome back!</div>
              <div className='p lead text-center'>Before you get back to editing your resume...</div>
              <div className='text-center my-4'>
                <div>
                  <img className={clsx(styles.dog, 'mb-4')} src={jumpingDog} alt='' />
                </div>
                <Link to={Urls.GetJobReady} onClick={closeModal}>
                  <Button type={ButtonType.CtaSecondary}>Get job ready</Button>
                </Link>
              </div>
            </div>
          ),
          onClose: _ => Cookies.set(
            GET_JOB_READY_MODAL_COOKIE_NAME,
            'dismissed',
            { expires: moment().add(1, 'weeks').toDate() }
          ),
        })
      }
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(_ => { reloadAccount() }, [])
  useEffect(loadResumes, [])
  useEffect(maybePromptGetJobReady, [account])

  const onCopy = resume => copyResume(resume.resumeId).then(loadResumes)
  const onDelete = resume => {
    openModal({
      title: _ => (
        <span>Delete "{resume.name}"?</span>
      ),
      body: _ => {
        const onDelete = _ => {
          deleteResume(resume.resumeId).then(loadResumes)
          closeModal()
        }
        return (
          <React.Fragment>
            <p>"{resume.name}" will be deleted forever. Are you sure?</p>
            <div>
              <Button type={ButtonType.Danger} className='mr-3 mb-2' onClick={onDelete}>Delete</Button>
              <Button type={ButtonType.Neutral} onClick={closeModal}>Cancel</Button>
            </div>
          </React.Fragment>
        )
      },
    })
  }

  return (
    <div className='container py-5'>
      <div className='row'>
        <div className='col'>
          <h1 className={clsx(typographyStyles.title)}>Welcome{!!name ? ` ${name}` : ''}!</h1>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6'>
          <div className='lead'>Your resumes</div>
        </div>
        <div className='col-md-6 text-md-right mt-3 mt-md-0'>
          <Link to={Urls.MyResumes_New}>
            <Button type={ButtonType.CtaPrimary}>
              <Plus className={styles.plus} />
              Create a new resume
          </Button>
          </Link>
        </div>
      </div>
      <div className='row py-5'>
        <div className='col'>
          <TemplatePicker
            templates={resumes}
            emptyMessage={`Looks like you don't have any resumes yet.<br />Why not start a new one?`}
            getEditLink={resume => generatePath(Urls.Builder, { resumeId: resume.resumeId })}
            size={TemplateCardSize.Large}
            onCopy={onCopy}
            onDelete={onDelete} />
        </div>
      </div>
    </div>
  )
}

export default withUnauthenticatedRedirect()(MyResumes)
