export const composeValidators = validators => value => {
  return validators.reduce((error, validator) => {
    return error || validator(value)
  }, undefined)
}

export const required = value => (value ? undefined : 'Required.')

export const ValidatorType = {
  Required: 'required',
}

export const ValidatorTypeMap = {
  [ValidatorType.Required]: required,
}
