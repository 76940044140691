import React, { useContext } from 'react'
import clsx from 'clsx'

import PreviewDebug from './PreviewDebug'
import PreviewFrame from './PreviewFrame'
import styles from './Preview.module.css'
import { BuilderContext } from '../_context'


function Preview() {
  const [state] = useContext(BuilderContext)
  const { previewOpen } = state
  return (
    <div className={clsx(styles.container, !previewOpen && styles.container__closed)}>
      <PreviewDebug />
      <PreviewFrame />
    </div>
  )
}

export default Preview
