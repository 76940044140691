import React, { useReducer, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { AccountContext } from './_context'
import initialState from './_state'
import { getGlobals } from '../Globals'
import { getAccount, logout, deleteAccount } from './_requests'
import { reducer } from './_reducers'
import { Action } from './_actions'
import { Urls } from '../routing/urls'
import { isAccountReady } from './status'


function Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [name, setName] = useState(null)
  const [loggedIn, setLoggedIn] = useState(false)
  const history = useHistory()

  useEffect(_ => {
    if (!!getGlobals().user.id) {
      reloadAccount()
    }
  }, [])

  useEffect(_ => {
    if (isAccountReady(state)) {
      const name = `
        ${(state.firstName[0] || '').toUpperCase()}${(state.firstName || '').slice(1)}
        ${(state.lastName[0] || '').toUpperCase()}.`
      setName(name)
      setLoggedIn(true)
    }
  }, [state])

  const reloadAccount = _ => getAccount().then(account => dispatch(Action.SetAccountData(account)))

  const reset = _ => {
    setName(null)
    setLoggedIn(false)
    getGlobals().user.id = null
  }

  const logoutAndReset = _ => {
    return logout().then(_ => {
      dispatch(Action.ClearAccountData())
      reset()
      history.push(Urls.Home)
    })
  }

  const deleteAndReset = _ => {
    return deleteAccount().then(_ => {
      dispatch(Action.ClearAccountData())
      reset()
      history.push(Urls.Home)
    })
  }

  return (
    <AccountContext.Provider value={[state, dispatch, name, loggedIn, logoutAndReset, deleteAndReset, reloadAccount, reset]}>
      {children}
    </AccountContext.Provider>
  )
}

export default Provider
