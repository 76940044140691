import { Urls } from './urls'


export const isInGetStarted = location => {
  return location.pathname.includes(Urls.GetStarted)
}

export const isInGetJobReady = location => {
  return location.pathname.includes(Urls.GetJobReady)
}
