import React, { useContext } from 'react'
import { Trash } from 'react-bootstrap-icons'
import clsx from 'clsx'

import { FieldLayoutMap, FieldLayoutType, FieldComponentLayoutDefaultMap, FieldComponentType, FieldComponentLabelDefaultMap, FieldLabelType, FieldLabelMap } from './maps'
import { FormContext } from '../_context'
import { ModalContext } from '../../modals/_context'
import { getIn } from '../structure'
import { Action } from '../_actions'
import styles from './FieldLayout.module.css'
import Button, { ButtonType } from '../../forms/buttons/Button'


function FieldLayout({ formValues, ...props }) {
  const { layout, fieldInfo } = props
  const { parent, location, fieldIndex, type } = fieldInfo
  const [formConfig, dispatch] = useContext(FormContext)
  const [, openModal, closeModal] = useContext(ModalContext)

  const Layout = FieldLayoutMap[layout] || FieldComponentLayoutDefaultMap[type] || FieldLayoutMap[FieldLayoutType.LeftLabel]
  const Label = FieldComponentLabelDefaultMap[type] || FieldLabelMap[FieldLabelType.Default]

  const field = getIn(formConfig, `${location}.fields[${fieldIndex}]`)

  const deletable = field.originator === FieldComponentType.AddField
  const deleteField = _ => {
    openModal({
      title: _ => (
        <span>Delete "{fieldInfo.label}"?</span>
      ),
      body: _ => {
        const onDelete = _ => {
          dispatch(Action.DeleteField(parent, location, fieldIndex, formValues))
          closeModal()
        }
        return (
          <React.Fragment>
            <p>"{fieldInfo.label}" will be deleted. Are you sure?</p>
            <div>
              <Button type={ButtonType.Danger} className='mr-3 mb-2' onClick={onDelete}>Delete</Button>
              <Button type={ButtonType.Neutral} onClick={closeModal}>Cancel</Button>
            </div>
          </React.Fragment>
        )
      },
    })
  }

  return (
    <div className={clsx(styles.layout, deletable && styles.withDelete)}>
      <Layout
        labelComponent={Label}
        {...props} />
      {
        deletable && (
          <button className={styles.delete} onClick={deleteField}><Trash /></button>
        )
      }
    </div>
  )
}

export default FieldLayout
