import React from 'react'


function Checkbox({ input, fieldInfo, fieldData, ...rest }) {
  return (
    <input
      className='form-control'
      type='checkbox'
      style={{ width: '20px', height: '20px' }}
      {...input}
      {...rest} />
  )
}

export default Checkbox
