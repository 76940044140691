import React, { useState, useContext, useEffect } from 'react'
import { Form, Field } from 'react-final-form'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

import styles from './JoinForm.module.css'
import typographyStyles from '../../../../typography/Typography.module.css'
import logo from './ps-horizontal.svg'
import { Urls } from '../../../../routing/urls'
import StackedField from '../../../../forms/layouts/StackedField'
import Input from '../../../../forms/fields/Input'
import Button, { ButtonType } from '../../../../forms/buttons/Button'
import { required } from '../../../../forms/validation'
import ScrollToTopOnMount from '../../../../routing/ScrollToTopOnMount'
import Select from '../../../../forms/fields/Select'
import DatePicker from '../../../../forms/fields/DatePicker'
import AustralianAddressField from '../../../../forms/layouts/AustralianAddressField'
import StackedFieldRow from '../../../../forms/layouts/StackedFieldRow'
import { submitToSuper } from './_requests'
import { AccountContext } from '../../../../account/_context'
import { isAccountReady } from '../../../../account/status'
import { createEvent } from '../../../../events/_requests'
import { EventCategory, EventDescription } from '../../../../events'
import { withFormErrorAsync } from '../../../../forms/errors'


function JoinForm() {

  const [account] = useContext(AccountContext)
  const [didSucceed, setDidSucceed] = useState(false)
  const [lastSubmission, setLastSubmission] = useState({ addressPostalSameAsResidential: true })

  const refillForm = _ => setDidSucceed(false)

  const submit = async values => {
    setLastSubmission(values)
    await submitToSuper(values)
    setDidSucceed(true)
  }

  useEffect(_ => {
    if (isAccountReady(account)) {
      setLastSubmission({
        firstName: account.firstName,
        familyName: account.lastName,
        email: account.email,
        mobileNumber: account.mobile,
        ...lastSubmission,
      })
    }
    // Only care about lastSubmission for the purposes of initialising the form.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  useEffect(_ => {
    createEvent(EventCategory.JobReady, EventDescription.ViewedSuperForm)
  }, [])

  useEffect(_ => {
    if (didSucceed) {
      createEvent(EventCategory.JobReady, EventDescription.ViewedEndScreen)
    }
  }, [didSucceed])

  return (
    <React.Fragment>
      <ScrollToTopOnMount />
      <div className='container'>
        <div className='row'>
          <div className='col-md-5'>
            <div className={clsx(styles.frame)}>
              <img className={clsx(styles.logo, 'mb-4')} src={logo} alt='Join Professional Super' />
              {!didSucceed && <p className='my-2 mb-4'>To get started, complete the form below.</p>}
              {
                !didSucceed && (
                  <Form
                    onSubmit={withFormErrorAsync(submit)}
                    initialValues={lastSubmission}
                    render={({ values, handleSubmit, submitting, submitErrors }) => (
                      <form onSubmit={handleSubmit}>
                        <StackedFieldRow>
                          <StackedField
                            className='col-6'
                            name='firstName' label='First name' validate={required}
                            component={Input} />
                          <StackedField
                            className='col-6'
                            name='familyName' label='Family name' validate={required}
                            component={Input} />
                        </StackedFieldRow>
                        <StackedField
                          name='email' label='Email' validate={required}
                          component={Input} />
                        <StackedField
                          name='mobileNumber' label='Mobile' validate={required}
                          component={Input} />
                        <StackedFieldRow>
                          <StackedField
                            className='col-6'
                            name='dateOfBirth' label='Date of birth' validate={required}
                            component={DatePicker}
                            placeholder='DD/MM/YYYY' />
                          <StackedField
                            className='col-6'
                            name='gender' label='Sex' validate={required}
                            component={Select}
                            fieldData={{
                              defaultOption: { label: 'Select' },
                              options: [
                                { label: 'Male', value: 'M' },
                                { label: 'Female', value: 'F' },
                                { label: 'Other', value: 'X' },
                              ]
                            }} />
                        </StackedFieldRow>
                        <AustralianAddressField name='residentialAddress' label='Residential address' submitErrors={submitErrors} />
                        <Field name='addressPostalSameAsResidential' type='checkbox'>
                          {({ input, meta }) => (
                            <div className='form-check'>
                              <input className='form-check-input' id='addressPostalSameAsResidential' {...input} />
                              <label className='form-check-label' htmlFor='addressPostalSameAsResidential'>
                                My postal address is the same as my residential address.
                              </label>
                            </div>
                          )}
                        </Field>
                        {!values.addressPostalSameAsResidential && (
                          <AustralianAddressField name='postalAddress' label='Postal address' submitErrors={submitErrors} />
                        )}
                        <div className={clsx(typographyStyles.psst, 'mt-4')}>
                          <small style={{ display: 'block' }}>
                            Any and all personal information you provide Professional Super is collected,
                            stored, processed and used in accordance with the terms of Professional Super’s&nbsp;
                            <a href='https://www.professionalsuper.com.au/privacy-policy/' target='_blank' rel='noopener noreferrer'>
                              Privacy Policy
                            </a>.
                          </small>
                        </div>
                        <div className='mt-4'>
                          <Button submit
                            className={styles.login}
                            type={ButtonType.CtaSecondary}
                            disabled={submitting}>
                            Next
                        </Button>
                        </div>
                      </form>
                    )}>
                  </Form>
                )
              }
              {
                didSucceed && (
                  <React.Fragment>
                    <p className='mb-4'><strong>Check your phone – we've sent a verification SMS to {lastSubmission.mobileNumber}.</strong></p>
                    <p>Your SMS will expire in 5 minutes.</p>
                    <p>
                      Click <Link onClick={_ => submitToSuper(lastSubmission)} to='#'>here</Link> to resend your SMS. If your mobile is
                      incorrect, you can <Link onClick={refillForm} to='#'>change it here</Link>.
                    </p>
                    <p>When you are verified, you'll be emailed a pre-filled form that you hand to your employer.</p>
                  </React.Fragment>
                )
              }
            </div>
            {
              didSucceed && (
                <div className='mt-5'>
                  <Link to={Urls.MyResumes} className={styles.escape}>Back to My Resumes</Link>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default JoinForm
