import React from 'react'


function Intro() {
  return (
    <React.Fragment>
      <p className='m-0'>Add any work experience that you may have completed during high school.</p>
    </React.Fragment>
  )
}

export default Intro
