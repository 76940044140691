import React from 'react'
import clsx from 'clsx'

import styles from './FormError.module.css'


function FormError({ className, children }) {
  return (
    <span className={clsx(styles.error, className)}>{children}</span>
  )
}

export default FormError
