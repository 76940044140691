import React from 'react'

import YearDropdown, { CurrentYearMode } from './YearDropdown'


function YearDropdownWithCurrent(props) {
  return (
    <YearDropdown {...props} fieldData={{ ...(props.fieldData || {}), currentYearMode: CurrentYearMode.Show }} />
  )
}

export default YearDropdownWithCurrent
