import React from 'react'
import ReactDOMServer from 'react-dom/server'

import { VersionTemplateNameDefaultMap, VersionProviderMap, TemplateNameResourceMap } from '../maps'


export const getTemplateHtmlCss = ({ version, template, formValues, formConfig }) => {
  const templateName = template || VersionTemplateNameDefaultMap[version]
  const StateProvider = VersionProviderMap[version]

  const [Template, css] = TemplateNameResourceMap[templateName]

  const props = new StateProvider(formValues, formConfig).getProps()
  const html = ReactDOMServer.renderToStaticMarkup(<Template {...props} />)

  return { html, css }
}
