import React from 'react'


function LeftLabel({
  children, name, label, fieldInfo, fieldData,
  labelComponent: Label,
  ...rest
}) {
  return (
    <div className='form-row' {...rest}>
      <div className='col-sm-4'>
        <Label
          name={name}
          label={label}
          fieldInfo={fieldInfo}
          fieldData={fieldData} />
      </div>
      <div className='col-sm-8'>
        {children}
      </div>
    </div>
  )
}

export default LeftLabel
