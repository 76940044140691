import React from 'react'
import { Field } from 'react-final-form'
import clsx from 'clsx'

import FieldError from '../messaging/FieldError'


function StackedField({ name, label, component: FieldComponent, className, ...rest }) {
  return (
    <Field name={name} {...rest}>
      {
        ({ input, meta, ...rest }) => {
          return (
            <div className={clsx('my-3', className)}>
              <label htmlFor={name}>{label}</label>
              <FieldComponent id={name} input={input} {...rest} />
              {meta.error && meta.touched && <FieldError>{meta.error}</FieldError>}
              {meta.submitError && meta.touched && <FieldError>{meta.submitError}</FieldError>}
            </div>
          )
        }
      }
    </Field>
  )
}

export default StackedField