import React, { useContext } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import { isLoggedIn, isAccountReady } from './status'
import { Urls } from '../routing/urls'
import { AccountContext } from './_context'


function withAuthenticatedRedirect(url) {
  return WrappedComponent => {
    return function Wrapped(props) {
      const [account] = useContext(AccountContext)
      const location = useLocation()
      return isLoggedIn() || isAccountReady(account)
        ? <Redirect to={{
          pathname: url || Urls.MyResumes,
          state: { from: location }
        }} />
        : <WrappedComponent {...props} />
    }
  }
}

export { withAuthenticatedRedirect }