export const ActionName = {
  SET_PROGRESS: 'SET_PROGRESS',
  SET_RESUME_DATA: 'SET_RESUME_DATA',
  SET_URL_DATA: 'SET_URL_DATA',

  ADD_SECTION: 'ADD_SECTION',
  DELETE_SECTION: 'DELETE_SECTION',
  TOGGLE_SECTION: 'TOGGLE_SECTION',
  TOGGLE_SECTION_VISIBILITY: 'TOGGLE_SECTION_VISIBILITY',
  ADD_FIELD: 'ADD_FIELD',
  DELETE_FIELD: 'DELETE_FIELD',
  SET_LABEL: 'SET_LABEL',

  SET_FORM_VALUES: 'SET_FORM_VALUES',
  SET_TEMPLATE: 'SET_TEMPLATE',

  TOGGLE_PREVIEW: 'TOGGLE_PREVIEW',
  TOGGLE_TEMPLATE_SWITCH: 'TOGGLE_TEMPLATE_SWITCH',
}

export const Action = {
  SetProgress: percentagePoints => ({ type: ActionName.SET_PROGRESS, payload: percentagePoints }),
  SetResumeData: data => ({ type: ActionName.SET_RESUME_DATA, payload: data }),
  SetUrlData: (previewUrl, downloadUrl) => ({ type: ActionName.SET_URL_DATA, payload: ({ previewUrl, downloadUrl }) }),

  AddSection: (key, index, config) => ({ type: ActionName.ADD_SECTION, payload: ({ key, index, config }) }),
  DeleteSection: (parentKey, sectionKey, index) => ({ type: ActionName.DELETE_SECTION, payload: ({ parentKey, sectionKey, index }) }),
  ToggleSection: (key, section) => ({ type: ActionName.TOGGLE_SECTION, payload: ({ key, section }) }),
  ToggleSectionVisibility: (key, section) => ({ type: ActionName.TOGGLE_SECTION_VISIBILITY, payload: ({ key, section }) }),
  AddField: (key, index, config) => ({ type: ActionName.ADD_FIELD, payload: ({ key, index, config }) }),
  DeleteField: (parentKey, sectionKey, index) => ({ type: ActionName.DELETE_FIELD, payload: ({ parentKey, sectionKey, index }) }),
  SetLabel: (key, index, value) => ({ type: ActionName.SET_LABEL, payload: ({ key, index, value }) }),

  SetFormValues: (values) => ({ type: ActionName.SET_FORM_VALUES, payload: ({ values }) }),
  SetTemplate: (templateId) => ({ type: ActionName.SET_TEMPLATE, payload: ({ templateId }) }),

  TogglePreview: () => ({ type: ActionName.TOGGLE_PREVIEW, payload: {} }),
  ToggleTemplateSwitch: () => ({ type: ActionName.TOGGLE_TEMPLATE_SWITCH, payload: {} })
}
