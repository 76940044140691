import React from 'react'
import clsx from 'clsx'


function Basic({ name, label, fieldInfo, fieldData, className, ...rest }) {
  return (
    <label htmlFor={name} className={clsx('col-form-label', className, !label && 'p-0')} {...rest}>
      {!!label && <div>{label}</div>}
      <small className={clsx('form-text text-muted', !label && 'm-0')} dangerouslySetInnerHTML={{ __html: fieldData.helpText }} />
    </label>
  )
}

export default Basic
