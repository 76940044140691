import React, { useContext } from 'react'

import { FormContext } from '../../_context'
import AddButton from './common/AddButton'
import { Action } from '../../_actions'
import { FieldComponentType } from '../maps'
import { getIn } from '../../structure'


// IMPORTANT:

// The correct usage for `AddField` is to exist as the only field of its type inside
// of a `fields` array. It must also be the last field in the `fields` array. It must
// receive a `fieldData.config`, which details the structure of the new field it will
// insert in place.

// For now, we will just splice into the parent section's sections at the position
// just in front of the field's position (i.e. second to last index, so practically we
// will be inserting a new field as a sibling).

function AddField({ label, fieldInfo, fieldData }) {
  const { location, fieldIndex } = fieldInfo
  const [formConfig, dispatch] = useContext(FormContext)

  const section = getIn(formConfig, location)

  const fieldTypeIndices = section.fields
    .map((field, index) => field.component === FieldComponentType.AddField ? index : undefined)
    .filter(index => index !== undefined)

  if (fieldTypeIndices.length > 1) {
    throw new Error(`AddField should be the only field of type '${FieldComponentType.AddField}' in its section. Location: ${location}`)
  }

  if (fieldTypeIndices[0] < section.fields.length - 1) {
    throw new Error(`AddField should be the last field in its section. Location: ${location}`)
  }

  const addFieldConfig = {
    ...fieldData.config,

    // Each field must have a unique name as it is used for form labels, as the
    // key where input data is stored in the form values backing the form, etc.
    name: `${FieldComponentType.AddField}#${new Date().getTime()}`,

    // Additional field metadata letting consumers of the config know what created
    // the current field.
    originator: FieldComponentType.AddField,
  }

  const addField = _ => dispatch(Action.AddField(location, fieldIndex, addFieldConfig))

  return (
    <AddButton onClick={addField}>
      {label}
    </AddButton>
  )
}

export default AddField
