import React from 'react'
import clsx from 'clsx'

import typographyStyles from '../typography/Typography.module.css'
import ProgressBar from './ProgressBar'


function Header({ children }) {
  return (
    <div className='container'>
      <div className='row'>
        <div className='col'>
          <h1 className={clsx(typographyStyles.title)}>Get job ready</h1>
        </div>
      </div>
      {!!children && (
        <div className='row mt-3'>
          <div className='col-md-6 pr-md-5'>
            {children}
          </div>
        </div>
      )}
      <div className='row mb-2'>
        <div className='col'>
          <ProgressBar className='my-4' />
        </div>
      </div>
    </div>
  )
}

export default Header
